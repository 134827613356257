import React, { useEffect, useState } from "react";
import HomeNotDataScreen from "../../screens/HomeScreenNotData/HomeScreen";
import { supabase } from "../../supabaseClient";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { setData } from "../../core/redux/app/Actions";
import { sort } from "fast-sort";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  confirmEmail,
  connectionWithAds,
  connectionWithAdsToMediaCredit,
} from "../../core/email/aprovedRegister";
import { sendEmail } from "../../core/email";
import Swal from "sweetalert2";
import { alertSuccess } from "../../components/Toast";
import { generateToken } from "../../core/router/routes";
import { optionsPlans } from "../../screens/RegisterScreen/RegisterScreen";

const HomeContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user);
  const [active, setActive] = useState(0);
  const [invoice, setInvoice] = useState([]);
  const [consumption, setConsumption] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [cashback, setCashback] = useState([]);
  const [limit, setLimit] = useState();
  const typeCashback = user.plan === 0 ? 0.5 : 1;
  const plan = optionsPlans?.find((item) => item?.value === user?.plan);
  const reloadHome = useSelector((state) => state.app.reloadHome);

  const verifyAndSaveToken = async () => {
    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const authCode = urlObject.searchParams.get("code");

    if (authCode) {
      const scope = urlObject.searchParams.get("scope");
      const saveProps = {};

      if (scope === "https://www.googleapis.com/auth/adwords") {
        await supabase.functions.invoke("generate_credentials", {
          body: JSON.stringify({ google: true, code: authCode, redirectUri: window.location.origin + "/home" })
        }).then(response => {
          return response?.data;
        })
        .then(response => {
          saveProps["google_token"] = response.access_token;
          googleIds();
        })
      } else {
        await supabase.functions.invoke("generate_credentials", {
          body: JSON.stringify({ code: authCode, redirectUri: window.location.origin + "/home" })
        }).then(response => {
          console.log({response})
          return response?.data;
        })
        .then(response => {
          console.log({response})
          saveProps["meta_token"] = response.access_token;
          metaIds()
        })
      }

      if(Object.keys(saveProps).length > 0){
        supabase
          .from("user")
          .update(saveProps)
          .eq("id", user.id)
          .select()
          .then((response) => {
            const user = response?.data[0];
            if(user){
              dispatch(setData({ user }));
            }
          });
      }
      // Você pode agora enviar o código para seu servidor para trocá-lo por um token de acesso
    }
  }

  useEffect(() => {
    verifyAndSaveToken()
  }, []);

  useEffect(() => {
    const search = location?.search;
    if (search && search.includes("sendEmailAds") && user && user.email) {
      sendEmail(
        user.email,
        "Conecte suas contas do Google e Meta Ads",
        confirmEmail(user.name)
      );
      setTimeout(() => {
        history.push("/home");
      }, 2000);
    }
  }, [location, user]);

  useEffect(() => {
    if (
      user.register_approved_at &&
      !user.social_media_approved_at &&
      !user.document_approved_at
    ) {
      setActive(1);
    } else if (
      user.register_approved_at &&
      user.social_media_approved_at &&
      !user.document_approved_at
    ) {
      setActive(2);
    } else if (
      user.register_approved_at &&
      user.social_media_approved_at &&
      user.document_approved_at
    ) {
      setActive(3);
    }
  }, []);

  useEffect(() => {
    verificationLimit();
  }, [transaction, invoice, reloadHome]);

  useEffect(() => {
    loadInvoice();
    loadTransactions();
  }, [reloadHome]);

  useEffect(() => {
    loadCashback();
  }, []);

  const verificationLimit = async () => {
    if (transaction && transaction.length > 0) {
      const userinfo = await getUserInfo(user.id);

      const invoicesValue = invoice.filter(
        (item) =>
          moment(item.closed_in).format("MMM") === moment().format("MMM") &&
          item.status === 1
      );
      // const verificationMonth =
      //   invoicesValue.length > 0
      //     ? moment().add(1, "months").format("MMM")
      //     : moment().format("MMM");
      const actualValue = transaction
        .filter(
          (item) =>
            moment(item.date).format("MMM") === moment().format("MMM") &&
            item.status === 6 &&
            !item.consumption
        )
        .reduce(
          (accumulator, currentValue) => accumulator + currentValue.value,
          0
        );
      // console.log({actualValue, verificationMonth})
      // + userinfo.package_limit
      const spentValue = transaction
        .filter(
          (item) =>
            moment(item.date).format("MMM") === moment().format("MMM") &&
            (item.status === 3 || item.status === 1 || item.status === 5) &&
            item.consumption
        )
        .reduce(
          (accumulator, currentValue) => accumulator + currentValue.value,
          0
        );

      setLimit(actualValue - spentValue);
    }
  };

  const loadInvoice = async () => {
    let query = supabase.from("Invoice").select();

    if (user.type !== 0) {
      query = query.eq("id_user", user.id).is("deleted_at", null);
    } else {
      query = query.is("deleted_at", null);
    }

    const { data, error } = await query;

    // console.log({data})

    if (!error) {
      setInvoice(
        sort(data.filter((invoice) => invoice.type !== 1)).asc(
          (u) => u.closed_in
        )
      );
      setConsumption(
        sort(data.filter((invoice) => invoice.type === 1)).asc(
          (u) => u.closed_in
        )
      );
    }
  };

  const loadTransactions = async () => {
    let query = supabase.from("Transaction").select();

    if (user.type !== 0) {
      query = query.eq("id_user", user.id).is("deleted_at", null);
    } else {
      query = query.is("deleted_at", null);
    }

    const { data, error } = await query;

    // console.log({data})

    if (!error) {
      setTransaction(data);
    }
  };

  const loadCashback = async () => {
    let query = supabase.from("Cashback").select();

    if (user.type !== 0) {
      query = query.eq("id_user", user.id).is("deleted_at", null);
    } else {
      query = query.is("deleted_at", null);
    }

    const { data, error } = await query;

    if (!error) {
      setCashback(data);
    }
  };

  const doMetaLogin = async () => {
    const redirectUri = window.location.origin + "/home";
    const metaUrl = `https://www.facebook.com/v19.0/dialog/oauth?client_id=1098773651325339&redirect_uri=${redirectUri}&scope=ads_read`;
    window.open(metaUrl, "_self");
  };

  const doGoogleLogin = async () => {
    const scope = "https://www.googleapis.com/auth/adwords";
    const responseType = "code";
    const accessType = "offline";
    const prompt = "consent";
    const redirectUri = window.location.origin + "/home";

    const code_verifier = generateRandomString(128);
    // Use a função generateCodeChallenge para obter o code_challenge
    const code_challenge = await generateCodeChallenge(code_verifier);
    // &code_challenge=${code_challenge}&code_challenge_method=S256
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=${responseType}&client_id=392227784851-j8nsqi6cpo81s53pv84hpd6kqmv7dnc1.apps.googleusercontent.com&redirect_uri=${redirectUri}&scope=${scope}&access_type=${accessType}&prompt=${prompt}`;
    window.open(authUrl, "_self");
  };

  const onTicket = async () => {
    const { data, error } = await supabase.storage
      .from(`ticketPlan`)
      .createSignedUrl(`${user.id}/ticket`, 315360000);

    window.open(data.signedURL, "_blank");
  };

  const googleIds = () => {
    Swal.fire({
      title: "Identificador Google Ads",
      text: "Preencha o campo abaixo com a identificação da sua conta. Caso possua duas ou mais contas, separe por vírgula. Ex: 00000000001,00000000002",
      imageUrl: "/google-info.png", // substitua pela URL da sua imagem explicativa
      imageWidth: 700,
      imageHeight: 500,
      input: "text",
      inputPlaceholder: "Digite aqui...",
      showCancelButton: true,
      background: "#ffffff",
      confirmButtonText: "Salvar",
      confirmButtonColor: "#199900",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "my-popup-ads",
      },
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage("Preencha o campo para continuar!");
        } else {
          return value;
        }
      },
    }).then(async (result) => {
      await supabase
        .from("user")
        .update({
          google_codes: result.value.trim(),
        })
        .eq("id", user.id)
        .select()
        .then((response) => {
          const user = response?.data[0];
          dispatch(setData({ user }));
        });
      sendEmail(
        user.email,
        "Análise de limite em andamento: Google Ads",
        connectionWithAds(user.name)
      );
      sendEmail(
        "plataforma@mediacredit.com.br",
        "Usuário pronto para análise: Google Ads",
        connectionWithAdsToMediaCredit(user.name)
      );
      alertSuccess("Salvo com sucesso");
    });
  };

  const metaIds = () => {
    Swal.fire({
      title: "Identificador Meta Ads",
      text: "Preencha o campo abaixo com a identificação da sua conta. Caso possua duas ou mais contas, separe por vírgula. Ex: 00000000001,00000000002",
      imageUrl: "/meta-info.png", // substitua pela URL da sua imagem explicativa
      imageWidth: 700,
      imageHeight: 500,
      input: "text",
      inputPlaceholder: "Digite aqui...",
      showCancelButton: true,
      background: "#ffffff",
      confirmButtonText: "Salvar",
      confirmButtonColor: "#199900",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "my-popup-ads",
      },
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage("Preencha o campo para continuar!");
        } else {
          return value;
        }
      },
    }).then(async (result) => {
      await supabase
        .from("user")
        .update({
          facebook_codes: result.value.trim(),
        })
        .eq("id", user.id)
        .select()
        .then((response) => {
          const user = response?.data[0];
          dispatch(setData({ user }));
        });
      sendEmail(
        user.email,
        "Análise de limite em andamento: Meta Ads",
        connectionWithAds(user.name)
      );
      sendEmail(
        "plataforma@mediacredit.com.br",
        "Usuário pronto para análise: Meta Ads",
        connectionWithAdsToMediaCredit(user.name)
      );
      alertSuccess("Salvo com sucesso");
    });
  };

  return (
    <HomeNotDataScreen
      active={active}
      setActive={setActive}
      doGoogleLogin={doGoogleLogin}
      doMetaLogin={doMetaLogin}
      user={user}
      history={history}
      type={user.ticket_approved_at ? 1 : 0}
      invoice={invoice}
      onTicket={onTicket}
      cashback={cashback}
      limit={limit}
      plan={plan}
      consumption={consumption}
      typeCashback={typeCashback}
    />
  );
};

export default HomeContainer;

const getUserInfo = async (id) => {
  const { data, error } = await supabase
    .from("user")
    .select()
    .eq("id", id)
    .is("deleted_at", null);
  return data && data[0];
};

function base64URLEncode(str) {
  return str
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}

function generateRandomString(length) {
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
  let text = "";

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

async function sha256(buffer) {
  const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  return hash;
}

async function generateCodeChallenge(codeVerifier) {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const base64Digest = await sha256(data);
  return base64URLEncode(base64Digest);
}
