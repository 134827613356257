export const aprovedRegister = (userName, message, labelButton, linkButton) => {
  //aqui devemos criar o html padrão, que ta no figma, o content, deve estar dentro desse padrão
  return `
  <div>
  <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
<h2 style="text-align: center;">Olá, ${userName}</h2>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">${message}</p>
<br />
<p style="text-align: center;">
<button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
  <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href=${linkButton} target="_blank">
   ${labelButton}
   </a>
    </p>
    </button>
  </div>
  <br />
  <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Obrigado,<br />Equipe Media Credit</p>
  </div>
  `;
};

export const confirmEmail = (userName) => {
  return `
      <div>
      <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
    <p style="text-align: left;">&nbsp;</p>
    <p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
    <h2 style="text-align: center;">Você se juntou a plataforma Media Credit!</h2>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Agora é hora de trilhar o caminho da aprovação do limite de crédito conectando suas contas do Google e Meta Ads. <br />Conecte suas contas clicando no botão abaixo:</p>
<br />
    <p style="text-align: center;">
    <button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
      <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br/home" target="_blank">
      Conectar contas
       </a>
        </p>
        </button>
      </div>
      <br />
      <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Atenciosamente,<br />Equipe Media Credit</p>
      </div>
      `;
};

export const connectionWithAds = (userName) => {
  return `
      <div>
      <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
    <p style="text-align: left;">&nbsp;</p>
    <p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
    <h2 style="text-align: center;">Aguarde aprovação de limite!</h2>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Agradecemos por conectar as contas do Google e Meta Ads. Agora é com a gente! <br />Sua análise ficará pronta em até 5 dias corridos. <br />Fique atento à sua caixa de entrada para não perder atualizações do progresso.</p>
    <p style="text-align: center;">
    </div>
    <br />
      <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Atenciosamente,<br />Equipe Media Credit</p>
    </div>
      `;
};

export const connectionWithAdsToMediaCredit = (userName) => {
  return `
      <div>
      <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
    <p style="text-align: left;">&nbsp;</p>
    <p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
    <h2 style="text-align: center;">Usuário pronto para análise!</h2>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, Media Credit!</p>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">O usuário, ${userName} conectou as contas de ads e está pronto para análise! <br />Verifique clicando no botão abaixo:</p>
<br />
    <p style="text-align: center;">
    <button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
      <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br/users" target="_blank">
      Acompanhe usuários
       </a>
        </p>
        </button>
    </div>
    <br />
      <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Atenciosamente,<br />Equipe Media Credit</p>
    </div>
      `;
};

export const toApproveDocuments = (userName) => {
  return `
      <div>
      <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
    <p style="text-align: left;">&nbsp;</p>
    <p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
    <h2 style="text-align: center;">Usuário pronto para análise!</h2>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, Media Credit!</p>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">O usuário, ${userName} enviou documentação para análise! <br />Verifique clicando no botão abaixo:</p>
<br />
    <p style="text-align: center;">
    <button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
      <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br/users" target="_blank">
      Acompanhe usuários
       </a>
        </p>
        </button>
    </div>
    <br />
      <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Atenciosamente,<br />Equipe Media Credit</p>
    </div>
      `;
};

export const releaseLimitInProgress = (userName) => {
  return `
      <div>
      <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
    <p style="text-align: left;">&nbsp;</p>
    <p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
    <h2 style="text-align: center;">Usuário pronto para análise!</h2>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Agradecemos o envio da documentação da sua empresa. Agora é com a gente! <br />Sua análise ficará pronta em até 5 dias corridos. <br />Fique atento à sua caixa de entrada para não perder atualizações do progresso.</p>
<br />
      <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Atenciosamente,<br />Equipe Media Credit</p>
    </div>
      `;
};

export const refusedApproved = (userName) => {
  return `
      <div>
      <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
    <p style="text-align: left;">&nbsp;</p>
    <p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
    <h2 style="text-align: center;">Atualização sobre o limite pré-aprovado!</h2>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Gostaríamos de informar que, após revisão detalhada, sua solicitação de limite para Ads não foi aprovada neste momento. <br />Entendemos que isso pode ser decepcionante, mas queremos assegurar que continuaremos trabalhando para oferecer soluções que atendam às suas necessidades. <br />Enquanto sua empresa não possui um limite aprovado, poderá consultar os relatórios automáticos do Google e Meta para potencializar as estratégias.</p>
<br />
    <p style="text-align: center;">
    <button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
      <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br/dashboard" target="_blank">
      Acompanhar relatórios
       </a>
        </p>
        </button>
    </div>
    <br />
      <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Atenciosamente,<br />Equipe Media Credit</p>
    </div>
      `;
};
