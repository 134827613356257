import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { alertError, alertSuccess } from "../../components/Toast";
import { compareValues } from "../../core/utils/compareValues";
import { saveTransaction } from "../../core/utils/transaction";
import { plans } from "../../screens/PackagesScreen/PackagesScreen";
import PaynamentDetailsScreen from "../../screens/PaynamentDetailsScreen";

const PaynamentDetailsContainer = (props) => {
    const id = props?.location?.pathname.split("/")[3]
    const history = useHistory();
    const { planName, amount } = plans?.find(item => item?.id === parseInt(id))
    const loggedUser = useSelector((state) => state.app.user);

    useEffect(() => {
        if (props?.location?.state?.pay) {
            onSave();
        }
    }, []);

    const onSave = async () => {
        try {
            await saveTransaction(undefined, { status: 1, name: planName, id_user: loggedUser.id, value: parseInt(amount.split("+")[1].replace(".", "")), pay: true, date: new Date(), complete: false, package: true })
            alertSuccess("Pedido enviado com sucesso!")
        } catch (e) {
            alertError(e.message)
        }
    }

    const closeModal = () => {
        history.push("/paynament");

    };

    return <PaynamentDetailsScreen
        planName={planName}
        type={id !== "3" ? 1 : 2}
        amount={amount.split("+")[1].replace(".", "")}
        closeModal={closeModal}
    />
}

export default PaynamentDetailsContainer;