import React, { useEffect, useState } from "react";
import TicketDetailsScreen from "../../screens/TicketDetailsScreen";
import { supabase } from "../../supabaseClient";
import { useDispatch, useSelector } from "react-redux";
import { alertError, alertSuccess } from "../../components/Toast";
import { compareValues } from "../../core/utils/compareValues";
import { saveInvoice } from "../../core/utils/invoice";
import { setData } from "../../core/redux/app/Actions";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { saveUser } from "../../core/utils/user";
import { sendEmail } from "../../core/email";
import {
  sendInvoices,
  sendInvoicesPaynament,
} from "../../core/email/sendInvoice";
import { optionsPlans } from "../../screens/RegisterScreen/RegisterScreen";
import { saveTransaction } from "../../core/utils/transaction";

const TicketDetailsContainer = ({ id }) => {
  const isNew = id === "0";
  const history = useHistory();
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [oldValues, setOldValues] = useState({});
  const [newLimit, SetNewLimit] = useState({});
  const [users, setUsers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const loggedUser = useSelector((state) => state.app.user);
  const isChange = compareValues(values, oldValues);
  const reload = useSelector((state) => state.app.reload_login_ticket || false);
  const [saveing, setSaveing] = useState(false);

  useEffect(() => {
    loadData();
  }, [id]);

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    if (values.closed_in) {
      loadTransactions();
    }
  }, [values]);

  const loadTransactions = async () => {
    const { data, error } = await supabase
      .from("Transaction")
      .select()
      .eq("id_user", values.id_user)
      .gt(
        "date",
        moment(values.closed_in).startOf("month").format("YYYY-MM-DDTHH:mm:ss")
      ) // > monthBack
      .lte(
        "date",
        moment(values.closed_in).endOf("month").format("YYYY-MM-DDTHH:mm:ss")
      ) // <= atualMonth
      .is("deleted_at", null);

    setTransactions(data);
  };

  const loadData = async () => {
    const { data, error } = await supabase
      .from("Invoice")
      .select()
      .eq("id", id)
      .is("deleted_at", null);

    const promises = data?.map(async (item) => {
      const ticket = await supabase.storage.from(`tickets`).list(`${item.id}`);

      return {
        ...item,
        ticket: ticket.data[0] || false,
      };
    });
    const dataPromise = await Promise.all(promises).then(
      (response) => response
    );
    setValues(isNew ? {} : formatData(dataPromise[0]));
    setOldValues(isNew ? {} : formatData(dataPromise[0]));
    setLoading(false);
  };

  const loadUsers = async () => {
    const { data, error } = await supabase
      .from("user")
      .select()
      .eq("type", 1)
      .is("deleted_at", null);

    setUsers(data);
  };

  const onChange = (filed, value) => {
    setValues({ ...values, [filed]: value });
  };

  const openDocument = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { data: tickets, error } = await supabase.storage
      .from(`tickets`)
      .createSignedUrl(`${values.id}/ticket`, 3600);

    if (tickets) {
      window.open(tickets.signedURL, "_blank");
    }
  };

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  // Formatando as datas para "dd/mm/yyyy" usando Moment.js

  // Adicionando mais 30 dias usando a função addDays
  // var newclosed_in = addDays(closed_inMoment, 30);
  // var newopen_in = addDays(open_inMoment, 30);

  const onSave = async () => {
    setSaveing(true);
    try {
      if (!values.name) {
        throw new Error("Selecione um nome!");
      }

      if (!values.expired_in) {
        throw new Error("Selecione a data que vencera a fatura!");
      }

      if (!values.closed_in) {
        throw new Error("Selecione a data que fechara a fatura!");
      }

      if (!values.value) {
        throw new Error("Selecione um valor!");
      }
      delete values.ticket;
      const saveInvoices = await saveInvoice(values.id, {
        ...values,
        value: parseInt(values.value),
      });
      setLoadingSave(true);
      if (!values.id) {
        const userSelect = users.find((item) => item.id === values.id_user);
        sendEmail(
          userSelect.email,
          "Nova fatura",
          sendInvoices(
            userSelect.name,
            values.name,
            moment(values.expired_in).format("DD/MM/YYYY"),
            values.value,
            values.id
          )
        );
      }

      if (values.status === 1) {
        const { data, error } = await supabase
          .from("user")
          .select()
          .eq("id", values.id_user)
          .is("deleted_at", null);
        const user = data[0];
        const plan = optionsPlans?.find((item) => item?.value === user?.plan);

        var closed_inMoment = moment(values.closed_in)
          .add(1, "month")
          .format("YYYY-MM-DDTHH:mm:ss");

        var open_inMoment = moment(values.closed_in)
          .add(1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ss");

        var expired_inMoment = moment(values.expired_in)
          .add(1, "month")
          .format("YYYY-MM-DDTHH:mm:ss");

        delete values.id;
        const save = await saveInvoice(undefined, {
          ...values,
          value: plan.amount,
          created_at: values.created_at,
          closed_in: closed_inMoment,
          open_in: open_inMoment,
          expired_in: expired_inMoment,
          name: values.name,
          status: 3,
          id_user: values.id_user,
          deleted_at: values.deleted_at,
        });

        const userSelect = users?.find((item) => item?.id === values.id_user);
        sendEmail(
          userSelect.email,
          "Pagamento de fatura",
          sendInvoicesPaynament(
            userSelect.name,
            values.name,
            moment(values.expired_in).format("DD/MM/YYYY"),
            values.value
          )
        );
        // sendEmail(
        //   userSelect.email,
        //   "Nova fatura",
        //   sendInvoices(
        //     userSelect.name,
        //     values.name,
        //     values.expired_in,
        //     values.value,
        //     save.data[0].id
        //   )
        // );

        if (values.type === 1) {
          // Aqui precisamos conferir se não tem um crédito de mesmo valor já inserido nesse período
          await saveTransaction(undefined, {
            type: plan.value,
            complete: true,
            date: open_inMoment,
            pay: false,
            name: plan.label,
            value: plan.credit,
            id_user: values.id_user,
            status: 6,
          });
        }

        // await saveUser(user.id, {
        //   package_limit:
        //     user.package_limit - values.value >= 0
        //       ? user.package_limit - values.value
        //       : 0,
        // });
      } else if (values.status === 4) {
        const userSelect = users?.find((item) => item?.id === values.id_user);
        sendEmail(
          userSelect.email,
          "Nova fatura",
          sendInvoices(
            userSelect.name,
            values.name,
            moment(values.expired_in).format("DD/MM/YYYY"),
            values.value,
            values.id
          )
        );
      }
      setLoadingSave(false);
      dispatch(setData({ reload_login_ticket: !reload }));
      history.push("/installment", { tab: 1 });
      alertSuccess("Crédito cadastrado com sucesso!");
    } catch (e) {
      alertError(e.message);
    }
    setSaveing(false);
  };

  const closeModal = () => {
    if (isChange) {
      Swal.fire({
        title: "Deseja sair sem salvar?",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: "warning",
        text: "Ao sair, seus dados seram descartados.",
        denyButtonText: `Descartar`,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isDenied) {
          history.push("/installment", { tab: 1 });
        }
      });
    } else {
      history.push("/installment", { tab: 1 });
    }
  };

  return (
    <TicketDetailsScreen
      values={values}
      openDocument={openDocument}
      user={loggedUser}
      type={loggedUser.type}
      onSave={onSave}
      isChange={isChange}
      onChange={onChange}
      users={users?.map((item) => ({ label: item.name, value: item.id }))}
      transactions={transactions}
      loading={loading}
      closeModal={closeModal}
      newLimit={newLimit}
      loadingSave={loadingSave}
      saveing={saveing}
    />
  );
};

export default TicketDetailsContainer;

const formatData = (data) => {
  return {
    ...data,
    value: data?.value?.toString(),
  };
};
