import React, { useRef, useState } from "react";
import "react-tabs/style/react-tabs.css";
import { FiTrash } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import GridTable from "@nadavshaar/react-grid-table";
import { BsFiletypePdf, BsPencil } from "react-icons/bs";
import { IconDropzone } from "../../components/Dropzone/Dropzone";
import { supabase } from "../../supabaseClient";
import { convertMoney } from "../../components/HomeTablesProgress";
import moment from "moment";
import { IoMdEye } from "react-icons/io";
import { isMobile } from "react-device-detect";
import ColumnMobile from "../columnMobile";
import ActionSheet from "actionsheet-react";
import { NotResultCompontent } from "../NotResultComponent";

export const InvoiceHistoryTable = ({ data, type, onDelete }) => {
  const [aux2, setAux2] = useState();
  let history = useHistory();
  const ref = useRef();

  const downloadDocument = async () => {
    const { data: tickets, error } = await supabase.storage
      .from(`tickets`)
      .createSignedUrl(`${aux2.id}/ticket`, 3600);
    if (tickets) {
      window.open(tickets.signedURL, "_blank");
    }
  };

  const handleOpen = (data) => {
    setAux2(data);
    ref.current.open();
  };

  return (
    <div className="div-table">
      <GridTable
        columns={isMobile ? columnsMobile(handleOpen) : columns(type, onDelete)}
        rows={data}
        texts={{
          noResults: <NotResultCompontent />,
          search: "Buscar:",
          totalRows: "Número de itens:",
          rows: "Itens:",
          selected: "Selecionado",
          rowsPerPage: "Itens por página:",
          page: "Página:",
          of: "de",
          prev: "Anterior",
          next: "Próximo",
          columnVisibility: "Colunas visíveis",
        }}
        showSearch={false}
        showRowsInformation={false}
        showColumnVisibilityManager={false}
        isPaginated={false}
        enableColumnsReorder={false}
      />
      <ActionSheet ref={ref}>
        <div className="ModalMobile">
          {type === 0 ? (
            <div>
              <div
                className="infosMobile"
                onClick={() =>
                  history.push(`/installment/details/${aux2.id}`, {
                    type: "ticket",
                  })
                }
              >
                 Editar{" "}
              </div>
              <div
                className="infosMobile"
                onClick={() => onDelete(aux2, "ticket")}
              >
                Excluir
              </div>
            </div>
          ) : (
            <div>
              {aux2?.ticket && (
                <div className="infosMobile" onClick={() => downloadDocument()}>
                  {" "}
                  Ver PDF
                </div>
              )}
              <div
                className="infosMobile"
                onClick={() =>
                  history.push(`/installment/details/${aux2.id}`, {
                    type: "ticket",
                  })
                }
              >
                Visualizar
              </div>
            </div>
          )}
        </div>
      </ActionSheet>
    </div>
  );
};

const columnsMobile = (handleOpen) => [
  {
    id: 1,
    field: "status",
    label: "Histórico",
    width: "10fr",
    pinned: true,
    resizable: false,
    cellRenderer: ({ data, rowIndex }) => (
      <ColumnMobile
        status={data.status}
        type="ticket"
        badge={BadgeStatusHistoric}
        data={data}
        isMobile={true}
        name={data.name}
        separetor={"-"}
        onClick={() => handleOpen(data)}
      />
      //<BadgeStatusExtract status={data.status}  />
    ),
  },
];

const columns = (type, onDelete) => [
  {
    id: 1,
    field: "status",
    label: "Status",
    pinned: true,
    resizable: false,
    cellRenderer: ({ data, rowIndex }) => (
      <BadgeStatusHistoric status={data.status} />
    ),
  },
  {
    id: 2,
    field: "name",
    label: "Nome",
    width: "1fr",
    paddingLeft: "30px !important",
    resizable: false,
    cellRenderer: ({ data, rowIndex }) => {
      return (
        <div style={{}}>
          {data.name}
          {/* {data.closed_in ? moment(data.closed_in).format("DD/MM/YYYY") : ""} */}
        </div>
      );
    },
  },
  {
    id: 3,
    field: "user.name",
    label: "Empresa",
    width: "1fr",
    paddingLeft: "30px !important",
    resizable: false,
    cellRenderer: ({ data, rowIndex }) => {
      return (
        <div style={{}}>
          {data.user?.name}
          {/* {data.closed_in ? moment(data.closed_in).format("DD/MM/YYYY") : ""} */}
        </div>
      );
    },
  },
  {
    id: 4,
    field: "date",
    label: "Fecha em ",
    width: "1fr",
    paddingLeft: "30px !important",
    resizable: false,
    cellRenderer: ({ data, rowIndex }) => {
      return (
        <div>
          {data.closed_in ? moment(data.closed_in).format("DD/MM/YYYY") : ""}
        </div>
      );
    },
  },
  {
    id: 5,
    field: "date",
    label: "Vence em",
    width: "1fr",
    paddingLeft: "30px !important",
    resizable: false,
    cellRenderer: ({ data, rowIndex }) => {
      return (
        <div>
          {data.expired_in ? moment(data.expired_in).format("DD/MM/YYYY") : ""}
        </div>
      );
    },
  },
  {
    id: 6,
    field: "value",
    label: "Valor",
    width: "1fr",
    paddingLeft: "30px !important",
    resizable: false,
    cellRenderer: ({ data }) => {
      return <div style={{}}>{convertMoney(parseInt(data.value))}</div>;
    },
  },
  {
    id: 7,
    field: "rescue",
    label: "Boleto",
    pinned: true,
    resizable: false,
    cellRenderer: ({ data }) => (
      <ActionCell
        type="ticket"
        data={data}
        invoice={true}
        userType={type}
        onDelete={onDelete}
      />
    ),
  },
];

export const ActionCell = ({
  type,
  data,
  invoice = false,
  userType,
  onDelete,
}) => {
  let history = useHistory();

  const downloadDocument = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { data: tickets, error } = await supabase.storage
      .from(`tickets`)
      .createSignedUrl(`${data.id}/ticket`, 3600);

    if (tickets) {
      window.open(tickets.signedURL, "_blank");
    }
  };

  return (
    <div className="div-icon">
      {invoice && ((data?.ticket?.id && userType === 1) || userType === 0) ? (
        <>
          {userType === 0 ? (
            <IconDropzone
              table={`tickets/${data.id}`}
              filename="ticket"
              type={userType}
              icon={
                <BsFiletypePdf
                  style={{ marginLeft: 10 }}
                  className="icons-action"
                />
              }
            />
          ) : (
            <BsFiletypePdf
              style={{ marginLeft: 10 }}
              className="icons-action"
              onClick={(e) => (userType === 1 ? downloadDocument(e) : {})}
            />
          )}
        </>
      ) : (
        <></>
      )}
      {userType === 0 ? (
        <BsPencil
          className="icons-action"
          style={{ marginLeft: 10 }}
          onClick={() =>
            history.push(`/installment/details/${data.id}`, { type })
          }
        />
      ) : (
        <IoMdEye
          className="icons-action"
          style={{ marginLeft: 10 }}
          onClick={() =>
            history.push(`/installment/details/${data.id}`, { type })
          }
        />
      )}
      {userType === 0 && (
        <FiTrash
          className="icons-action delete"
          style={{ marginLeft: 10 }}
          onClick={() => onDelete(data, "ticket")}
        />
      )}
    </div>
  );
};

const BadgeStatusHistoric = ({ status }) => {
  let result;
  switch (status) {
    case 1:
      result = <div className="status">{isMobile ? "" : "Pago"}</div>;
      break;
    case 2:
      result = (
        <div className="status reprovado"> {isMobile ? "" : "Vencido"}</div>
      );
      break;
    case 3:
      result = (
        <div className="status processamento">
          {" "}
          {isMobile ? "" : "Aguardando pagamento"}
        </div>
      );
      break;
    case 4:
      result = (
        <div className="status fechado"> {isMobile ? "" : "Fechado"}</div>
      );
      break;
    default:
      result = (
        <div className="status processamento">
          {isMobile ? "" : "Aguardando pagamento"}
        </div>
      );
  }
  return result;
};
