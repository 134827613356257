import React from "react";
import { MdClose } from "react-icons/md";
import CalendarContainer from "../../containers/CalendarContainer/CalendarContainer";

const PaynamentDetailsScreen = ({ planName, type, closeModal }) => (
    <div className="modal-paynament-container">
        <div className="modal-header">
            <div className="header-title">Créditos - {planName}</div>
            <div className="div-close-itens">
                <MdClose
                    size={20}
                    onClick={closeModal}
                    style={{ cursor: "pointer" }}
                />
            </div>
        </div>
        {type === 1 ? <div className="modal-consumption-body">
            <text className="send-liberation">Estamos processando seu pedido, entraremos em contato para liberação</text>
            <lottie-player key='loading-data' src="https://lottie.host/3e951272-58da-401f-8e19-232c93c28532/OVsQvjwH1x.json" background="transparent" speed="1" style={{ width: 200, height: 200 }} loop autoplay></lottie-player>
        </div> : <div className="modal-consumption-body">
            <CalendarContainer />
        </div>}

    </div>
);

export default PaynamentDetailsScreen;