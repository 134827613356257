import {
  CNPJInput,
  CellphoneInput,
  LabelInput,
  LabelSelect,
} from "../../components/Input/Input";
import { DefaultButton } from "../../components/Button/Button";
import { components as reactComp } from "react-select";

const RegisterScreen = ({
  handleLogin,
  onChange,
  values,
  loading,
  history,
}) => {
  return (
    <>
      <div className="register-container">
        <div className="div-background">
          <div className="form">
            <div className="form-scroll">
              <div>
                <img
                  className="logo"
                  src={require("../../assets/images/logo.svg").default}
                />
              </div>
              <div className="loginInfo">
                <h2 className="title-register">Olá, crie sua conta!</h2>
                <h5 className="description">
                  Conte-nos um pouco sobre você e sua empresa.
                </h5>
                <div className="inputs">
                  {components.map((item) =>
                    item.type === "select" ? (
                      <LabelSelect
                        menuPlacement={"top"}
                        className="login-select"
                        placeholder={item.label}
                        options={item.options}
                        value={valueOptions(item.id, item.field, values)}
                        onChange={(row) => onChange(item.field, row.value)}
                        components={{ MenuList }}
                      />
                    ) : item.type === "cnpj" ? (
                      <CNPJInput
                        className="login-select"
                        placeholder={item.label}
                        value={values[item.field]}
                        onChange={(value) => onChange(item.field, value)}
                      />
                    ) : item.type === "cellphone" ? (
                      <CellphoneInput
                        className="login-select"
                        placeholder={item.label}
                        value={values[item.field]}
                        onChange={(value) => onChange(item.field, value)}
                      />
                    ) : (
                      <LabelInput
                        className={"login-input"}
                        value={values[item.field]}
                        placeholder={item.label}
                        type={item.field}
                        onChange={(value) => onChange(item.field, value)}
                      />
                    )
                  )}
                  <div className="terms">
                    <div className="terms-a">
                      <input
                        type="checkbox"
                        className="inputCheck"
                        onChange={(value) =>
                          onChange("terms", value.target.checked)
                        }
                        value={values.terms}
                      />{" "}
                      Eu aceito os{" "}
                      <a
                        href="https://mediacredit.com.br/termos-de-uso/"
                        target="_blank"
                      >
                        Termos de Uso,
                      </a>{" "}
                      <a
                        href="https://mediacredit.com.br/termo-de-consulta-scr/"
                        target="_blank"
                      >
                        Termos de Consulta,
                      </a>{" "}
                      <a
                        href="https://mediacredit.com.br/termo-de-autorizacao-de-consulta-de-recebiveis/"
                        target="_blank"
                      >
                        Termo de Autorização de Consulta de Recebível
                      </a>{" "}
                      e{" "}
                      <a
                        href="https://mediacredit.com.br/politica-de-privacidade/"
                        target="_blank"
                      >
                        Politica de privacidade
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-absolute">
              <div className="navButonLog">
                <div className="loginBtn">
                  <DefaultButton
                    disabled={loading || values.terms === false}
                    label={loading ? "Entrando.." : "Cadastrar"}
                    onClick={handleLogin}
                    className={"shadow-5"}
                  />
                </div>
              </div>
              <div className="backToLogin">
                <h6
                  className={"forgotPassword"}
                  onClick={() => history.push("/home")}
                >
                  Voltar para o login
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterScreen;

const MenuList = ({ children, ...props }) => (
  <reactComp.MenuList {...props} innerProps={{ class: "-MenuList" }}>
    {children}
  </reactComp.MenuList>
);

function valueOptions(id, field, values) {
  if (id === 4) {
    return optionsPlans.find((row) => row.value === values[field]);
  } else if (id === 5) {
    return optionsTime.find((row) => row.value === values[field]);
  } else {
    return options.find((row) => row.value === values[field]);
  }
}

export const optionsPlans = [
  { value: 0, label: "Plano Ads+ 30", credit: "5000", amount: "150" },
  { value: 1, label: "Plano Ads+ 45", credit: "20000", amount: "450" },
  { value: 2, label: "Plano Ads+ 60", credit: "50000", amount: "2000" },
  { value: 3, label: "Plano Ads+ 90", credit: "100000", amount: "4800" },
  // { value: 4, label: "Pacote 2.000 Créditos", credit: "2000" },
  // { value: 5, label: "Pacote 5.000 Créditos", credit: "5000" },
  // { value: 6, label: "Pacote 10.000 Créditos", credit: "10000" },
  // { value: 7, label: "Pacote 50.000 Créditos", credit: "50000" },
  // { value: 8, label: "Pacote personalizado de Créditos" },
];

export const optionsTime = [
  { value: 0, label: "Nunca anunciei" },
  { value: 1, label: "Menos de 6 meses" },
  { value: 2, label: "De 6 meses a 1 ano" },
  { value: 3, label: "Mais de 1 ano" },
];
export const options = [
  { value: 0, label: "Nunca investi" },
  { value: 1, label: "Menos de R$ 1.000,00 por mês" },
  { value: 2, label: "De R$ 1.000,00 a R$ 5.000,00 por mês" },
  { value: 3, label: "De R$ 5.000,00 a R$ 20.000,00 por mês" },
  { value: 4, label: "De R$ 20.000,00 a R$ 50.000,00 por mês" },
  { value: 5, label: "Acima de R$ 50.000,00 por mês" },
];

const components = [
  { id: 0, label: "CNPJ", type: "cnpj", field: "cnpj" },
  { id: 1, label: "Nome", type: "text", field: "name" },
  { id: 2, label: "Telefone", type: "cellphone", field: "phone" },
  { id: 3, label: "Link do site ou rede social", type: "text", field: "link" },
  {
    id: 4,
    label: "Selecione o Plano desejado",
    type: "select",
    field: "plan",
    options: optionsPlans,
  },
  {
    id: 5,
    label: "Há quanto tempo sua empresa tem realizado anúncios pagos?",
    type: "select",
    field: "time",
    options: optionsTime,
  },
  {
    id: 6,
    label: "Qual é o investimento médio mensal em anúncios pagos?",
    type: "select",
    field: "invest",
    options: options,
  },
  { id: 7, label: "E-mail", type: "text", field: "email" },
  { id: 8, label: "Senha", type: "text", field: "password" },
];
