import React from 'react';
import { BsArrowLeft, BsArrowRight, BsChevronDown, BsChevronUp, BsTextIndentLeft, BsTextIndentRight } from 'react-icons/bs';
import { ProSidebar, Menu, MenuItem, SidebarContent, SidebarFooter, SubMenu } from 'react-pro-sidebar';
import { menuRoutes } from '../../core/router/routes';
import { useDispatch } from 'react-redux';
import { setData } from '../../core/redux/app/Actions';
import { useHistory } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

export const SidebarScreen = ({ collapsed, setCollapsed, pathname, loggedUser, company }) => {
    const dispatch = useDispatch();
    const history = useHistory()
    return (
        <ProSidebar collapsed={collapsed}>
            <SidebarContent>
                <div className="logo">
                    {collapsed ? <img className="sidebar-logo mini" src={require("../../assets/images/logo-icon-menor.svg").default} /> :
                        <img className="sidebar-logo" src={require("../../assets/images/logo-branca.svg").default} />}
                </div>
                <Menu iconShape="square">
                    {menuRoutes(loggedUser, company).filter(item => !item.hidden).map((route, index) => (
                        <>
                            {collapsed
                                ? <MenuItem
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        if (route.path === '/modules') {
                                            setCollapsed(true)
                                        } else {
                                            dispatch(setData({ navigate: route.path }))
                                            history.push(route.path)
                                            if (!collapsed) {
                                                setCollapsed(true)
                                            }
                                        }
                                    }}
                                    id={route.selector}
                                    key={`menu-routes-${index}`}
                                    className={route.mobile && "mobile"}
                                    icon={route.icon}
                                    active={route.path === pathname}
                                >
                                    {route.title}
                                </MenuItem>
                                : route.childs && route.childs.length > 0
                                    ? <SubMenu
                                        icon={route.icon}
                                        title={route.title}
                                        defaultOpen={true}
                                    >
                                        {route.childs.map((child) => (
                                            <MenuItem
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    dispatch(setData({ navigate: child.path }))
                                                    history.push(child.path)
                                                    if (!collapsed) {
                                                        setCollapsed(true)
                                                    }
                                                }}
                                                active={child.path === pathname}
                                            >
                                                {child.title}
                                            </MenuItem>
                                        ))}
                                    </SubMenu>
                                    : <MenuItem
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            dispatch(setData({ navigate: route.path }))
                                            history.push(route.path)
                                            if (!collapsed) {
                                                setCollapsed(true)
                                            }
                                        }}
                                        id={route.selector}
                                        key={`menu-routes-${index}`}
                                        className={route.mobile && "mobile"}
                                        icon={route.icon}
                                        active={route.path === pathname}
                                    >
                                        {route.title}
                                    </MenuItem>
                            }
                        </>
                    ))}

                </Menu>
            </SidebarContent>
            <div onClick={() => setCollapsed(collapsed => !collapsed)} key={`menu-routes-exit`} className={`icon-collapse  ${collapsed ? 'collapsed' : ''}`}>
                {collapsed ? <FaArrowRight /> : <FaArrowLeft />}
                {!collapsed ? <div className='text-collapse'>Encolher menu</div> : <div />}
            </div>
        </ProSidebar>
    )
}