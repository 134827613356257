import moment from "moment";
import { convertMoney } from "../../components/HomeTablesProgress";

const ColumnMobile = ({
  badge: BadgeComponent,
  data,
  name,
  onClick,
  separetor,
}) => {

  return (
    <div className="line-extrato" onClick={() => onClick(data)}>
      <div className="main-info">
        <div className="info-left">
          <div className="color">
            <BadgeComponent status={data.status} data={data} />
          </div>
          <div className="info-dentro">
            <div className="name">{name}</div>
            <div>
              {data?.date && moment(data.date).format("DD/MM/YYYY")}
              {data?.wins_in && moment(data.wins_in).format("DD/MM/YYYY")}
              {data.open_in && moment(data.open_in).format("DD/MM/YYYY")}
            </div>
          </div>
        </div>
        <div>
          {data.consumption ? "-" : ""}
          {data.value && convertMoney(data.value)}
        </div>
      </div>
    </div>
  );
};

export default ColumnMobile;
