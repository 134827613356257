import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { alertError, alertSuccess } from "../../components/Toast";
import { sendEmail } from "../../core/email";
import { sendCahback, sendCashbackRescued } from "../../core/email/cashback";
import { setData } from "../../core/redux/app/Actions";
import { saveCashback } from "../../core/utils/cachsback";
import { compareValues } from "../../core/utils/compareValues";
import { saveUser } from "../../core/utils/user";
import CashbackDetailsScreen from "../../screens/CashbackDetailsScreen";
import { supabase } from "../../supabaseClient";

const CashbackDetailsContainer = ({ id }) => {
  const isNew = id === "0";
  const history = useHistory();
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [loadingSave, setLoadingSave] = useState(false)
  const [oldValues, setOldValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const isChange = compareValues(values, oldValues);
  const loggedUser = useSelector((state) => state.app.user);
  const reload = useSelector(
    (state) => state.app.reload_login_cashback || false
  );
  const [saveing, setSaveing ] = useState(false)
  useEffect(() => {
    loadData();
  }, [id]);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadData = async () => {
    const { data } = await supabase
      .from("Cashback")
      .select()
      .eq("id", id)
      .is("deleted_at", null);

    setValues(isNew ? {} : data[0]);
    setOldValues(isNew ? {} : data[0]);
    setLoading(false);
  };

  const loadUsers = async () => {
    const { data, error } = await supabase
      .from("user")
      .select()
      .eq("type", 1)
      .is("deleted_at", null);

    setUsers(data);
  };

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  const onSave = async () => {
    setSaveing(true)
    const userSelect = users.find(item => item.id === values.id_user)
    try {
      if (!values.id_user) {
        throw new Error("Selecione um úsuario para salvar!");
      }

      if (!values.name) {
        throw new Error("Adicione um nome!");
      }

      if (!values.value) {
        throw new Error("Selecione um valor!");
      }

      if (!values.wins_in) {
        throw new Error("Selecione a data que vencera a fatura!");
      }

      if (!values.status) {
        throw new Error("Selecione um status!");
      }
      setLoadingSave(true)

      await saveCashback(values.id, {
        ...values,
        value: parseInt(values.value),
      });

      if (!values.id) {
        sendEmail(userSelect.email, "Cashback gerado Media Credit", sendCahback(userSelect.name))
      }

      if (values.status === 2) {
        const userTransaction = users.find(
          (item) => item.id === values?.id_user
        );
        await saveUser(values?.id_user, {
          valueCredit:
            parseInt(userTransaction?.valueCredit) + parseInt(values?.value),
        });
        sendEmail(userSelect.email, "Cashback resgatado com sucesso", sendCashbackRescued(userSelect.name))
      }

      dispatch(setData({ reload_login_cashback: !reload }));
      history.push("/installment", { tab: 2 });
      alertSuccess("Crédito cadastrado com sucesso!");


      setLoadingSave(false)
    } catch (e) {
      alertError(e.message);
    }
    setSaveing(false)
  };

  const closeModal = () => {
    if (isChange) {
      Swal.fire({
        title: "Deseja sair sem salvar?",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: "warning",
        text: "Ao sair, seus dados seram descartados.",
        denyButtonText: `Descartar`,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isDenied) {
          history.push("/installment");
        }
      });
    } else {
      history.push("/installment");
    }
  };

  return (
    <CashbackDetailsScreen
      type={loggedUser.type}
      users={users?.map((item) => ({ label: item.name, value: item.id }))}
      values={values}
      onSave={onSave}
      onChange={onChange}
      isChange={isChange}
      loading={loading}
      closeModal={closeModal}
      loadingSave={loadingSave}
     saveing={saveing}
    />
  );
};

export default CashbackDetailsContainer;
