import React, { useRef, Fragment, useState } from "react";
import ActionSheet from "actionsheet-react";
import { FiTrash } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import GridTable from "@nadavshaar/react-grid-table";
import { BsFiletypePdf, BsPencil } from "react-icons/bs";
import { IconDropzone } from "../../components/Dropzone/Dropzone";
import { supabase } from "../../supabaseClient";
import moment from "moment";
import { convertMoney } from "../../components/HomeTablesProgress";
import "react-tabs/style/react-tabs.css";
import { IoMdEye } from "react-icons/io";
import { isMobile } from "react-device-detect";
import ColumnMobile from "../columnMobile";
import { NotResultCompontent } from "../NotResultComponent";

export const ConsumptionExtractTable = ({ data, type, onDelete }) => {
  const [aux, setAux] = useState();
  let history = useHistory();
  const ref = useRef();

  const handleOpen = (data) => {
    setAux(data);

    ref.current.open();
  };

  return (
    <div className="div-table">
      <GridTable
        columns={isMobile ? columnsMobile(handleOpen) : columns(type, onDelete)}
        rows={data}
        texts={{
          noResults: <NotResultCompontent />,
          search: "Buscar:",
          totalRows: "Número de itens:",
          rows: "Itens:",
          selected: "Selecionado",
          rowsPerPage: "Itens por página:",
          page: "Página:",
          of: "de",
          prev: "Anterior",
          next: "Próximo",
          columnVisibility: "Colunas visíveis",
        }}
        showSearch={false}
        showRowsInformation={false}
        showColumnVisibilityManager={false}
        isPaginated={false}
        enableColumnsReorder={false}
      />

      <ActionSheet ref={ref}>
        {type === 0 ? (
          <div className="ModalMobile">
            <div
              className="infosMobile"
              onClick={() =>
                history.push(`/installment/details/${aux.id}`, { type })
              }
            >
              Editar
            </div>
            <div
              className="infosMobile"
              onClick={() => onDelete(aux, "consumption")}
            >
              Excluir
            </div>
          </div>
        ) : (
          <div className="ModalMobile">
            <div
              className="infosMobile"
              onClick={() =>
                history.push(`/installment/details/${aux.id}`, { type })
              }
            >
              Visualizar
            </div>
          </div>
        )}
      </ActionSheet>
    </div>
  );
};

const columnsMobile = (handleOpen) => [
  {
    id: 1,
    field: "status",
    label: "Extrato",
    width: "10fr",
    pinned: true,
    resizable: false,
    cellRenderer: ({ data, rowIndex }) => (
      <ColumnMobile
        status={data.status}
        badge={BadgeStatusExtract}
        data={data}
        isMobile={true}
        name={data.name}
        onClick={() => handleOpen(data)}
        value={data.value}
      />
    ),
  },
];

const columns = (type, onDelete) => [
  {
    id: 1,
    field: "status",
    label: "Status",
    pinned: true,
    resizable: false,
    cellRenderer: ({ data, rowIndex }) => (
      <BadgeStatusExtract status={data.status} data={data} />
    ),
  },
  {
    id: 2,
    field: "name",
    label: "Nome",
    width: "1fr",
    paddingLeft: "30px !important",
    resizable: false,
    cellRenderer: ({ data, rowIndex }) => {
      return <div>{data.name}</div>;
    },
  },
  {
    id: 3,
    field: "user.name",
    label: "Empresa",
    width: "1fr",
    paddingLeft: "30px !important",
    resizable: false,
    cellRenderer: ({ data, rowIndex }) => {
      return <div>{data.user?.name}</div>;
    },
  },
  {
    id: 4,
    field: "date",
    label: "Data",
    width: "1fr",
    paddingLeft: "30px !important",
    resizable: false,
    cellRenderer: ({ data, rowIndex }) => {
      return <div>{moment(data.date).format("DD/MM/YYYY")}</div>;
    },
  },
  {
    id: 5,
    field: "value",
    label: "Valor",
    width: "1fr",
    paddingLeft: "30px !important",
    resizable: false,
    cellRenderer: ({ data }) => {
      return (
        <div>
          {data.consumption ? "-" : ""}
          {convertMoney(data?.value || 0)}
        </div>
      );
    },
  },
  {
    id: 6,
    field: "actions",
    label: "Ações",
    pinned: true,
    resizable: false,
    cellRenderer: ({ data }) => (
      <ActionCell
        type="consumption"
        data={data}
        userType={type}
        onDelete={onDelete}
        status={data.status}
      />
    ),
  },
];

export const ActionCell = ({
  type,
  data,
  invoice = false,
  userType,
  onDelete,
  status,
}) => {
  let history = useHistory();

  const downloadDocument = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { data: tickets, error } = await supabase.storage
      .from(`tickets`)
      .createSignedUrl(`${data.id}/ticket`, 3600);

    if (tickets) {
      window.open(tickets.signedURL, "_blank");
    }
  };

  const downloadConsumption = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { data: tickets, error } = await supabase.storage
      .from(`ticketsTransaction`)
      .createSignedUrl(data.ticket.replace("ticketsTransaction/", ""), 3600);
    if (tickets) {
      window.open(tickets.signedURL, "_blank");
    }
  };

  return (
    <div className="div-icon">
      {invoice && ((data?.ticket?.id && userType === 1) || userType === 0) ? (
        <>
          {userType === 0 ? (
            <IconDropzone
              table={`tickets/${data.id}`}
              filename="ticket"
              type={userType}
              icon={
                <BsFiletypePdf
                  style={{ marginLeft: 10 }}
                  className="icons-action"
                />
              }
            />
          ) : (
            <BsFiletypePdf
              style={{ marginLeft: 10 }}
              className="icons-action"
              onClick={(e) => (userType === 1 ? downloadDocument(e) : {})}
            />
          )}
        </>
      ) : (
        <></>
      )}
      {userType === 0 && data.consumption && data.ticket && (
        <BsFiletypePdf
          style={{ marginLeft: 10 }}
          className="icons-action"
          onClick={(e) => downloadConsumption(e)}
        />
      )}
      {/* {status === 4 && <MdOutlineCancel
                className="icons-action"
                style={{ marginLeft: 10 }}
                onClick={() => history.push(`/installment/details/${data.id}`, { type: "disaprovedCredits" })}
            />} */}
      {userType === 0 ? (
        <BsPencil
          className="icons-action"
          style={{ marginLeft: 10 }}
          onClick={() =>
            history.push(`/installment/details/${data.id}`, { type })
          }
        />
      ) : (
        <IoMdEye
          className="icons-action"
          style={{ marginLeft: 10 }}
          onClick={() =>
            history.push(`/installment/details/${data.id}`, { type })
          }
        />
      )}

      {userType === 0 && (
        <FiTrash
          className="icons-action delete"
          style={{ marginLeft: 10 }}
          onClick={() => onDelete(data, "consumption")}
        />
      )}
    </div>
  );
};

const BadgeStatusExtract = ({ status, data }) => {
  let history = useHistory();
  let result;
  // if (data.consumption) {
  //   switch (status) {
  //     case 2:
  //       result = (
  //         <div className="status reprovado">
  //           {isMobile ? "" : "Boleto recusado"}
  //         </div>
  //       );
  //       break;
  //     case 3:
  //       result = <div className="status"> {isMobile ? "" : "Boleto pago"}</div>;
  //       break;
  //     default:
  //       result = (
  //         <div className="status processamento">
  //           {isMobile ? "" : "Boleto em pagamento"}
  //         </div>
  //       );
  //   }
  //   return result;
  // } else {
  switch (status) {
    case 1:
      result = (
        <div className="status processamento">
          {" "}
          {isMobile ? "" : "Em processamento"}{" "}
        </div>
      );
      break;
    case 2:
      result = (
        <div className="status analise">
          {" "}
          {isMobile ? "" : "Crédito em análise"}
        </div>
      );
      break;
    case 3:
      result = (
        <div className="status"> {isMobile ? "" : "Crédito recebido"}</div>
      );
      break;
    case 4:
      result = (
        <div
          style={{ cursor: "pointer" }}
          className="status reprovado"
          onClick={() =>
            history.push(`/installment/details/${data.id}`, {
              type: "disaprovedCredits",
            })
          }
        >
          {isMobile ? "" : "Crédito reprovado"}
        </div>
      );
      break;
    case 5:
      result = (
        <div className="status analise">{isMobile ? "" : "Assinatura CCB"}</div>
      );
      break;

    case 6:
      result = (
        <div className="status liberado">
          {isMobile ? "" : "Limite liberado"}
        </div>
      );
      break;

    default:
      result = (
        <div className="status processamento">
          {" "}
          {isMobile ? "" : "Em processamento"}
        </div>
      );
  }
  return result;
  // }
};
