import { supabase } from "../../supabaseClient"

export const getUser = async (email) => {
    let { data, error } = await supabase
        .from('Users')
        .select("*")
        .eq("email", email)

    if (!error) {
        return data[0]
    } else {
        return {}
    }
}


export const saveUser = async (id = undefined, item) => {

    if (id) {
        return await supabase
            .from('user')
            .update({ ...item })
            .match({ id })
    } else {
        return await supabase
            .from('user')
            .insert([{ ...item }])
    }
}