import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { alertError, alertSuccess } from "../../components/Toast";
import { saveUser } from "../../core/utils/user";
import HomeDetailsScreen from "../../screens/HomeDetailsScreen";
import { supabase } from "../../supabaseClient";
import { sendEmail } from "../../core/email";
import { sendSignature } from "../../core/email/sendSignature";

const HomeDetailsContainer = () => {
  const history = useHistory();
  const loggedUser = useSelector((state) => state.app.user);
  const [values, setValues] = useState({});
  const [user, setUser] = useState({});
  const [reload, setReload] = useState(false);

  useEffect(() => {
    loadData();
  }, [reload]);

  const loadData = async () => {
    const { data } = await supabase
      .from("user")
      .select()
      .eq("id", loggedUser.id)
      .is("deleted_at", null);

    setUser(data[0]);
  };

  const signContract = async () => {
    try {
      if (!values.signature) {
        throw new Error("Precisamos ao menos de sua assinatura!");
      }

      const { data } = await supabase.storage
        .from(`signature`)
        .createSignedUrl(`${loggedUser.id}/signature`, 315360000);

      await saveUser(loggedUser.id, {
        ...loggedUser,
        signature: values.link || data.signedURL,
        contract_refused_at: null,
      });
      sendEmail(
        "plataforma@mediacredit.com.br",
        "Assinatura",
        sendSignature(user.name, user.id)
      );
      history.push("/home");
      alertSuccess("Assinatura enviada com sucesso!");
      setReload((reload) => !reload);
    } catch (e) {
      alertError(e.message);
    }
  };

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  const onLinking = async () => {
    const { data, error } = await supabase.storage
      .from(`signature`)
      .createSignedUrl(`${loggedUser.id}/not_signature`, 315360000);

    if (!data?.signedURL) {
      alertError("Aguarde! Contrato ainda não enviado.");
    } else {
      window.open(data.signedURL, "_blank");
    }
  };

  return (
    <HomeDetailsScreen
      history={history}
      signContract={signContract}
      user={user}
      onChange={onChange}
      values={values}
      onLinking={onLinking}
    />
  );
};

export default HomeDetailsContainer;
