import React from "react";
import { useSelector } from "react-redux";
import { optionsPlans } from "../../screens/RegisterScreen/RegisterScreen";

const PaynamentHeaderContainer = (props) => {
    const title = props?.title || "Pacotes de créditos";

    const user = useSelector((state) => state.app.user);
    if (!user?.id) {
        return;
    }
    const plan = optionsPlans?.find((item) => item.value == user?.plan)?.label;

    return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
        <div className="title-planos">{title}</div>
        <img className="coin" src={require("../../assets/images/coin.3d.png").default} />
    </div>
}

export default PaynamentHeaderContainer;