export const sendAdsTicket = (userName) => {
  return `
    <div>
    <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
  <p style="text-align: left;">&nbsp;</p>
  <p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
  <h2 style="text-align: center;">Boleto enviado com sucesso!</h2>
  <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
  <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">O boleto para consumir seu limite de Ads foi enviado com sucesso. <br />O time da Media Credit está avaliando o boleto e disponibilizará a CCB em breve para assinatura. <br />Fique atento à sua caixa de entrada para não perder atualizações do progresso.</p>
  <br />
  <p style="text-align: center;">
  <button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
    <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br/home" target="_blank">
    Acompanhar
     </a>
      </p>
      </button>
    </div>
    <br />
    <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Obrigado,<br />Equipe Media Credit</p>
    </div>
    `;
};

export const sendAdsReceived = (userName) => {
  return `
  <div>
  <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
<h2 style="text-align: center;">Assine a CCB para consumir o limite</h2>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">O boleto de Google e Meta Ads para consumo de limite foi aprovado. <br />A CCB foi enviada para o e-mail do representante legal da empresa para assinatura. <br />Acompanhe o processo clicando abaixo:</p>
<br />
<p style="text-align: center;">
<button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
  <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br/paynament" target="_blank">
  Acompanhar
   </a>
    </p>
    </button>
  </div>
  <br />
  <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Obrigado,<br />Equipe Media Credit</p>
  </div>
  `;
};

export const sendAdsFinish = (userName) => {
  return `
  <div>
  <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
<h2 style="text-align: center;">Crédito Ads recebido</h2>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">O boleto de Google e Meta Ads para consumo de limite foi pago com sucesso e o recurso foi creditado nas contas para investir em anúncios. <br />Acompanhe o consumo do seu limite e a fatura atual clicando no link abaixo:</p>
<br />
<p style="text-align: center;">
<button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
  <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br/installment" target="_blank">
  Acompanhar
   </a>
    </p>
    </button>
  </div>
  <br />
  <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Obrigado,<br />Equipe Media Credit</p>
  </div>
  `;
};

export const sendAsProcess = (userName) => {
  return `
  <div>
  <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
<h2 style="text-align: center;">Boleto pago em processamento</h2>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">O boleto enviado para consumo do pacote foi processado. <br />O time da Media Credit realizou o pagamento do boleto e a compensação pode levar até 3 dias úteis. <br />Acompanhe o processamento na plataforma:</p>
<br />
<p style="text-align: center;">
<button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
  <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br/paynament" target="_blank">
  Acompanhar
   </a>
    </p>
    </button>
  </div>
  <br />
  <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Obrigado,<br />Equipe Media Credit</p>
  </div>
  `;
};

export const sendAdsReproved = (userName) => {
  return `
  <div>
  <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
<h2 style="text-align: center;">O boleto foi reprovado</h2>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">O boleto de Google e Meta Ads para consumo de limite foi reprovado. <br />Entre em contato com o time da Media Credit, clicando no botão abaixo, para saber mais detalhes:</p>
<br />
<p style="text-align: center;">
<button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
  <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="http://wa.me/5548996575085" target="_blank">
  Falar com o time
   </a>
    </p>
    </button>
  </div>
  <br />
  <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Obrigado,<br />Equipe Media Credit</p>
  </div>
  `;
};

export const sendPackageProcess = (userName) => {
  return `
  <div>
  <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
<h2 style="text-align: center;">Boleto enviado com sucesso</h2>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">O boleto para consumir seu pacote foi enviado com sucesso. <br />O time da Media Credit está avaliando o boleto e enviará notificação sobre o status de pagamento em breve. <br />Fique atento à sua caixa de entrada para não perder atualizações do progresso.</p>
<br />
<p style="text-align: center;">
<button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
  <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br" target="_blank">
  Acompanhar
   </a>
    </p>
    </button>
  </div>
  <br />
  <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Obrigado,<br />Equipe Media Credit</p>
  </div>
  `;
};

export const sendPackageReproved = (userName) => {
  return `
  <div>
  <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
<h2 style="text-align: center;">Boleto reprovado</h2>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">O boleto para consumo seu pacote foi reprovado. <br />Entre em contato com o time do Media Credit, clicando no botão abaixo, para saber mais detalhes:</p>
<br />
<p style="text-align: center;">
<button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
  <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br" target="_blank">
  Falar com o time
   </a>
    </p>
    </button>
  </div>
  <br />
  <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Obrigado,<br />Equipe Media Credit</p>
  </div>
  `;
};

export const sendPackageFinish = (userName) => {
  return `
  <div>
  <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
<h2 style="text-align: center;">Pacote recebido</h2>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">O pacote foi consumido com sucesso e o recurso foi creditado para pagamento do boleto enviado. <br />Adquira mais pacotes clicando no botão abaixo:</p>
<br />
<p style="text-align: center;">
<button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
  <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br/paynament" target="_blank">
  Comprar pacote
   </a>
    </p>
    </button>
  </div>
  <br />
  <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Obrigado,<br />Equipe Media Credit</p>
  </div>
  `;
};

export const sendPackageCustom = (userName) => {
  return `
  <div>
  <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
<h2 style="text-align: center;">Reunião agendada com sucesso!</h2>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Sua reunião com o time da Media Credit foi agendada.</p>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Iremos debater os seguintes assuntos: <br />- Apresentação da Media Credit <br />- Estrutura de Marketing e Perfil de Investidor da sua Empresa <br />- Metodologia de Performance e Indicadores Estratégicos</p>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Para uma experiência completa, recomendamos acessar a reunião de um computador ou notebook com câmera para melhor visualização das informações. <br />Embora não seja obrigatório, o uso de uma webcam promove uma interação mais próxima e envolvente. <br />Certifique-se de ter um microfone e fone de ouvido para uma comunicação clara e eficaz. <br />Garanta uma conexão de internet estável, preferencialmente com cabo, para uma experiência sem interrupções. <br />Se tiver sugestões adicionais ou se preferir incluir mais algum tópico específico, por favor, sinta-se a vontade. Estamos ansiosos para compartilhar insights valiosos e discutir como a Media Credit pode impulsionar o sucesso da sua empresa.</p>
<br />
  <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Obrigado,<br />Equipe Media Credit</p>
  </div>
  `;
};
