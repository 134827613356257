import React from "react";
import { NotResultCompontent } from "../NotResultComponent";
import { MdPictureAsPdf } from "react-icons/md";
import { supabase } from "../../supabaseClient";
import { alertError } from "../Toast";

const Header = ({ data }) => {
  return (
    <thead>
      <tr>
        {data.map((item) => (
          <th>{item.name}</th>
        ))}
      </tr>
    </thead>
  );
};

const Lista = ({ data, type }) => {
  const downloadDocument = async (item) => {
    const { data: tickets, error } = await supabase.storage
      .from(`tickets`)
      .createSignedUrl(`${item.id}/ticket`, 3600);

    if (tickets) {
      window.open(tickets.signedURL, "_blank");
    } else {
      alertError("Boleto indisponível")
    }
  };

  return (
    <tbody>
      {data.map((item) => (
        <tr className="list">
          <td className="name-text-list">{item.name}</td>
          <td>{item && item.value}</td>
          {item && item.closed_in ? <td>{item && item.closed_in}</td> : <></>}
          <td>{item && item.expired_in}</td>
          {type === "cashback" ? <td style={{ cursor: "pointer" }} onClick={() => downloadDocument(item)}><MdPictureAsPdf /></td> : <td style={{ cursor: "pointer" }} >{options?.find((row) => row?.value === item?.status).label}</td>}
        </tr>
      ))}
    </tbody>
  );
};

const TableCard = ({ arrayHeader = [], arrayList = [], type }) => {

  return (
    <div className="card-table">
      {arrayList && arrayList.length > 0 ?
        <table className="table">
          {arrayHeader.length > 0 && <Header data={arrayHeader} />}
          <Lista data={arrayList} type={type} />
        </table> : <div className="not-result-center">
          <NotResultCompontent height={100} width={100} size={12} />
        </div>}
    </div>
  );
};

export default TableCard;

const options = [
  { value: 1, label: "Resgate solicitado" },
  { value: 2, label: "Resgatado" },
  { value: 3, label: "Disponível" },
  { value: 4, label: "Expirado" },
];