import React, { useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import HeaderContainer from "../../containers/Header/HeaderContainer";
import { SidebarContainer } from "../../containers/Sidebar/SidebarContainer";
import { menuRoutes } from "./routes";
import { RouteModal } from "../../components/Modal/Modal";
import { useSelector } from "react-redux";

export const AuthRoutes = (props) => {
  const history = useHistory();
  const user = useSelector((state) => state.app.user || {});

  useEffect(() => {
    const unlisten = history.listen(() => {
      sessionStorage.setItem("lastPath", history.location.pathname);
    });
    return unlisten;
  }, [history]);

  useEffect(() => {
    if (
      history.location.pathname === "/" ||
      history.location.pathname === "/login"
    ) {
      if (!user.google_token && !user.meta_token) {
        history.push("/home?sendEmailAds");
      } else {
        history.push("/home");
      }
    }
  }, []);

  // const lastPath = sessionStorage.getItem("lastPath");
  // console.log(history.location.pathname)

  return (
    <div className="container-app">
      <SidebarContainer {...props} />
      <div className="body-app">
        <HeaderContainer {...props} />
        <div className="admin-screen-content">
          <div className={"admin-screen-main"}>
            {menuRoutes(user).map(({ path, Component }) => (
              <Route key={path} path={path} component={Component} />
            ))}
          </div>
          {menuRoutes(user)
            .filter((item) => item.details)
            .map((route, index) => (
              <Route
                key={`route-details-${index}`}
                path={`${route.path}/details/:id`}
                render={(props) => (
                  <RouteModal expanded={true} {...route}>
                    {route.details({ ...props, parentPath: route.path })}
                  </RouteModal>
                )}
              />
            ))}
          {/* <Route
            path="*"
            render={() => <Redirect to={(lastPath || "/home") + window?.location?.search} />}
          /> */}
        </div>
      </div>
    </div>
  );
};
