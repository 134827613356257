import { sort } from "fast-sort";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { alertError, alertSuccess } from "../../components/Toast";
import { removeCashback, saveCashback } from "../../core/utils/cachsback";
import { removeInvoice } from "../../core/utils/invoice";
import { removeTransactions } from "../../core/utils/transaction";
import ConsumptionExtractScreen from "../../screens/ConsumptionExtractScreen/ConsumptionExtractScreen";
import { supabase } from "../../supabaseClient";

const ConsumptionExtractContainer = (props) => {
  const history = useHistory();
  const loggedUser = useSelector((state) => state.app.user);
  const [tab, setTab] = useState(0);
  const [users, setUsers] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [reload, setReload] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [cashbacks, setCashBacks] = useState([]);
  const [filter, setFilter] = useState({});
  const { state } = props.location;
  const reloadTransactions = useSelector(
    (state) => state.app.reload_login_consuption || false
  );
  const reloadInvoices = useSelector(
    (state) => state.app.reload_login_ticket || false
  );
  const reloadCashback = useSelector(
    (state) => state.app.reload_login_cashback || false
  );

  useEffect(() => {
    if (state && state.tab) {
      setTab(state.tab);
    }
  }, [state && state.tab]);

  useEffect(() => {
    if (loggedUser.type === 0) {
      loadUsers();
    }
  }, [loggedUser.type]);

  useEffect(() => {
    loadTransactions();
  }, [tab, reload, reloadTransactions]);

  useEffect(() => {
    loadInvoces();
  }, [tab, reload, reloadInvoices]);

  useEffect(() => {
    loadCashbacks();
  }, [tab, reload, reloadCashback]);

  const loadTransactions = async () => {
    let query = supabase.from("Transaction").select(
      `
      *,
        user (
            id,
            name
        )
      `
    );
    if (loggedUser.type !== 0) {
      query = query.eq("id_user", loggedUser.id).is("deleted_at", null);
    } else {
      query = query.is("deleted_at", null);
    }

    query = query.order("created_at", { ascending: false });

    const { data, error } = await query;

    if (!error) {
      setTransactions(sort(data).desc((item) => moment(item.date).toDate()));
    }
  };

  const loadCashbacks = async () => {
    let query = supabase.from("Cashback").select();

    if (loggedUser.type !== 0) {
      query = query.eq("id_user", loggedUser.id).is("deleted_at", null);
    } else {
      query = query.is("deleted_at", null);
    }

    const { data, error } = await query;

    if (!error) {
      setCashBacks(sort(data).desc((item) => moment(item.wins_in).toDate()));
    }
  };

  const loadInvoces = async () => {
    let query = supabase.from("Invoice").select(`
    *,
      user (
          id,
          name
      )
    `);

    if (loggedUser.type !== 0) {
      query = query.eq("id_user", loggedUser.id).is("deleted_at", null);
    } else {
      query = query.is("deleted_at", null);
    }

    query = query.order("expired_in", { ascending: false });

    const { data, error } = await query;

    const promises = data?.map(async (item) => {
      const ticket = await supabase.storage.from(`tickets`).list(`${item.id}`);

      return {
        ...item,
        ticket: ticket.data[0] || false,
      };
    });

    const formatData = await Promise.all(promises).then((response) => response);

    if (!error) {
      setInvoices(
        sort(formatData).asc((item) => moment(item.closed_in).toDate())
      );
    }
  };

  const loadUsers = async () => {
    const { data, error } = await supabase
      .from("user")
      .select()
      .eq("type", 1)
      .is("deleted_at", null);

    setUsers(data.map((item) => ({ label: item.name, value: item.id })));
  };

  const changeTab = (tab) => {
    setTab(tab);
  };

  const onDelete = async (item, field) => {
    Swal.fire({
      title: "Deseja excluir este item?",
      showCancelButton: true,
      showConfirmButton: false,
      showDenyButton: true,
      icon: "warning",
      text: "Ao excluir, seu item será descartado.",
      denyButtonText: `Excluir`,
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isDenied) {
        if (field === "consumption") {
          delete item.user;
          await removeTransactions(item);
          alertSuccess("Removido com sucesso!");
        } else if (field === "ticket") {
          delete item.ticket;
          delete item.user;
          await removeInvoice(item);
          alertSuccess("Removido com sucesso!");
        } else {
          delete item.user;
          await removeCashback(item);
          alertSuccess("Removido com sucesso!");
        }
        setReload((reload) => !reload);
      }
    });
  };

  const onRescue = async (item) => {
    try {
      await saveCashback(item.id, { ...item, status: 1 });
      setReload((reload) => !reload);
      alertSuccess("Cashback solicitado com sucesso!");
    } catch (e) {
      alertError(e.message);
    }
  };

  return (
    <ConsumptionExtractScreen
      changeTab={changeTab}
      tab={tab}
      history={history}
      type={loggedUser.type}
      users={users}
      setFilter={setFilter}
      invoices={filteredData(invoices, filter)}
      transactions={filteredData(transactions, filter)}
      onDelete={onDelete}
      cashbacks={filteredData(cashbacks, filter)}
      onRescue={onRescue}
    />
  );
};

export default ConsumptionExtractContainer;

const filteredData = (data, filter) => {
  return data?.filter((item) => doFilter(item, filter));
};

const doFilter = (item, filter) => {
  let hasSearch = true;
  if (filter && filter.search && item.name) {
    hasSearch = item.name.toLowerCase().includes(filter.search.toLowerCase());
  }

  let hasUser = true;
  if (filter && filter.user && filter.user.value) {
    hasUser = item.id_user === filter.user.value;
  }

  return hasSearch && hasUser;
};
