import React, { useRef, useState } from "react";
import { FiTrash } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import GridTable from "@nadavshaar/react-grid-table";
import { BsFiletypePdf, BsPencil } from "react-icons/bs";
import { IconButton } from "../../components/Button/Button";
import { FaCheck } from "react-icons/fa";
import { IconDropzone } from "../../components/Dropzone/Dropzone";
import { supabase } from "../../supabaseClient";
import "react-tabs/style/react-tabs.css";
import moment from "moment";
import { convertMoney } from "../HomeTablesProgress";
import { IoMdEye } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { isMobile } from "react-device-detect";
import ColumnMobile from "../columnMobile";
import ActionSheet from "actionsheet-react";
import { NotResultCompontent } from "../NotResultComponent";

export const DetailsCashbackTable = ({ data, type, onDelete, onRescue }) => {
  const [aux3, setAux3] = useState();
  let history = useHistory();
  const ref = useRef();

  const handleOpen = (data) => {
    setAux3(data);
    ref.current.open();
  };

  return (
    <div className="div-table">
      <GridTable
        columns={
          isMobile
            ? columnsMobile(handleOpen)
            : columns(type, onDelete, onRescue)
        }
        rows={data}
        texts={{
          noResults: <NotResultCompontent />,
          search: "Buscar:",
          totalRows: "Número de itens:",
          rows: "Itens:",
          selected: "Selecionado",
          rowsPerPage: "Itens por página:",
          page: "Página:",
          of: "de",
          prev: "Anterior",
          next: "Próximo",
          columnVisibility: "Colunas visíveis",
        }}
        showSearch={false}
        showRowsInformation={false}
        showColumnVisibilityManager={false}
        isPaginated={false}
        enableColumnsReorder={false}
      />
      <ActionSheet ref={ref}>
        <div className="ModalMobile">
          {type === 0 ? (
            <>
              <div
                className="infosMobile"
                onClick={() =>
                  history.push(`/installment/details/${aux3.id}`, {
                    type: "cashback",
                  })
                }
              >
                Editar{" "}
              </div>
              <div className="infosMobile" onClick={() => onDelete(aux3)}>
                {" "}
                Excluir
              </div>
            </>
          ) : (
            <div
              className="eyeMobile"
              onClick={() =>
                history.push(`/installment/details/${aux3.id}`, {
                  type: "cashback",
                })
              }
            >
              {" "}
              Vizualizar{" "}
            </div>
          )}
        </div>
      </ActionSheet>
    </div>
  );
};

const columnsMobile = (handleOpen) => [
  {
    id: 1,
    field: "status",
    label: "Detalhes de cashback",
    width: "10fr",
    pinned: true,
    resizable: false,
    cellRenderer: ({ data, rowIndex }) => (
      <ColumnMobile
        status={data.status}
        badge={BadgeStatusDetailing}
        data={data}
        isMobile={true}
        name={data.name}
        onClick={() => handleOpen(data)}
      />
    ),
  },
];

const columns = (type, onDelete, onRescue) => [
  {
    id: 1,
    field: "status",
    label: "Status",
    pinned: true,
    resizable: false,
    cellRenderer: ({ data }) => <BadgeStatusDetailing status={data.status} />,
  },
  {
    id: 2,
    field: "name",
    label: "Nome",
    width: "1fr",
    paddingLeft: "30px !important",
    resizable: false,
    cellRenderer: ({ data }) => {
      return <div>{data.name}</div>;
    },
  },
  {
    id: 3,
    field: "value",
    label: "Valor",
    width: "1fr",
    paddingLeft: "30px !important",
    resizable: false,
    cellRenderer: ({ data }) => {
      return <div style={{}}>{convertMoney(parseInt(data.value))}</div>;
    },
  },
  {
    id: 4,
    field: "date",
    label: "Vence em",
    width: "1fr",
    paddingLeft: "30px !important",
    resizable: false,
    cellRenderer: ({ data }) => {
      return (
        <div>
          {data.wins_in ? moment(data.wins_in).format("DD/MM/YYYY") : ""}
        </div>
      );
    },
  },
  {
    id: 5,
    field: "actions",
    label: "Resgatar",
    width: "1fr",
    resizable: false,
    cellRenderer: ({ data, rowIndex }) => {
      return (
        <IconButton
          icon={
            data.status === 1 ? (
              <AiOutlineLoading3Quarters color={"#ccc"} />
            ) : (
              <FaCheck color={data.status === 2 ? "white" : "#008a00"} />
            )
          }
          className={`rescue-button ${data.status === 2 ? "aproved" : data.status === 1 ? "requested" : ""
            }`}
          onClick={() =>
            data.status === 2 || data.status === 1 ? {} : onRescue(data)
          }
        />
      );
    },
  },
  {
    id: 6,
    field: "actions",
    label: "Ações",
    pinned: true,
    resizable: false,
    cellRenderer: ({ data }) => (
      <ActionCell
        type="cashback"
        data={data}
        userType={type}
        onDelete={onDelete}
      />
    ),
  },
];

export const ActionCell = ({
  type,
  data,
  invoice = false,
  userType,
  onDelete,
}) => {
  let history = useHistory();

  const downloadDocument = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { data: tickets, error } = await supabase.storage
      .from(`tickets`)
      .createSignedUrl(`${data.id}/ticket`, 3600);

    if (tickets) {
      window.open(tickets.signedURL, "_blank");
    }
  };

  return (
    <div className="div-icon">
      {invoice && ((data?.ticket?.id && userType === 1) || userType === 0) ? (
        <>
          {userType === 0 ? (
            <IconDropzone
              table={`tickets/${data.id}`}
              filename="ticket"
              type={userType}
              icon={
                <BsFiletypePdf
                  style={{ marginLeft: 10 }}
                  className="icons-action"
                />
              }
            />
          ) : (
            <BsFiletypePdf
              style={{ marginLeft: 10 }}
              className="icons-action"
              onClick={(e) => (userType === 1 ? downloadDocument(e) : {})}
            />
          )}
        </>
      ) : (
        <></>
      )}
      {userType === 0 ? (
        <BsPencil
          className="icons-action"
          style={{ marginLeft: 10 }}
          onClick={() =>
            history.push(`/installment/details/${data.id}`, { type })
          }
        />
      ) : (
        <IoMdEye
          className="icons-action"
          style={{ marginLeft: 10 }}
          onClick={() =>
            history.push(`/installment/details/${data.id}`, { type })
          }
        />
      )}
      {userType === 0 && (
        <FiTrash
          className="icons-action delete"
          onClick={() => onDelete(data)}
          style={{ marginLeft: 10 }}
        />
      )}
    </div>
  );
};


export const BadgeStatusDetailing = ({ status, color }) => {
  let result;
  switch (status) {
    case 1:
      result = (
        <div className="status processamento">
          {isMobile ? "" : "Resgate solicitado"}
        </div>
      );
      break;
    case 2:
      result = (
        <div className="status analise">{isMobile ? "" : "Resgatado"}</div>
      );
      break;
    case 3:
      result = <div className="status">{isMobile ? "" : "Disponível"}</div>;
      break;
    case 4:
      result = (
        <div className="status reprovado">{isMobile ? "" : "Expirado"}</div>
      );
      break;

    default:
      result = (
        <div className="status processamento">
          {isMobile ? "" : "Resgate Solicitado"}
        </div>
      );
  }
  return result;
};
