import React from "react";
import TicketDetailsContainer from "../TicketDetailsContainer";
import ConsumptionDetailsContainer from "../ConsumptionDetailsContainer";
import CashbackDetailsContainer from "../CashbackDetailsContainer";
import DisapprovedCreditContainer from "../DisapprovedCreditContainer";

const DetailsContainer = ({ location }) => {
  const { state, pathname } = location;
  const id = pathname.split("/")[3]

  switch (state && state.type) {
    case "ticket":
      return <TicketDetailsContainer id={id} />;
    case "cashback":
      return <CashbackDetailsContainer id={id} />;
    case "disaprovedCredits":
      return <DisapprovedCreditContainer id={id} />
    default:
      return <ConsumptionDetailsContainer id={id} />;
  }
};

export default DetailsContainer;
