import { supabase } from "../../supabaseClient";

export const saveTransaction = async (id = undefined, item) => {
  if (id) {
    return await supabase
      .from("Transaction")
      .update({ ...item })
      .match({ id });
  } else {
    return await supabase.from("Transaction").insert([{ ...item }]);
  }
};

export const removeTransactions = async (item) => {
  return await supabase
    .from("Transaction")
    .update({ ...item, deleted_at: new Date() })
    .match({ id: item.id });
};
