import React from "react";
import { DefaultButton } from "../../components/Button/Button";
import { IoMdAdd } from "react-icons/io";
import TableCard from "../../components/TableCard";
import moment from "moment";
import { sort } from "fast-sort";
import "moment/locale/pt-br";

export const HomeTablesProgress = ({
  history,
  data,
  cashback,
  typeCashback,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <div className="line">
        <div
          style={{
            width: "37%",
            backgroundColor: "#4ffffd",
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
            display: "flex",
          }}
        />
        <div style={{ width: "38%", backgroundColor: "#ffd809" }} />
        <div
          style={{
            width: "24%",
            backgroundColor: "#ff004d",
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
            display: "flex",
          }}
        />
      </div>
      <div className="card-container">
        {components.map(
          ({
            id,
            title,
            subTitle,
            ticketButton,
            textButton,
            buttonIcon,
            ticketIcon,
            send,
            color,
          }) => (
            <div className="card">
              <div className="titles">
                <div className="title">{title}</div>
                {id !== 2 && (
                  <div
                    className={`subTitle-card ${
                      send ? "send" : "subTitle-card-C"
                    }`}
                  >
                    {subTitle}
                    <div className="description">
                      {id === 0
                        ? convertMoney(
                            calcTotal(data.filter((item) => item.status !== 1))
                          )
                        : convertMoney(
                            // (typeCashback / 100) *
                            calcTotal(
                              cashback.filter((item) => item.status == 3)
                            )
                          )}
                    </div>
                  </div>
                )}
                {id !== 2 && (
                  <div className="div-table">
                    <TableCard
                      arrayList={
                        id === 0
                          ? formatTransaction(data, 1)
                          : formatTransaction(cashback)
                      }
                      type={id === 0 ? "cashback" : ""}
                      arrayHeader={
                        id === 0
                          ? arrayHeader.filter((item) => item.id !== 6)
                          : arrayHeader.filter(
                              (item) => item.id !== 3 && item.id !== 5
                            )
                      }
                    />
                  </div>
                )}
              </div>
              {id === 2 && (
                <div className="div-lottie">
                  <div className="div-lottie">
                    <lottie-player
                      key="loading-data"
                      src="https://lottie.host/53250dc0-9897-430e-8763-84890a809043/NbOYTXGmNQ.json"
                      background="transparent"
                      speed="1"
                      style={{ width: 100, height: 100 }}
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                  <div className="div-description">
                    Anexe o boleto das plataformas de anúncios para utilizar seu
                    crédito de mídia
                  </div>
                </div>
              )}
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* {id === 2 && (
                  <DefaultButton
                    style={{ backgroundColor: color }}
                    className={"ticketButton"}
                    label={ticketButton}
                    icon={ticketIcon}
                    onClick={() => history.push(`/home/details/1`, { type: 1 })}
                  />
                )} */}
              </div>
              <div className="div-button">
                <DefaultButton
                  style={{ backgroundColor: color }}
                  className={`${
                    title === "Histórico" || title === "Cashback"
                      ? `connect-button color-${id}`
                      : "connect-button ticketButton"
                  }`}
                  label={textButton}
                  icon={ticketIcon}
                  onClick={() =>
                    id === 0
                      ? history.push("/installment", { tab: 1 })
                      : id === 1
                      ? history.push("/installment", { tab: 2 })
                      : history.push(`/home/details/1`, { type: 1 })
                  }
                />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const formatTransaction = (data, type) => {
  const newData =
    type === 1 ? getPreviousCurrentNextMonths(data) : getThreeObjects(data, 1);
  return newData.map((item) => {
    const month = moment(item.closed_in).format("MMM");
    const newItem =
      type === 1
        ? {
            closed_in: item.closed_in
              ? moment(item.closed_in).format("DD/MM")
              : "",
            expired_in: item.expired_in
              ? moment(item.expired_in).format("DD/MM")
              : "",
          }
        : { expired_in: moment(item.wins_in).format("DD/MM") };

    return {
      id: item.id,
      name: month,
      value: convertMoney(parseInt(item.value)),
      status: item.status,
      ...newItem,
    };
  });
};

function getThreeObjects(array, page) {
  const objctPage = 3;
  const home = (page - 1) * objctPage;
  const end = home + objctPage;
  const objectsPage = array.slice(home, end);

  return objectsPage;
}

function getPreviousCurrentNextMonths(array) {
  if (array && array.length > 0) {
    const data = sort(array).asc((u) => u.open_in);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
    const previousYear = previousMonth === 12 ? currentYear - 1 : currentYear;
    const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
    const nextYear = nextMonth === 1 ? currentYear + 1 : currentYear;

    const filteredData = data.filter((item) => {
      const closeDate = new Date(item.closed_in);
      const closeMonth = closeDate.getMonth() + 1;
      const closeYear = closeDate.getFullYear();

      return (
        (closeMonth === previousMonth && closeYear === previousYear) ||
        (closeMonth === currentMonth && closeYear === currentYear) ||
        (closeMonth === nextMonth && closeYear === nextYear)
      );
    });

    return filteredData.sort(
      (a, b) => new Date(a.closed_in) - new Date(b.closed_in)
    );
  } else {
    return array;
  }
}

export const calcTotal = (data) => {
  return data.reduce(function (acumulator, element) {
    return acumulator + parseInt(element.value);
  }, 0);
};

export function convertMoney(value) {
  if (value === 0 || !value) {
    return "R$ 0,00";
  } else {
    return (
      "R$ " +
      value.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }
}

const components = [
  {
    id: 0,
    title: "Histórico",
    subTitle: "Em aberto ",
    textButton: "Mais detalhes",
    send: true,
  },
  {
    id: 1,
    title: "Cashback",
    subTitle: "Disponível ",
    description: " R$ 0,00",
    textButton: "Resgatar",
    send: true,
  },
  {
    id: 2,
    title: "Subir créditos",
    subTitle: "Enviar documentos",
    // textButton: "Mais detalhes",
    textButton: "Boleto ads",
    ticketIcon: <IoMdAdd className="icon-integration" />,
    send: false,
  },
];

const arrayHeader = [
  { id: 1, name: "Mês" },
  { id: 2, name: "Valor" },
  { id: 3, name: "Fecha em" },
  { id: 4, name: "Vence em" },
  { id: 5, name: "Boleto" },
  { id: 6, name: "Status" },
];
