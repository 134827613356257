
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import { Reducers } from './rootReducer'
import logger from "redux-logger";

let store;
let persist;
export const getStore = () => store;

export const getPersist = () => persist;
export default function configureStore() {
    const enhancer = compose(
        applyMiddleware(thunk, logger)
    );
    store = createStore(Reducers, enhancer);
    persist = persistStore(store);


    return store;
}