import React, { useState } from "react";
import "react-tabs/style/react-tabs.css";
import { IoMdCheckmark, IoMdAlert } from "react-icons/io";
import { IconButton } from "../../components/Button/Button";
import { BsPencil } from "react-icons/bs";
import { IoAnalytics } from "react-icons/io5";
import { FaSignature } from "react-icons/fa";
import { IconDropzone } from "../Dropzone/Dropzone";
import { LuFile, LuFileCheck } from "react-icons/lu";

export const Item = ({ children, onClick, disableMember }) => {
  const [disabled, setDisabled] = useState(true);
  return (
    <div
      style={{ flexDirection: "row", display: "flex" }}
      className={disabled || disableMember ? "item-disabled" : ""}
    >
      {" "}
      {children(disabled || disableMember)}
      {!disableMember ? (
        <div className="div-icon">
          <IconButton
            icon={
              disabled || disableMember ? (
                <BsPencil className="icon" color="black" />
              ) : (
                <IoMdCheckmark
                  className="icon"
                  color="green"
                  onClick={onClick}
                />
              )
            }
            disabled={disableMember}
            onClick={() => setDisabled((disabled) => !disabled)}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const ItemCheck = ({
  disabled,
  children,
  approvedDefault,
  recusedDefault,
  onCheck,
  type,
  signature,
  onSignature,
  table,
  filename,
  ticket = false,
  changeDropzone = () => {},
  sendEmailFiles,
  notMore = false,
}) => {
  return (
    <div
      style={{ flexDirection: "row", display: "flex" }}
      className={"item-disabled"}
    >
      {children}
      {notMore ? (
        <> </>
      ) : disabled ? (
        <>
          <div className="div-icon">
            {verificationButton(approvedDefault, recusedDefault)}
          </div>
        </>
      ) : (
        <>
          {type === 1 && signature && (
            <div className="div-icon">
              <IconButton
                icon={
                  <FaSignature title="Aprovar" className="icon" color="black" />
                }
                disabled={disabled}
                className={disabled ? "block" : ""}
                onClick={onSignature}
              />
            </div>
          )}
          {type === 2 && !signature && (
            <div className="div-icon">
              <IconDropzone
                table={table}
                filename={filename}
                type={0}
                onChange={changeDropzone}
                sendEmailFiles={sendEmailFiles}
                icon={
                  ticket ? (
                    <LuFileCheck className="icons-action" />
                  ) : (
                    <LuFile className="icons-action" />
                  )
                }
              />
            </div>
          )}
          <div className="div-icon">
            <IconButton
              icon={
                <IoMdAlert
                  title="Retornar para análise"
                  className="icon"
                  color="brown"
                />
              }
              disabled={disabled}
              className={disabled ? "block" : ""}
              onClick={() => onCheck(false)}
            />
          </div>
          <div className="div-icon">
            <IconButton
              icon={
                <IoMdCheckmark title="Aprovar" className="icon" color="green" />
              }
              disabled={disabled}
              className={disabled ? "block" : ""}
              onClick={() => onCheck(true)}
            />
          </div>
        </>
      )}
    </div>
  );
};

const verificationButton = (aproved, recused) => {
  if (aproved === null && recused === null) {
    return <IoAnalytics />;
  } else if (aproved && recused === null) {
    return <IoMdCheckmark title="Aprovar" className="icon" color="green" />;
  } else if (recused && aproved === null) {
    return (
      <IoMdAlert title="Retornar para análise" className="icon" color="brown" />
    );
  }
};
