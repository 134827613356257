import React from "react";
import { DefaultButton } from "../../components/Button/Button";
import { IoMdShare, IoMdArrowUp, IoMdCreate, IoMdLock } from "react-icons/io";
import { BsClipboardCheck } from "react-icons/bs";
import { RiBarcodeBoxLine } from "react-icons/ri";
import { HiOutlineDocumentAdd } from "react-icons/hi";
import { Stepper } from "react-form-stepper";
import Swal from "sweetalert2";
import { FaCheck } from "react-icons/fa";

export const ConectComponent = ({
  active,
  setActive,
  user,
  history,
  onTicket,
  doGoogleLogin,
  doMetaLogin,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <div className="title-jornada">Conclua seu cadastro</div>
      <div>
        <Stepper
          steps={steps}
          activeStep={active}
          className="stepper"
          styleConfig={{
            activeBgColor: "#FF004D",
            completedBgColor: "#199900",
          }}
        />
      </div>
      <div className="card-container">
        {components(user, history, onTicket).map(
          (
            {
              id,
              title,
              subTitle,
              description,
              icon,
              textButton,
              buttonIcon,
              color,
              blocked,
              sucess,
              onClick,
            },
            index
          ) => {
            const send = index <= active;
            return (
              <div className="card">
                {sucess && (
                  <div className="checked">
                    <FaCheck color="#008a00" />
                  </div>
                )}
                {blocked && (
                  <div className="blocked">
                    <IoMdLock />
                  </div>
                )}
                <div
                  className={`card-icons ${send ? "send" : "card-icons-check"}`}
                >
                  {icon}
                </div>
                <div className={`titles ${send ? "send" : "titles-C"}`}>
                  <div className={`title ${send ? "send" : "title-C"}`}>
                    {title}
                  </div>
                  <div
                    className={`subTitle-card ${
                      send ? "send" : "subTitle-card-C"
                    }`}
                  >
                    {subTitle}
                  </div>
                  <div style={{ marginRight: "10px", paddingLeft: "20px" }}>
                    {description}
                  </div>
                </div>
                <div className="div-button">
                  {id != 1 && (
                    <DefaultButton
                      style={{ backgroundColor: color }}
                      className={`connect-button color-${id}`}
                      label={textButton}
                      icon={buttonIcon}
                      onClick={() => {
                        setActive(index);
                        if (onClick) {
                          onClick();
                        }
                      }}
                    />
                  )}
                  {id === 1 && (
                    <DefaultButton
                      style={{ backgroundColor: color, marginRight: 10 }}
                      className={`connect-button color-${id}`}
                      label={"Google"}
                      icon={buttonIcon}
                      disabled={user.google_token}
                      onClick={doGoogleLogin}
                    />
                  )}
                  {id === 1 && (
                    <DefaultButton
                      style={{ backgroundColor: color, marginLeft: 10 }}
                      className={`connect-button color-${id}`}
                      label={"Meta"}
                      icon={buttonIcon}
                      disabled={user.meta_token}
                      onClick={doMetaLogin}
                    />
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

const components = (user, history, onTicket) => [
  {
    id: 0,
    title: "Etapa 1",
    subTitle: "Cadastro Inicial",
    description: user.document_approved_at
      ? "A etapa de cadastro na Plataforma Media Credit foi concluída"
      : "Envie os documentos para concluir o seu cadastro",
    textButton: "Vizualizar",
    icon: <BsClipboardCheck className="icon" />,
    onClick: () => history.push(`/data/${user.id}`),
    sucess: user.register_approved_at,
  },
  {
    id: 1,
    title: "Etapa 2",
    subTitle: "Conecte as contas de ads",
    description: "Conecte as contas de ads e descubra seu limite pré-aprovado",
    textButton: "CONECTAR",
    buttonIcon: <IoMdShare className="icon" />,
    icon: <IoMdShare className="icon" />,
    blocked: !user.register_approved_at,
    sucess: user.social_media_approved_at,
  },
  {
    id: 2,
    title: "Etapa 3",
    subTitle: "Enviar documentos",
    description:
      "Para liberar seu limite pré-aprovado compartilhe documentos da sua empresa",
    textButton: "Upload de documentos",
    buttonIcon: <IoMdArrowUp className="icon" />,
    icon: <HiOutlineDocumentAdd className="icon" />,
    onClick: () => history.push(`/data/${user.id}`, { tab: 1 }),
    blocked: !user.social_media_approved_at,
    sucess: user.document_approved_at,
  },
  {
    id: 3,
    title: "Etapa 4",
    subTitle: user.contract_approved_at
      ? user.ticket_send_at
        ? "Pagar boleto"
        : "Aguardando envio do boleto"
      : "Contrato e mensalidade",
    description: user.contract_approved_at
      ? user.ticket_send_at
        ? "Pague o seu boleto para continuar"
        : ""
      : "Assine o contrato e pague a primeira mensalidade e comece a usar o seu limite",
    textButton: user.contract_approved_at ? "Boleto" : "Assinar",
    buttonIcon: user.contract_approved_at ? (
      false
    ) : (
      <IoMdCreate className="icon" />
    ),
    icon: <RiBarcodeBoxLine className="icon" />,
    onClick: () =>
      user.contract_approved_at
        ? onTicket()
        : history.push(`/home/details/${user.id}`),
    blocked: !user.document_approved_at,
  },
];

const steps = [
  { label: "Cadastro inicial" },
  { label: "Conectar as contas de ads" },
  { label: "Enviar documentos" },
  { label: "Contrato e mensalidade" },
];
