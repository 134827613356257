import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { alertError, alertSuccess } from "../../components/Toast";
import { getTranslation } from "../../core/Translator";
import { supabase } from "../../supabaseClient";
import LoginScreen from "../../screens/Login/LoginScreen";
import { useDispatch } from "react-redux";
import { setData } from "../../core/redux/app/Actions";
import { openRoutes } from "../../core/router/routes";
import RegisterScreen from "../../screens/RegisterScreen/RegisterScreen";
import { validateEmail } from "../../core/utils/string";

const RegisterContainer = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({ email: "", password: "" });
  const [hidden, setHidden] = useState(false);
  const lastPath = sessionStorage.getItem("lastPath");

  const handleLogin = async () => {
    try {
      setLoading(true);
      const extraProps = {};
      const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

      if (values && values.email && validateEmail(values.email)) {
        if (values && !values.cnpj) {
          return alertError("CNPJ é obrigatório!");
        }
        if (values && !values.name) {
          return alertError("Nome é obrigatório!");
        }
        if (values && !values.phone) {
          return alertError("Telefone é obrigatório!");
        }

        if (values && !values.link) {
          return alertError("Link do site é obrigatório!");
        }

        if (typeof values.plan !== "number") {
          return alertError("Plano é obrigatório!");
        }

        if (typeof values.invest !== "number") {
          return alertError("Investimento médio é obrigatório!");
        }

        if (typeof values.time !== "number") {
          return alertError("Tempo anunciando é obrigatório!");
        }

        if (values && !values.password) {
          return alertError("Senha é obrigatória!");
        }

        if (values.password) {
          extraProps.password = values.password;
        }

        const userToAdd = { ...values, register_approved_at: new Date() };
        delete userToAdd.password;
        const { error: errorCreateUser, data } = await supabase
          .from("user")
          .insert([userToAdd])
          .select();

        if (!errorCreateUser) {
          const { error, session } = await supabase.auth.signUp(
            { email: values.email, ...extraProps },
            {
              shouldCreateUser: false,
            }
          );
          if (error) throw error;

          if (!session) {
            alertSuccess("Enviamos uma solicitação para o seu email!");
          }

          history.push("/");
        } else {
          alertError(errorCreateUser.message);
        }
      } else {
        alertError("E-mail inválido!");
      }
    } catch (error) {
      alertError(getTranslation(error.error_description || error.message));
      // console.log("Errors", { error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (openRoutes?.filter((item) => item.path === lastPath)?.length === 0) {
      sessionStorage.setItem("lastPath", "/home");
      dispatch(setData({ navigate: "/home" }));
    }
  }, []);

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  return (
    <RegisterScreen
      handleLogin={handleLogin}
      onChange={onChange}
      values={values}
      loading={loading}
      history={history}
      hidden={hidden}
      setHidden={setHidden}
    />
  );
};

export default RegisterContainer;
