import moment from "moment";
import React from "react";
import { MdClose } from "react-icons/md";
import { LoadingButton } from "../../components/Button/Button";
import {
  DateInput,
  LabelInput,
  LabelSelect,
  MoneyInput,
  TextInput,
} from "../../components/Input/Input";
import { LoadingComponent } from "../../components/LoadingComponent";

const ConsumptionDetailsScreen = ({
  history,
  type,
  onSave,
  values,
  isChange,
  onChange,
  users,
  loading,
  closeModal,
  loadingSave,
  saveing,
}) => {
  return (
    <div className="modal-consumption-container">
      <div className="modal-header">
        <div className="header-title">Consumo</div>
        <div>
          {isChange ? (
            <LoadingButton
              className={`btn-save-details`}
              label={"Salvar"}
              onClick={onSave}
              loading={loadingSave}
              disabled={saveing}
            />
          ) : (
            <></>
          )}
          <MdClose
            size={20}
            onClick={closeModal}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <div className="modal-consumption-body">
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            {type === 0 ? (
              <LabelSelect
                className={"documents-select"}
                placeholder={"Usuário"}
                options={users}
                onChange={(item) => onChange("id_user", item.value)}
                value={users?.find((item) => item.value === values.id_user)}
                disabled={loadingSave}
              />
            ) : (
              <></>
            )}
            <LabelInput
              className={"documents-input"}
              placeholder={"Nome"}
              onChange={(item) => onChange("name", item)}
              value={values && values.name}
              disabled={type === 1 || loadingSave}
            />
            <DateInput
              className={"documents-input"}
              placeholder={"Data"}
              onChange={(item) => onChange("date", item)}
              value={
                values && values.date ? moment(values.date).toDate() : null
              }
              disabled={type === 1 || loadingSave}
            />
            <MoneyInput
              className={"documents-input"}
              placeholder={"Valor"}
              onChange={(item) => onChange("value", item)}
              value={values?.value}
              disabled={type === 1 || loadingSave}
            />
            {type === 0 ? (
              <LabelSelect
                className="documents-select"
                placeholder={"Status"}
                options={options}
                onChange={(item) => onChange("status", item.value)}
                disabled={type === 1 || loadingSave}
                value={options.find((item) => item.value === values?.status)}
              />
            ) : (
              <></>
            )}
            {(type === 1 && values?.details) || type === 0 ? (
              <TextInput
                className={"documents-input"}
                placeholder={"Detalhes"}
                onChange={(event) => onChange("details", event.target.value)}
                value={values?.details}
                area={"area"}
                disabled={type === 1 || loadingSave}
              />
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ConsumptionDetailsScreen;

const options = [
  { value: 1, label: "Em processamento" },
  { value: 2, label: "Crédito em análise" },
  { value: 3, label: "Crédito recebido" },
  { value: 4, label: "Crédito  reprovado" },
  { value: 5, label: "Assinatura CCB" },
  { value: 6, label: "Limite liberado" },
];

const optionsConsumption = [
  { value: 1, label: "Boleto em pagamento" },
  { value: 2, label: "Boleto reprovado" },
  { value: 3, label: "Boleto pago" },
];
