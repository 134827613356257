export const sendSignature = (userName, userid) => {
  //aqui devemos criar o html padrão, que ta no figma, o content, deve estar dentro desse padrão
  return `
  <div>
  <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
<h2 style="text-align: center;">Usuário pronto para a análise de documentos</h2>
<p  style="text-align: center;">Olá, Media Credits!</p>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">O usuário ${userName} enviou a assinatura para análise, verifique clicando no botão abaixo:</p>
<br />
<p style="text-align: center;">
<button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
  <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br/data/${userid}" target="_blank">
   Verificar assinatura</a>
    </p>
    </button>
</div>
<br />
<p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Obrigado,<br />Equipe Media Credit</p>
  </div>
  `;
};
