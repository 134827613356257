import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { DefaultButton } from "../../components/Button/Button";
import { BiPlus } from "react-icons/bi";
import { IconInput, LabelSelect } from "../../components/Input/Input";
import { IoIosSearch } from "react-icons/io";
import { ConsumptionExtractTable } from "../../components/ConsumptionExtractTable";
import { InvoiceHistoryTable } from "../../components/InvoiceHistoryTable";
import { DetailsCashbackTable } from "../../components/DetailsCashbackTable";
import "react-tabs/style/react-tabs.css";
import { isMobile } from "react-device-detect";

const ConsumptionExtractScreen = ({
  changeTab,
  tab,
  history,
  type,
  users,
  setFilter,
  invoices,
  transactions,
  onDelete,
  cashbacks,
  onRescue,
}) => {
  return (
    <>
      <div className="consumption-container">
        <div className="header">
          <div style={{ marginRight: "15px" }}>
            <IconInput
              placeholder={"Pesquisar"}
              inputClassname="input-size"
              icon={<IoIosSearch className="search-Icon" />}
              style={{ color: "black" }}
              onChange={(value) =>
                setFilter((filters) => ({ ...filters, search: value }))
              }
            />
          </div>
          {!isMobile && type === 0 && (
            <LabelSelect
              menuPlacement={"bottom"}
              className="select-size"
              placeholder={"Usuário"}
              options={users}
              isClearable={true}
              onChange={(value) =>
                setFilter((filters) => ({ ...filters, user: value }))
              }
            />
          )}
          {type === 0 && (
            <div>
              <DefaultButton
                label={
                  isMobile ? (
                    <BiPlus className="icon-filter" />
                  ) : (
                    <>
                      <span>Novo Item</span>
                      <BiPlus className="icon-filter" />
                    </>
                  )
                }
                className="new-button"
                onClick={() =>
                  history.push(`/installment/details/0`, {
                    type:
                      tab === 0
                        ? "consumption"
                        : tab === 1
                        ? "ticket"
                        : "cashback",
                  })
                }
              />
            </div>
          )}
        </div>
        <Tabs selectedIndex={tab} onSelect={(index) => changeTab(index)}>
          <TabList>
            <Tab className="tabs">
              {" "}
              {isMobile ? "Extrato" : "Extrato de consumo"}
            </Tab>
            <Tab className="tabs">
              {isMobile ? "Faturas" : "Histórico de faturas"}
            </Tab>
            <Tab className="tabs">
              {isMobile ? "Cashback" : "Detalhamento de cashback"}
            </Tab>
          </TabList>
          <TabPanel className="tab-panel">
            <ConsumptionExtractTable
              data={transactions}
              type={type}
              onDelete={onDelete}
            />
          </TabPanel>
          <TabPanel className="tab-panel">
            <InvoiceHistoryTable
              data={invoices}
              type={type}
              onDelete={onDelete}
            />
          </TabPanel>
          <TabPanel className="tab-panel">
            <DetailsCashbackTable
              data={cashbacks}
              type={type}
              onDelete={onDelete}
              onRescue={onRescue}
            />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default ConsumptionExtractScreen;
