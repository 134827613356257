export const sendCahback = (userName) => {
  return `
      <div>
      <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
    <p style="text-align: left;">&nbsp;</p>
    <p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
    <h2 style="text-align: center;">Cashback gerado Media Credit</h2>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Cashback gerado</p>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Gostaríamos de informar que o seu consumo do mês gerou cashback na sua conta</p>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Para resgatar seu cashback e utilizá-lo, siga estas instruções simples: <br />1. Acesse a Plataforma Media Credit <br />2. Clique em "Suas movimentações" e depois em "Detalhamento de Cashback". <br />3. Escolha a opção de resgate disponível. <br />4. Ao emitir sua próxima fatura nas plataformas de anúncios, inclua o valor do cashback. Nós realizaremos o pagamento.</p>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Não deixe seu cashback acumular poeira! Resgate agora e potencialize suas estratégias!</p>
<br />
    <p style="text-align: center;">
    <button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
      <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br/installment" target="_blank">
      Resgatar
       </a>
        </p>
        </button>
      </div>
      <br />
      <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Obrigado,<br />Equipe Media Credit</p>
      </div>
      `;
};

export const sendCashbackRescued = (userName) => {
  return `
      <div>
      <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
    <p style="text-align: left;">&nbsp;</p>
    <p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
    <h2 style="text-align: center;">Cashback resgatado com sucesso!</h2>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Cashback resgatado</p>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Gostaríamos de informar que o cashback gerado em seu consumo foi resgatado com sucesso!</p>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Continue a utilizar seu limite disponível e gere ainda mais cashback para aproveitar ao máximo os benefícios da nossa plataforma.</p>
    <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Clique no botão abaixo para acessar sua conta na Media Credit e explorar todos os recursos disponíveis:</p>
<br />
    <p style="text-align: center;">
    <button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
      <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br/home" target="_blank">
      Acessar Media Credit
       </a>
        </p>
        </button>
      </div>
      <br />
      <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Obrigado,<br />Equipe Media Credit</p>
      </div>
      `;
};
