import React from "react";
import { useHistory } from "react-router-dom";
import PackagesScreen from "../../screens/PackagesScreen/PackagesScreen";

const PackagesContainer = () => {
  const history = useHistory();

  return <PackagesScreen
    history={history}
  />

};

export default PackagesContainer;