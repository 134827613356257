import React from "react";
import { DefaultButton } from "../../components/Button/Button";
import {
  IoMdShare,
  IoIosArrowRoundForward,
  IoMdAdd,
  IoMdLock,
} from "react-icons/io";
import { ConectComponent } from "../../components/ConectStepper";
import {
  calcTotal,
  convertMoney,
  HomeTablesProgress,
} from "../../components/HomeTablesProgress";
import moment from "moment";

const HomeNotDataScreen = ({
  invoice,
  user,
  active,
  setActive,
  doGoogleLogin,
  type,
  history,
  onTicket,
  cashback,
  limit,
  plan,
  typeCashback,
  doMetaLogin,
  consumption,
}) => {
  return (
    <>
      <div className="home-container">
        <div className="header">
          <div className="top-header">
            <div className="text-limit">
              {limit || !user.social_media_approved_at
                ? "Limite liberado"
                : "Limite pré-aprovado"}
            </div>
            <div
              className="header-info"
              style={{
                flexDirection: "row",
                display: "flex",
              }}
            >
              <div className="limit">
                {limit || !user.social_media_approved_at
                  ? convertMoney(limit)
                  : convertMoney(plan?.credit)}
              </div>
              <DefaultButton
                className="payment-button"
                label={<IoIosArrowRoundForward className="icon-header" />}
                onClick={() => history.push("/installment")}
              />
            </div>
            <div>
              {type === 1 && (
                <div className="div-dados">
                  {dados.map(({ dados, title, id }) => (
                    <div
                      className={id === 3 ? "title-dados-green" : "title-dados"}
                    >
                      {title}
                      <div className="dados">
                        {id === 0
                          ? convertMoney(user.valueCredit)
                          : id === 3
                          ? convertMoney(
                              // typeCashback *
                              calcTotal(
                                cashback.filter((item) => item.status !== 2)
                              )
                            )
                          : invoiceWins(id, consumption)}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {type === 1 ? (
            <div>
              <div
                style={{
                  fontWeight: "500",
                  marginLeft: "15px",
                  fontSize: "18px",
                }}
              >
                Uso por plataforma
              </div>
              <div className="card-header-container">
                {serviceAws(doGoogleLogin, doMetaLogin, user).map(
                  ({
                    id,
                    value,
                    textButton,
                    buttonIcon,
                    img,
                    blocked,
                    onClick,
                    disabled,
                  }) => (
                    <div
                      className="card-header"
                      style={{ position: "relative" }}
                    >
                      {value && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {img && <div className="img-card-header">{img}</div>}
                          <div>
                            <DefaultButton
                              className={
                                id === 2
                                  ? "integration-button"
                                  : "connect-button"
                              }
                              label={textButton}
                              icon={buttonIcon}
                              onClick={onClick}
                              // disabled={disabled}
                            />
                          </div>
                        </div>
                      )}
                      {blocked && id === 2 && (
                        <div className="blocked">
                          <IoMdLock />
                        </div>
                      )}
                      {id === 2 && (
                        <div>
                          <DefaultButton
                            className={id === 2 ? "integration-button" : ""}
                            label={textButton}
                            icon={buttonIcon}
                          />
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  fontWeight: "500",
                  marginLeft: "15px",
                  fontSize: "18px",
                }}
              >
                Integrações
              </div>
              <div className="card-header-container">
                {service(doGoogleLogin, doMetaLogin, user).map(
                  ({
                    id,
                    title,
                    textButton,
                    buttonIcon,
                    subTitle,
                    img,
                    onClick,
                    disabled,
                  }) => (
                    <div className="card-header">
                      {title && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {img && <div className="img-card-header">{img}</div>}
                          {title && (
                            <div className="title-card-header">
                              {title}
                              {subTitle && (
                                <div className="subTitle-card-header">
                                  {subTitle}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      <div>
                        <DefaultButton
                          className={
                            id === 2 ? "integration-button" : "connect-button"
                          }
                          label={textButton}
                          icon={buttonIcon}
                          onClick={onClick}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </div>
        {type === 1 ? (
          <HomeTablesProgress
            data={invoice}
            cashback={cashback}
            active={active}
            setActive={setActive}
            user={user}
            history={history}
            typeCashback={typeCashback}
          />
        ) : (
          <ConectComponent
            active={active}
            setActive={setActive}
            user={user}
            history={history}
            onTicket={onTicket}
            doGoogleLogin={doGoogleLogin}
            doMetaLogin={doMetaLogin}
          />
        )}
      </div>
    </>
  );
};

export default HomeNotDataScreen;

const invoiceWins = (id, invoices) => {
  const invoicesCurrentMonth = invoiceClosesInCurrentMonth(invoices);
  // invoices && invoices.find(invoiceClosesInCurrentMonth);
  if (invoicesCurrentMonth?.id) {
    return id === 1
      ? moment(invoicesCurrentMonth.closed_in).format("DD/MM")
      : moment(invoicesCurrentMonth.expired_in).format("DD/MM");
  }
};

function getMonthAndYear(date) {
  // console.log({date})
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return { month, year };
}

function invoiceClosesInCurrentMonth(invoices) {
  for (let index = 0; index < invoices.length; index++) {
    const invoice = invoices[index];
    const currentDate =
      invoice.status === 3 ? moment().add(1, "month").toDate() : new Date();
    const { month: currentMonth, year: currentYear } =
      getMonthAndYear(currentDate);
    const invoiceDate =
      invoice.status === 3
        ? moment().add(1, "month").toDate()
        : new Date(invoice.closed_in);
    const { month: invoiceMonth, year: invoiceYear } =
      getMonthAndYear(invoiceDate);
    if (
      currentMonth === invoiceMonth &&
      currentYear === invoiceYear &&
      invoice.status === 1
    ) {
      if (invoices[index + 1] && invoices[index + 1].id) {
        return invoices[index + 1];
      }
    }
    return invoice;
  }
  // return currentMonth === invoiceMonth && currentYear === invoiceYear;
}

const service = (doGoogleLogin, doMetaLogin, user) => [
  {
    id: 0,
    title: "Meta Ads",
    textButton:
      user.meta_token && user.facebook_codes ? "CONECTADO" : "CONECTAR",
    buttonIcon: <IoMdShare className="icon-connect" />,
    subTitle: user.facebook_codes,
    onClick: doMetaLogin,
    // disabled: user.meta_token,
    img: (
      <img
        className="img-header"
        src={require("../../assets/images/meta.webp").default}
      />
    ),
  },
  {
    id: 1,
    title: "Google Ads",
    textButton:
      user.google_token && user.google_codes ? "CONECTADO" : "CONECTAR",
    buttonIcon: <IoMdShare className="icon-connect" />,
    subTitle: user.google_codes,
    onClick: doGoogleLogin,
    // disabled: user.google_token,
    img: (
      <img
        className="img-header"
        src={require("../../assets/images/Google-Ads.png").default}
      />
    ),
  },
];

const serviceAws = (doGoogleLogin, doMetaLogin, user) => {
  return [
    {
      id: 0,
      value: "0,00",
      textButton: user?.meta_token ? "CONECTADO" : "CONECTAR",
      buttonIcon: <IoMdShare className="icon-connect" />,
      onClick: doMetaLogin,
      disabled: user?.meta_token,
      img: (
        <img
          className="img-header"
          src={require("../../assets/images/meta.webp").default}
        />
      ),
    },
    {
      id: 1,
      value: "0,00",
      textButton: user?.google_token ? "CONECTADO" : "CONECTAR",
      buttonIcon: <IoMdShare className="icon-connect" />,
      onClick: doGoogleLogin,
      disabled: user?.google_token,
      subTitle: "GA_425XWE5PO",
      img: (
        <img
          className="img-header"
          src={require("../../assets/images/Google-Ads.png").default}
        />
      ),
    },
    // {
    //   id: 2,
    //   textButton: "Nova integração",
    //   buttonIcon: <IoMdAdd className="icon-integration" />,
    //   blocked: true,
    // },
  ];
};

const dados = [
  { id: 1, title: "Fecha em", dados: "30 jan" },
  { id: 2, title: "Vence em ", dados: "15 mar" },
  { id: 3, title: "Cashback a liberar ", dados: "R$ 0,00" },
];

export const calcTotalPercetage = (cashback) => {
  return cashback?.reduce(function (acumulator, element) {
    return acumulator + parseInt(element.valueCashback);
  }, 0);
};
