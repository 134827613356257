import React from "react";
import "react-tabs/style/react-tabs.css";
import {
  CNPJInput,
  CellphoneInput,
  LabelInput,
  LabelSelect,
} from "../../components/Input/Input";
import moment from "moment";
import { Item, ItemCheck } from "../FormsItem";
import { IoMdCheckmark } from "react-icons/io";
import { LoadingComponent } from "../../components/LoadingComponent";
import { useSelector } from "react-redux";
import { DefaultButton } from "../Button/Button";

const DetailsRegister = ({
  user,
  values,
  onSave,
  approveContract,
  approveDocument,
  onSignature,
  onChange,
  loading,
  docs,
  changeTicket,
  sendEmailFiles,
  refusedPreApproved,
}) => {
  const loggedUser = useSelector((state) => state.app.user);
  return (
    <div className="div-details-register-container">
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <div className="sub-title">Dados de cadastro da sua empresa</div>
          <div className="inputs">
            {components.map((item) => {
              if (item.type === "select") {
                return (
                  <Item onClick={onSave} disableMember={user.type !== 0}>
                    {(disabled) => (
                      <LabelSelect
                        menuPlacement={"top"}
                        className="documents-select"
                        placeholder={item.label}
                        options={options}
                        disabled={user.type !== 0}
                        onChange={(e) => onChange(item.field, e)}
                        value={options.find(
                          (item) => item.id == values[item.field]
                        )}
                      />
                    )}
                  </Item>
                );
              } else if (item.type === "cnpj") {
                return (
                  <Item onClick={onSave} disableMember={user.type !== 0}>
                    {(disabled) => (
                      <CNPJInput
                        className="documents-select"
                        placeholder={item.label}
                        disabled={user.type !== 0}
                        value={values[item.field]}
                        onChange={(value) => onChange(item.field, value)}
                      />
                    )}
                  </Item>
                );
              } else if (item.type === "cellphone") {
                return (
                  <Item onClick={onSave} disableMember={user.type !== 0}>
                    {(disabled) => (
                      <CellphoneInput
                        className="documents-select"
                        placeholder={item.label}
                        disabled={user.type !== 0}
                        onChange={(value) => onChange(item.field, value)}
                        value={values[item.field]}
                      />
                    )}
                  </Item>
                );
              } else if (item.type === "cashback") {
                return (
                  <Item onClick={onSave} disableMember={user.type !== 0}>
                    {(disabled) => (
                      <LabelInput
                        className={"documents-input"}
                        placeholder={item.label}
                        type={item.field}
                        disabled={user.type !== 0}
                        value={values[item.field]}
                        onChange={(value) => onChange(item.field, value)}
                      />
                    )}
                  </Item>
                );
              } else if (item.type === "register") {
                return (
                  <ItemCheck
                    disabled={user.type !== 0}
                    approvedDefault={values["register_approved_at"]}
                    recusedDefault={values["register_refused_at"]}
                    onCheck={(approved) =>
                      approveDocument(
                        approved,
                        "register_approved_at",
                        "register_refused_at"
                      )
                    }
                  >
                    <LabelInput
                      className={"documents-input"}
                      placeholder={item.label}
                      disabled={true}
                      type={item.field}
                      value={verificationAproved(
                        "Cadastro",
                        values["register_approved_at"],
                        values["register_refused_at"]
                      )}
                      values={values[item.field]}
                    />
                  </ItemCheck>
                );
              } else if (item.type === "verification") {
                return (
                  <ItemCheck
                    disabled={true}
                    notMore={true}
                    //approvedDefault={values["social_media_approved_at"]}
                    // recusedDefault={values["social_media_refused_at"]}
                    // onCheck={(approved) =>
                    //   approveDocument(
                    //     approved,
                    //     "social_media_approved_at",
                    //     "social_media_refused_at"
                    //   )
                    //}
                  >
                    <LabelInput
                      className={"documents-input"}
                      placeholder={item.label}
                      type={item.field}
                      disabled={true}
                      value={`Verificação de usuário: ${moment(
                        values[item.field]
                      ).format("DD/MM/YYYY HH:mm")} `}
                      values={values[item.field]}
                    />
                  </ItemCheck>
                );
              } else if (item.type === "social_media") {
                return (
                  <ItemCheck
                    disabled={user.type !== 0}
                    approvedDefault={values["social_media_approved_at"]}
                    recusedDefault={values["social_media_refused_at"]}
                    onCheck={(approved) =>
                      approveDocument(
                        approved,
                        "social_media_approved_at",
                        "social_media_refused_at"
                      )
                    }
                  >
                    <LabelInput
                      className={"documents-input"}
                      placeholder={item.label}
                      type={item.field}
                      disabled={true}
                      value={verificationAproved(
                        "Midia social",
                        values["social_media_approved_at"],
                        values["social_media_refused_at"]
                      )}
                      values={values[item.field]}
                    />
                  </ItemCheck>
                );
              } else if (item.type === "documents") {
                return (
                  <ItemCheck
                    disabled={user.type !== 0}
                    approvedDefault={values["document_approved_at"]}
                    recusedDefault={values["document_refused_at"]}
                    onCheck={(approved) =>
                      approveDocument(
                        approved,
                        "document_approved_at",
                        "document_refused_at"
                      )
                    }
                  >
                    <LabelInput
                      className={"documents-input"}
                      placeholder={item.label}
                      disabled={true}
                      type={item.field}
                      value={verificationAproved(
                        "Documentos",
                        values["document_approved_at"],
                        values["document_refused_at"]
                      )}
                      values={values[item.field]}
                    />
                  </ItemCheck>
                );
              } else if (item.type === "contrato") {
                return (
                  <div style={{ textAlign: "right" }}>
                    <ItemCheck
                      disabled={user.type !== 0}
                      approvedDefault={values["contract_approved_at"]}
                      recusedDefault={values["contract_refused_at"]}
                      onCheck={(approved) =>
                        approveContract(
                          approved,
                          "contract_approved_at",
                          "contract_refused_at"
                        )
                      }
                      type={1}
                      signature={values.signature}
                      onSignature={onSignature}
                      table={`signature/${values.id}`}
                      filename="not_signature"
                      sendEmailFiles={sendEmailFiles}
                      ticket={
                        docs?.filter((item) => item.name === "not_signature")
                          ?.length > 0
                          ? true
                          : false
                      }
                    >
                      <LabelInput
                        className={"documents-input"}
                        placeholder={item.label}
                        type={item.field}
                        disabled={true}
                        value={verificationAproved(
                          "Contrato",
                          values["contract_approved_at"],
                          values["contract_refused_at"]
                        )}
                        values={values[item.field]}
                      />
                    </ItemCheck>
                    {loggedUser.type === 0 && values.document_approved_at && (
                      <DefaultButton
                        label={"Reprovar limite pré-aprovado"}
                        className={`refused-pre-approved-button`}
                        onClick={refusedPreApproved}
                      />
                    )}
                  </div>
                );
              } else if (item.type === "ticket") {
                return (
                  <ItemCheck
                    disabled={user.type !== 0}
                    approvedDefault={values["ticket_approved_at"]}
                    recusedDefault={values["ticket_refused_at"]}
                    onCheck={(approved) =>
                      approveDocument(
                        approved,
                        "ticket_approved_at",
                        "ticket_refused_at"
                      )
                    }
                    type={2}
                    sendEmailFiles={sendEmailFiles}
                    signature={false}
                    table={`ticketPlan/${values.id}`}
                    filename="ticket"
                    ticket={
                      docs?.filter((item) => item.name === "ticket")?.length > 0
                        ? true
                        : false
                    }
                    changeDropzone={changeTicket}
                  >
                    <LabelInput
                      className={"documents-input"}
                      placeholder={item.label}
                      type={item.field}
                      disabled={true}
                      value={verificationAproved(
                        "Boleto",
                        values["ticket_approved_at"],
                        values["ticket_refused_at"]
                      )}
                      values={values[item.field]}
                    />
                  </ItemCheck>
                );
              } else if (item.type !== "contrato" && item.type !== "cashback") {
                return (
                  <Item onClick={onSave} disableMember={user.type !== 0}>
                    {(disabled) => (
                      <LabelInput
                        className={"documents-input"}
                        placeholder={item.label}
                        type={item.field}
                        disabled={disabled || item.field === "email"}
                        value={values[item.field]}
                        onChange={(value) => onChange(item.field, value)}
                      />
                    )}
                  </Item>
                );
              }
            })}
            <div className="terms">
              {`Em ${moment(values.created_at).format("DD/MM/YYYY HH:mm")} ${
                loggedUser.id === values.id ? "você" : "o usuário"
              } declarou aceite aos `}
              <a
                href="https://mediacredit.com.br/termos-de-uso/"
                target="_blank"
              >
                Termos de Uso,
              </a>{" "}
              <a
                href="https://mediacredit.com.br/termo-de-consulta-scr/"
                target="_blank"
              >
                Termos de Consulta,
              </a>{" "}
              <a
                href="https://mediacredit.com.br/termo-de-autorizacao-de-consulta-de-recebiveis/"
                target="_blank"
              >
                Termo de Autorização de Consulta de Recebível
              </a>{" "}
              e{" "}
              <a
                href="https://mediacredit.com.br/politica-de-privacidade/"
                target="_blank"
              >
                Politica de privacidade
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DetailsRegister;

const verificationAproved = (text, aproved, recused) => {
  if (aproved === null && recused === null) {
    return `${text}: Em analise `;
  } else if (aproved && recused === null) {
    return `${text}: Aprovado`;
  } else if (recused && aproved === null) {
    return `${text}: Recusado `;
  }
};

const terms = [
  {
    id: 0,
    name: "Termos aceitos em",
    link: "https://mediacredit.com.br/termos-de-uso/",
  },
  {
    id: 1,
    name: "Política de privacidade aceita em",
    link: "https://mediacredit.com.br/politica-de-privacidade/",
  },
  {
    id: 2,
    name: "Termo de consulta SCR aceito em",
    link: "https://mediacredit.com.br/termo-de-consulta-scr/",
  },
];

const components = [
  { id: 0, label: "CNPJ", type: "cnpj", field: "cnpj" },
  { id: 1, label: "Nome", type: "text", field: "name" },
  { id: 2, label: "Telefone", type: "cellphone", field: "phone" },
  { id: 4, label: "E-mail", type: "text", field: "email" },
  { id: 4, label: "Site ou rede social", type: "text", field: "link" },
  { id: 6, label: "Cadastro", type: "register", field: "register" },
  { id: 7, label: "verificação", type: "verification", field: "created_at" },
  { id: 8, label: "Media Social", type: "social_media", field: "social_media" },
  { id: 9, label: "Documentos", type: "documents", field: "documents" },
  { id: 10, label: "Contrato", type: "contrato", field: "contrato" },
  { id: 11, label: "Boleto", type: "ticket", field: "ticket" },
];

const options = [
  { value: 0, label: "Plano Ads+ 30" },
  { value: 1, label: "Plano Ads+ 45" },
  { value: 2, label: "Plano Ads+ 60" },
  { value: 3, label: "Plano Ads+ 90" },
  // { value: 4, label: "Pacote 2.000 Créditos" },
  // { value: 5, label: "Pacote 5.000 Créditos" },
  // { value: 6, label: "Pacote 10.000 Créditos" },
  // { value: 7, label: "Pacote 50.000 Créditos" },
  // { value: 8, label: "Pacote personalizado de Créditos" },
];
