import { LabelInput } from "../../components/Input/Input";
import { DefaultButton } from "../../components/Button/Button";
import { sendEmail } from "../../core/email";

const LoginScreen = ({
  handleLogin,
  onChange,
  values,
  loading,
  history,
  hidden,
}) => {
  return (
    <>
      <div className="login-container">
        <div className="div-background">
          <div className="form">
            <div>
              <img
                className="logo"
                src={require("../../assets/images/logo.svg").default}
              />
            </div>
            <div className="loginInfo">
              <h2 className="title-login">Acessar minha conta</h2>
              <div className="inputs">
                <LabelInput
                  className={"login-input"}
                  classLabel={"loginLabel"}
                  value={values.email}
                  placeholder={"E-mail"}
                  type={"email"}
                  onChange={(value) => onChange("email", value)}
                />
                { }
                <LabelInput
                  classLabel={"loginLabel"}
                  className={"login-input"}
                  value={values.password}
                  placeholder={"Senha"}
                  type={hidden ? "email" : "password"}
                  onChange={(value) => onChange("password", value)}
                />
                <h6
                  className={"forgotPassword"}
                  onClick={() => history.push("/recovery")}
                >
                  Esqueceu a senha?
                </h6>

                <span></span>
                <div className="navButonLog">
                  <div className="loginBtn">
                    <DefaultButton
                      disabled={loading}
                      label={loading ? "Entrando.." : "Entrar"}
                      onClick={handleLogin}
                      className={"shadow-5"}
                    />
                  </div>
                  <div className="info-h6">
                    Ainda não possui conta?
                    <h6 className={"forgotPassword"} onClick={() => {
                      history.push("/register")
                    }}>
                      Cadastre-se
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
