import React from "react";
import HomeDetailsContainer from "../HomeDetailsContainer";
import HomeTicket from "../HomeTicket";

const VerifyHomeDetailsContainer = (props) => {
    const { state } = props.location

    if (state && state.type === 1) {
        return <HomeTicket />
    } else {
        return <HomeDetailsContainer />
    }

};

export default VerifyHomeDetailsContainer;
