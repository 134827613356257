import React from "react";
import { IoIosCheckmark, IoIosClose } from "react-icons/io";
import { DropzoneInput } from "../../components/Input/Input";
import { supabase } from "../../supabaseClient";
import "react-tabs/style/react-tabs.css";
import { MdErrorOutline } from "react-icons/md";
import { LoadingComponent } from "../../components/LoadingComponent";

const DocumentsDetails = ({ user, values, onChange, setReload, findDocuments, loading }) => {
    return <div className="div-details-documents-container">
        {loading ? <LoadingComponent /> : <>
            <div className="sub-title">
                Inclua os documentos da sua empresa em formato PDF. O prazo de
                análise é de 72 horas.
            </div>
            <div className="div-documents">
                {documents.map(({ title, field }) => {
                    const formatField = `${field}_approved_at`;
                    const document = findDocuments?.filter((item) => item.name.startsWith(field));
                    const downloadDocument = async (e, field) => {
                        e.stopPropagation();
                        e.preventDefault();
                        const { data, error } = await supabase.storage
                            .from(`documents`)
                            .createSignedUrl(`${user.id}/${field}`, 3600);

                        if (data) {
                            window.open(data.signedURL, "_blank");
                        }
                    };

                    return (
                        <div className="file-container-item">
                            <DropzoneInput
                                single={false}
                                filename={field}
                                table={`documents/${user.id}`}
                                value={document.length > 0 ? document : values[field]}
                                title={title}
                                setReload={setReload}
                                onChange={(value) => onChange(field, value)}
                                downloadDocument={downloadDocument}
                                aproved={values.document_approved_at}
                                recused={values.document_refused_at}
                                placeholder={
                                    <div>
                                        {title}
                                        <div style={{ color: document ? "green" : "#4d4d4d" }}>
                                            {document.length > 0 ? "Arquivo adicionado" : "Clique ou arraste seu arquivo para adicionar"}
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    );
                })}
            </div>
        </>}
    </div>
}

export default DocumentsDetails;

const documents = [
    {
        id: 0,
        title: "Contrato social",
        subtitle: "",
        label: "Subir documento",
        field: "social_contract",
    },
    {
        id: 1,
        title: "Última alteração do contrato social",
        subtitle: "Documento carregado",
        label: "Alterar documento",
        colorButton: "#ffd809",
        iconLeft: <IoIosCheckmark className="icon" style={{ color: "green" }} />,
        color: "green",
        field: "last_social_contract",
    },
    {
        id: 2,
        title: "Balanço patrimonial (últimos 2 anos)",
        subtitle: "Documento reprovado",
        label: "Alterar documento",
        colorButton: "#ffd809",
        iconLeft: <IoIosClose className="icon" style={{ color: "red", fontSize: "16px" }} />,
        iconErro: <MdErrorOutline className="icon" style={{ color: "red" }} />,
        color: "red",
        field: "balance_patri",
    },
    {
        id: 3,
        title: "DRE (últimos 2 anos)",
        subtitle: "",
        label: "Subir documento",
        field: "dre",
    },
    {
        id: 4,
        title: "Balancete mais recente ",
        subtitle: "Documento carregado",
        label: "Alterar documento",
        colorButton: "#ffd809",
        iconLeft: <IoIosCheckmark className="icon" style={{ color: "green" }} />,
        color: "green",
        field: "balancete",
    },
    {
        id: 5,
        title: "Declaração de faturamento (últimos dois anos)",
        subtitle: "Documento carregado",
        label: "Alterar documento",
        colorButton: "#ffd809",
        iconLeft: <IoIosCheckmark className="icon" style={{ color: "green" }} />,
        color: "green",
        field: "invoicing",
    },
    {
        id: 6,
        title: "Documento de identificação dos sócios",
        subtitle: "Documento carregado",
        label: "Alterar documento",
        colorButton: "#ffd809",
        iconLeft: <IoIosCheckmark className="icon" style={{ color: "green" }} />,
        color: "green",
        field: "patner_identification",
    },
    {
        id: 7,
        title: "Comprovante de residência dos sócios",
        subtitle: "Documento carregado",
        label: "Alterar documento",
        colorButton: "#ffd809",
        iconLeft: <IoIosCheckmark className="icon" style={{ color: "green" }} />,
        color: "green",
        field: "member_receipt",
    },
];
