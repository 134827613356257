import React from "react";
import { LuFile } from "react-icons/lu";
import { MdClose } from "react-icons/md";
import { DefaultButton } from "../../components/Button/Button";
import { CustomDropzone } from "../../components/Dropzone/Dropzone";

const HomeDetailsScreen = ({
  history,
  signContract,
  user,
  onChange,
  values,
  onLinking,
}) => (
  <div className="modal-consumption-container">
    <div className="modal-header">
      <div style={{ display: "flex" }}>
        <div className="header-title">
          {user.contract_approved_at
            ? "Pagar boleto"
            : "Assinatura do contrato"}
        </div>
      </div>
      <div>
        {user.signature && !user.contract_refused_at ? (
          <></>
        ) : (
          <DefaultButton
            label={"Assinar contrato"}
            className="btn-save-details"
            onClick={signContract}
          />
        )}
        <MdClose
          size={20}
          onClick={() => history.push("/home")}
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>

    <div className="modal-consumption-body">
      {user.signature && !user.contract_refused_at ? (
        <>
          <text className="text-contract">
            Sua assinatura está em análise, aguarde.
          </text>
        </>
      ) : (
        <>
          {/* <text className="text-contract">Clique no icone para baixar o arquivo de assinatura:</text>
          {user.signature ? <></> : <div className="div-icon-pdf">
            <LuFile onClick={onLinking} />
          </div>} */}
          <div className="button-contract">
            <text className="text-contract or">
              Verifique seu email para assinatura do contrato. Você receberá um
              email da plataforma ClickSign, prossiga com assinatura e envie o
              documento abaixo:
            </text>
            <CustomDropzone
              placeholder={"Arraste e solte sua assinatura!"}
              table={`signature/${user.id}`}
              filename={`signature`}
              disabled={values?.link && values.link !== ""}
              onChange={(value) => onChange("signature", value)}
              value={values?.signature}
              withDataPlaceholder={"Assinatura Carregada"}
            />
          </div>
        </>
      )}
    </div>
  </div>
);

export default HomeDetailsScreen;
