import React from "react";
import { useHistory } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import HeaderScreen from "../../screens/HeaderScreen/index";
import { menuRoutes } from "../../core/router/routes";
import { useSelector } from "react-redux";

const HeaderContainer = () => {
  const navigate = useSelector((state) => state.app.navigate);
  const user = useSelector((state) => state.app.user || {});
  let history = useHistory();
  const route = menuRoutes(user).find((item) => item.path === window.location.pathname);

  console.log({route})

  const onLogout = () => {
    supabase.auth.signOut();
    history.push("/");
  };

  return <HeaderScreen onLogout={onLogout} route={route} history={history} />;
};

export default HeaderContainer;
