import React, { useState } from "react";
import { CNPJInput, LabelSelect } from "../../components/Input/Input";
import { DefaultButton } from "../../components/Button/Button";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { sendEmail } from "../../core/email";
import { sendPackageCustom } from "../../core/email/ads";
import { useSelector } from "react-redux";

const CalendarScreen = () => {
  const loggedUser = useSelector((state) => state.app.user);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onEventScheduled: (e) => {
      sendEmail(
        loggedUser.email,
        "Reunião agendada com sucesso",
        sendPackageCustom(loggedUser.name)
      );
    },
  });

  return (
    <div className="calendar-container">
      <div className="title">Vamos lá!</div>
      <div className="sub-title">
        Agora vamos agendar uma reunião para validar o seu pacote
      </div>
      <div className="div-calendar">
        <InlineWidget
          url="https://calendly.com/mediacredit"
          className="calendar"
          locale={"pt-br"}
        />
      </div>
      {/* <div className="div-button">
                <DefaultButton
                    className={"default-button"}
                    label={"Agendar"}
                />
            </div> */}
    </div>
  );
};

export default CalendarScreen;
