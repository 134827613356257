import React, { useEffect, useState } from "react";
import UserScreen from "../../screens/UserScreen/UserScreen";
import { supabase } from "../../supabaseClient";
import moment from "moment";

const UserContainer = (props) => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({});

  const loadData = async () => {
    const { data } = await supabase
      .from("user")
      .select()
      .eq("type", 1)
      .is("deleted_at", null);

    setData(data);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <UserScreen
      data={filteredData(data, filter)}
      setFilter={setFilter}
      filter={filter}
    />
  );
};

export default UserContainer;

const filteredData = (data, filter) => {
  return data?.filter((item) => doFilter(item, filter));
};

const doFilter = (item, filter) => {
  let hasSearch = true;
  let hasDate = true;

  if (filter && filter.search) {
    hasSearch = item.name.toLowerCase().includes(filter.search.toLowerCase());
  }

  if (filter && filter.date) {
    hasDate =
      moment(item.created_at)
        .hours(0)
        .diff(moment(filter.date).hours(0), "h") <= 1 &&
      moment(item.created_at)
        .hours(0)
        .diff(moment(filter.date).hours(0), "h") >= -1;
  }

  return hasSearch && hasDate;
};
