import "./App.scss";
import { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { AuthRoutes, FirstRoute } from "./core/router/AuthRoutes";
import { PublicRoutes } from "./core/router/PublicRoutes";
import { alertError, ToastComponent } from "./components/Toast";
import { useDispatch } from "react-redux";
import { setData } from "./core/redux/app/Actions";

export default function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(null);

  useEffect(() => {
    const session = supabase.auth.session();
    setSession(session);
    const {data, error} = supabase.auth.onAuthStateChange((_event, session) => {
      // if (_event == "PASSWORD_RECOVERY" || _event == "recovery") {
      //   setSession(null);
      // } else {
        
      // }
      setSession(session);
      setLoading(false)
    });
    if(!session){
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    if (session && session.user && session.user.email) {
      loadUser(session.user.email);
    }
  }, [session && session.user && session.user.email]);

  const loadUser = async (email) => {
    setLoading(true);

    const { data: users, error } = await supabase
      .from("user")
      .select()
      .eq("email", email)
      .is("deleted_at", null);

    if (!error) {
      const user = users[0];
      if (user) {
        dispatch(setData({ user }));
      } else {
        alertError("Erro ao entrar, tente novamente mais tarde!");
        supabase.auth.signOut();
      }
    } else {
      setSession(null);
      alertError("Erro ao entrar, tente novamente mais tarde!");
    }
    setLoading(false);
  };

  return loading ? (
    <div />
  ) : (
    <Router>
      {session ? <AuthRoutes session={session} /> : <PublicRoutes />}
      <ToastComponent />
    </Router>
  );
}
export const isMediaCredit = process.env.REACT_APP_MEDIA_CREDIT === "true";
