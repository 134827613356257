import { DisapprovedCreditScreen } from "../../screens/ DisapprovedCreditScreen"
import { React, useEffect, useState } from "react";
import { supabase } from "../../supabaseClient";
import { useHistory } from "react-router-dom";

const DisapprovedCreditContainer = ({ id }) => {
  const history = useHistory();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData()
  }, [id]);

  const loadData = async () => {
    const { data } = await supabase
      .from("Transaction")
      .select()
      .eq("id", id)
      .is("deleted_at", null);
    setValues(data[0]);
    setLoading(false)
  };

  return <DisapprovedCreditScreen
    history={history}
    values={values}
    loading={loading}

  />
}

export default DisapprovedCreditContainer