export const sendInvoices = (userName, name, expired_in, value, id) => {
  return `
    <div>
    <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
  <p style="text-align: left;">&nbsp;</p>
  <p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
  <h2 style="text-align: center;">Você recebeu uma fatura</h2>
  <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Pague sua fatura</p>
  <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
  <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Gostaríamos de informar que uma fatura foi gerada para sua conta na Media Credit <br />Nome: ${name} <br />Vence em: ${expired_in} <br />Valor: R$${value}</p>
  <p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Por favor, tome as seguintes medidas: <br />1. Verifique os detalhes da sua fatura na plataforma; <br />2. Garanta o pagamento da fatura antes da data de vencimento para evitar quaisquer interrupções no serviço</p>
<br />
  <p style="text-align: center;">
  <button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
    <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br/installment/details/${id}" target="_blank">
    Verificar fatura
     </a>
      </p>
      </button>
    </div>
    <br />
    <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Obrigado,<br />Equipe Media Credit</p>
    </div>
    `;
};

export const sendInvoicesPaynament = (userName, name, expired_in, value) => {
  return `
  <div>
  <p style="text-align: left;"><img src="https://app.mediacredit.com.br/logo.png" /></p>
<p style="text-align: left;">&nbsp;</p>
<p style="text-align: center;"><img src="https://app.mediacredit.com.br/email.png" /></p>
<h2 style="text-align: center;">Confirmação de pagamento de fatura</h2>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Pagamento confirmado</p>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Olá, ${userName}!</p>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Queremos agradecer pelo pagamento da fatura <br />Nome: ${name} <br />Vence em: ${expired_in} <br />Valor: R$${value}</p>
<p style="font-size: 16px; color: rgba(0, 0, 0, 1);text-align: center;">Confirmamos o recebimento do pagamento. <br />Se precisar de qualquer outra assistência ou tiver alguma dúvida adicional, não hesite em nos contatar imediatamente. Estamos sempre aqui para ajudar! <br />Agradecemos sua colaboração continua e valorizamos muito sua parceria conosco.</p>
<br />
<p style="text-align: center;">
<button style="text-decoration: none;background-color: rgba(255, 92, 141, 1); border: 0px; border-radius: 6px; box-shadow: 0px 1px 38px 0px rgba(255, 92, 141, 0.45); text-align: center;"> <p>
  <a style="text-decoration: none ; color: white; font-size: 14px; font-weight: 400;" href="https://app.mediacredit.com.br/home" target="_blank">
  Consumir limite
   </a>
    </p>
    </button>
  </div>
  <br />
  <p style="text-align: center; color: rgba(110, 113, 145, 1); font-size: 10px;">Obrigado,<br />Equipe Media Credit</p>
  </div>
  `;
};
