import { isMobile } from "react-device-detect"
import { Link } from "react-router-dom"
import { Button, Spinner } from "reactstrap"

export const DefaultButton = ({ label, disabled, onClick, className = "", icon, style }) => {
    return (
        <Button
            onClick={(e) => {
                e.preventDefault()
                onClick()
            }}
            style={style}
            icon={icon}
            disabled={disabled}
            className={`button ${className}`}
        >

            <span>{label}</span>

        </Button>
    )
}

export const LinkButton = ({ label, disabled, onClick, className = "" }) => {
    return (
        <Button
            onClick={(e) => {
                e.preventDefault()
                onClick()
            }}
            disabled={disabled}
            className={`link-button ${className}`}
        >
            <span>{label}</span>
        </Button>
    )
}

export const NavLink = ({ to, label, className }) => {
    return (
        <Link to={to} className={`link-button ${className}`}>
            <span>{label}</span>
        </Link>
    )
}

export const IconButton = ({ icon, className, onClick, ...props }) => {
    return <Button
        className={`link-button ${className}`}
        onClick={(e) => {
            e.preventDefault()
            onClick()
        }}
        color="link"
        {...props}
    >
        {icon}
    </Button>
}

export const LoadingButton = ({ label, disabled, onClick, className = "", icon, style, loading = false }) => {
    return (
        <Button
            onClick={(e) => {
                e.preventDefault()
                onClick()
            }}
            style={style}
            disabled={disabled}
            className={`button ${className}`}
        >
            {loading ? <Spinner style={{ height: "1rem", width: "1rem", marginRight: 8 }} /> : <></>}
            <span>{label}</span>
        </Button>
    )
}