import React from "react";
import Modal from "react-modal";
import { ModalBody } from "reactstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

export const RouteModal = ({ expanded, children }) => {
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const closeModal = () => {
    // if (compareValues) {
    //   Swal.fire({
    //     title: "Deseja sair sem salvar?",
    //     showCancelButton: true,
    //     showConfirmButton: false,
    //     showDenyButton: true,
    //     icon: "warning",
    //     text: "Ao sair, seus dados seram descartados.",
    //     denyButtonText: `Descartar`,
    //     cancelButtonText: "Cancelar",
    //   }).then((result) => {
    //     if (result.isDenied) {
    //       history.goBack();
    //     }
    //   });
    // } else {
    history.goBack();
    // }
  };

  return (
    <Modal
      onRequestClose={closeModal}
      className={`media-credit-modal ${expanded ? "expanded" : ""}`}
      isOpen={true}
    >
      <ModalBody className="modal-body">{children}</ModalBody>
    </Modal>
  );
};
