


const ColumnMobileUser = ({  name,email , onClick }) => {

    return (
        <div className="line-extrato" onClick={onClick} >
            <div className="main-info">
                <div className="info-left">
                    <div className="info-dentro">
                        <div className="name">
                            {name}
                        </div>
                        <div>
                          {email}
                        </div>
                    </div>
                </div>   
            </div>

        </div>
    );
};


export default ColumnMobileUser;