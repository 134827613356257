import React from "react";
import { CardHeader } from "reactstrap";
import { IoExitOutline } from "react-icons/io5";

const HeaderScreen = ({ route, onLogout, history }) => {
  return (
    <CardHeader className="card-header">
      <div style={{ flex: 1 }}>
        {route?.titleComponent ? route.titleComponent() : route && route.title ? (
          <h6 className="title">{route.title}</h6>
        ) : (
          <div />
        )}
      </div>
      <div className="flex-header">
        {route && route.action ? route.action(history) : <div />}
        <div className="perfil-logout" onClick={onLogout}>
          <IoExitOutline size={20} style={{ color: "black" }} />
        </div>
        {/* <UncontrolledDropdown className="btn-rotate">
              <DropdownToggle
                  aria-haspopup={true}
                  caret={false}
                  color="#000"
                  data-toggle="dropdown"
                  nav
              >
                  <div className='perfil main' >
                      <ReactImageFallback
                          src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${selected.logo}`}
                          fallbackImage={require("../../assets/images/logo.svg").default}
                          initialImage={require("../../assets/images/dark_loader.gif").default}
                          alt=""
                          className='perfil-logo' />
                      <h6 className='perfil-text'>{selected.name}</h6>
                      <BsChevronDown className='perfil-icon' />
                  </div>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-header" aria-labelledby="navbarDropdownMenuLink" right>
                  <DropdownItem
                      onClick={onLogout}
                      className={'drop-logout'}
                  >
                      <div className='perfil-logout' >
                          <IoExitOutline size={30} style={{color:"white"}}/>
                          <h6 className='perfil-text'>Sair</h6>
                      </div>
                  </DropdownItem>
              </DropdownMenu>
          </UncontrolledDropdown> */}
      </div>
    </CardHeader>
  );
};

export default HeaderScreen;
