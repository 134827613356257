import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { alertError, alertSuccess } from "../../components/Toast";
import { sendEmail } from "../../core/email";
import {
  sendAdsFinish,
  sendAdsReceived,
  sendAdsReproved,
  sendAdsTicket,
  sendAsProcess,
  sendPackageFinish,
  sendPackageProcess,
  sendPackageReproved,
} from "../../core/email/ads";
import { setData } from "../../core/redux/app/Actions";
import { compareValues } from "../../core/utils/compareValues";
import { saveTransaction } from "../../core/utils/transaction";
import { saveUser } from "../../core/utils/user";
import ConsumptionDetailsScreen from "../../screens/ConsumptionDetailsScreen";
import { supabase } from "../../supabaseClient";

const ConsumptionDetailsContainer = ({ id }) => {
  const isNew = id === "0";
  const dispatch = useDispatch();
  const history = useHistory();
  const [values, setValues] = useState({});
  const [users, setUsers] = useState([]);
  const [oldValues, setOldValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [saveing, setSaveing] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const loggedUser = useSelector((state) => state.app.user);
  const isChange = compareValues(values, oldValues);

  const reload = useSelector(
    (state) => state.app.reload_login_consuption || false
  );
  const [tickets, setTickets] = useState({});

  useEffect(() => {
    loadData();
  }, [id]);

  useEffect(() => {
    loadUsers();
  }, []);

  // useEffect(() => {
  //   loadTicketsTransactions()
  // }, []);

  const loadData = async () => {
    const { data } = await supabase
      .from("Transaction")
      .select()
      .eq("id", id)
      .is("deleted_at", null);

    setValues(isNew ? {} : formatData(data[0]));
    setOldValues(isNew ? {} : formatData(data[0]));
    setLoading(false);
  };

  const loadTicketsTransactions = async () => {
    const { data: ticket } = await supabase.storage
      .from(`ticketPlan`)

      .createSignedUrl(`${users.id}/${field}`, 3600);
    // console.log(ticket)
    // setTickets(ticket, "oi" )

    if (ticket) {
      window.open(ticket.signedURL, "_blank");
    }
  };

  const loadUsers = async () => {
    const { data, error } = await supabase
      .from("user")
      .select()
      .eq("type", 1)
      .is("deleted_at", null);

    setUsers(data);
  };

  const onChange = (filed, value) => {
    setValues({ ...values, [filed]: value });
  };

  const onSave = async () => {
    setSaveing(true);
    let reproved_at = null;
    try {
      if (!values.name) {
        throw new Error("Selecione um nome!");
      }

      if (!values.date) {
        throw new Error("Selecione uma data!");
      }

      if (!values.value) {
        throw new Error("Selecione um valor!");
      }

      if (values.status === 4) {
        reproved_at = new Date();
      }
      setLoadingSave(true);
      if (values.package) {
        await saveTransaction(values.id, {
          ...values,
          value: values.status === 4 ? 0 : parseInt(values.value),
          reproved_at: reproved_at,
        });

        // const userSelect = users.find((item) => item.id === values.id_user);
        // if (values.status === 2) {
        //   sendEmail(
        //     userSelect.email,
        //     "Boleto enviado com sucesso",
        //     sendPackageProcess(userSelect.name)
        //   );
        // } else if (values.status === 4) {
        //   sendEmail(
        //     userSelect.email,
        //     "Boleto reprovado",
        //     sendPackageReproved(userSelect.name)
        //   );
        // } else if (values.status === 1) {
        //   sendEmail(
        //     userSelect.email,
        //     "Boleto processado",
        //     sendAsProcess(userSelect.name)
        //   );
        // } else if (values.status === 3) {
        //   sendEmail(
        //     userSelect.email,
        //     "Pacote recebido",
        //     sendPackageFinish(userSelect.name)
        //   );
        // }
      } else if (values.consumption) {
        await saveTransaction(values.id, {
          ...values,
          value: values.status === 4 ? 0 : parseInt(values.value),
          reproved_at: reproved_at,
        });

        const userSelect = users.find((item) => item.id === values.id_user);
        if (values.status === 4) {
          sendEmail(
            userSelect.email,
            "Boleto reprovado para consumo de limite",
            sendAdsReproved(userSelect.name)
          );
        } else if (values.status === 5) {
          sendEmail(
            userSelect.email,
            "Assine a CCB para consumo de limite",
            sendAdsReceived(userSelect.name)
          );
        } else if (values.status === 1) {
          sendEmail(
            userSelect.email,
            "Boleto pago em processamento",
            sendAsProcess(userSelect.name)
          );
        } else if (values.status === 3) {
          sendEmail(
            userSelect.email,
            "Crédito Ads recebido",
            sendAdsFinish(userSelect.name)
          );
        }
      } else {
        await saveTransaction(values.id, {
          ...values,
          value: parseInt(values.value),
          reproved_at: reproved_at,
        });

        const userSelect = users.find((item) => item.id === values.id_user);
        if (values.status === 4) {
          sendEmail(
            userSelect.email,
            "Boleto reprovado para consumo de limite",
            sendAdsReproved(userSelect.name)
          );
        } else if (values.status === 5) {
          sendEmail(
            userSelect.email,
            "Assine a CCB para consumo de limite",
            sendAdsReceived(userSelect.name)
          );
        } else if (values.status === 1) {
          sendEmail(
            userSelect.email,
            "Boleto pago em processamento",
            sendAsProcess(userSelect.name)
          );
        } else if (values.status === 3) {
          sendEmail(
            userSelect.email,
            "Crédito Ads recebido",
            sendAdsFinish(userSelect.name)
          );
        }
      }

      if (values.status === 3 && !values.consumption) {
        const userTransaction = users.find(
          (item) => item.id === values?.id_user
        );

        await saveUser(values?.id_user, {
          package_limit:
            parseInt(userTransaction?.package_limit || 0) +
            parseInt(values?.value),
        });
      }
      setLoadingSave(false);
      dispatch(setData({ reload_login_consuption: !reload }));
      history.push("/installment");
      alertSuccess("Crédito cadastrado com sucesso!");
    } catch (e) {
      alertError(e.message);
    }
    setSaveing(false);
  };

  const closeModal = () => {
    if (isChange) {
      Swal.fire({
        title: "Deseja sair sem salvar?",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: "warning",
        text: "Ao sair, seus dados seram descartados.",
        denyButtonText: `Descartar`,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isDenied) {
          history.push("/installment");
        }
      });
    } else {
      history.push("/installment");
    }
  };

  return (
    <ConsumptionDetailsScreen
      history={history}
      values={values}
      onSave={onSave}
      isChange={isChange}
      onChange={onChange}
      type={loggedUser.type}
      users={users?.map((item) => ({ label: item.name, value: item.id }))}
      loading={loading}
      closeModal={closeModal}
      tickets={tickets}
      loadTicketsTransactions={loadTicketsTransactions}
      loadingSave={loadingSave}
      saveing={saveing}
    />
  );
};

export default ConsumptionDetailsContainer;

const formatData = (data) => {
  return {
    ...data,
    value: data?.value?.toString(),
  };
};
