import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import DetailsPlan from "../../components/DetailsPlan";
import DetailsRegister from "../../components/DetailsRegister";
import DocumentsDetails from "../../components/DocumentsDetails";
import { isMobile } from "react-device-detect";

const DocumentsScreen = ({ refusedPreApproved, values, user, tabSelect, tab, findDocuments, approveDocument, onChange, onSave, setReload, sendChangeDocumentEmail, approveContract, onSignature, loading, docs, savePackage, invoices, limit, changeTicket, sendEmailFiles }) => {

  return (
    <>
      <div className="documents-container">
        <Tabs onSelect={tabSelect} selectedIndex={tab}>
          <TabList>
            {tabs.map((item) => (
              <Tab className="tabs">{item.title}</Tab>
            ))}
          </TabList>
          <TabPanel className="tab-panel">
            <div className="details-plan-container">
              <DetailsRegister
                user={user}
                values={values}
                onSave={onSave}
                approveContract={approveContract}
                approveDocument={approveDocument}
                onSignature={onSignature}
                onChange={onChange}
                loading={loading}
                changeTicket={changeTicket}
                docs={docs}
                refusedPreApproved={refusedPreApproved}
                sendEmailFiles={sendEmailFiles}
              />
              <DetailsPlan
                values={values}
                onChange={onChange}
                onSave={onSave}
                loading={loading}
                user={user}
                savePackage={savePackage}
                invoices={invoices}
                limit={limit}
              />
            </div>
            
          </TabPanel>
          <TabPanel className="tab-panel">
            <DocumentsDetails
              user={user}
              values={values}
              onChange={onChange}
              setReload={setReload}
              findDocuments={findDocuments}
              sendChangeDocumentEmail={sendChangeDocumentEmail}
              loading={loading}
            />
          </TabPanel>
          {/* <TabPanel className="tab-panel">
            <DetailsPlan
              values={values}
              onChange={onChange}
              onSave={onSave}
              loading={loading}
              user={user}
              savePackage={savePackage}
              invoices={invoices}
              limit={limit}
            />
          </TabPanel> */}
        </Tabs>
      </div>
    </>
  );
};

export default DocumentsScreen;

const tabs = [
  { id: 0, title: isMobile ? "Cadastro" : "Dados de cadastro" },
  { id: 1, title: isMobile ? "Documentos" : "Documentos da empresa" },
  // { id: 1, title: isMobile ? "Detalhes" : "Detalhes do Plano" },
];
