import React from 'react';

export const compareValues = (obj1, obj2) => {
    const keyObj1 = Object.keys(obj1);
    const keyObj2 = Object.keys(obj2);

    if (keyObj1.length !== keyObj2.length) {
        return true;
    }

    for (let key of keyObj1) {

        if (obj1[key] !== obj2[key]) {
            return true;
        }
    }
    return false;
}