import moment from "moment";
import React from "react";
import { MdClose } from "react-icons/md";
import { LoadingButton } from "../../components/Button/Button";
import {
  DateInput,
  LabelInput,
  LabelSelect,
  MoneyInput,
} from "../../components/Input/Input";
import { LoadingComponent } from "../../components/LoadingComponent";

const CashbackDetailsScreen = ({
  users,
  onChange,
  values,
  isChange,
  onSave,
  type,
  loading,
  closeModal,
  loadingSave,
  saveing,
}) => (
  <div className="modal-consumption-container">
    <div className="modal-header">
      <div className="header-title">Cashback</div>
      <div>
        {isChange ? (
          <LoadingButton
            className={`btn-save-details`}
            label={"Salvar"}
            onClick={onSave}
            loading={loadingSave}
            disabled={saveing}
          />
        ) : (
          <></>
        )}
        <MdClose size={20} onClick={closeModal} style={{ cursor: "pointer" }} />
      </div>
    </div>
    <div className="modal-consumption-body">
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {type === 0 ? (
            <LabelSelect
              className={"documents-select"}
              placeholder={"Usuário"}
              options={users}
              onChange={(item) => onChange("id_user", item.value)}
              value={users?.find((item) => item.value === values.id_user)}
              disabled={loadingSave}
            />
          ) : (
            <></>
          )}
          <LabelInput
            className={"documents-input"}
            placeholder={"Nome"}
            value={values?.name}
            onChange={(value) => onChange("name", value)}
            disabled={type === 1 || loadingSave}
          />
          <MoneyInput
            className={"documents-input"}
            placeholder={"Valor"}
            value={values?.value}
            onChange={(value) => onChange("value", value)}
            disabled={type === 1 || loadingSave}
          />
          <DateInput
            className={"documents-input"}
            placeholder={"Vence em"}
            value={values?.wins_in ? moment(values.wins_in).toDate() : null}
            onChange={(value) => onChange("wins_in", value)}
            disabled={type === 1 || loadingSave}
          />
          {type === 0 ? (
            <LabelSelect
              menuPlacement={"top"}
              className="documents-select"
              placeholder={"Status"}
              options={options}
              value={options?.find((item) => item?.value === values?.status)}
              onChange={(item) => onChange("status", item.value)}
              disabled={type === 1 || loadingSave}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  </div>
);

export default CashbackDetailsScreen;

const options = [
  { value: 1, label: "Resgate solicitado" },
  { value: 2, label: "Resgatado" },
  { value: 3, label: "Disponível" },
  { value: 4, label: "Expirado" },
];
