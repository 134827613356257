import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { alertError, alertSuccess } from "../../components/Toast";
import { sendEmail } from "../../core/email";
import { sendAdsTicket } from "../../core/email/ads";
import { setData } from "../../core/redux/app/Actions";
import { compareValues } from "../../core/utils/compareValues";
import { saveTransaction } from "../../core/utils/transaction";
import HomeTicketScreen from "../../screens/HomeTicketScreen";
import { optionsPlans } from "../../screens/RegisterScreen/RegisterScreen";

const HomeTicket = () => {
  const dispatch = useDispatch()
  const history = useHistory();
  const loggedUser = useSelector((state) => state.app.user);
  const [values, setValues] = useState({});
  const [loadingSave, setLoadingSave] = useState(false);
  const [oldValues, setOldValues] = useState({});
  const isChange = compareValues(values, oldValues);
  const reloadHome = useSelector((state) => state.app.reloadHome || false);
  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  const onSave = async () => {
    try {
      if (!values.value) {
        throw new Error("Selecione um valor para continuar!");
      }

      if (!values.date) {
        throw new Error("Selecione a data para continuar!");
      }

      if (!values.ticket) {
        throw new Error("Boleto precisa ser adicionado para continuar!");
      }

      const plan = optionsPlans?.find(
        (item) => item?.value === loggedUser?.plan
      );
      setLoadingSave(true)
      await saveTransaction(undefined, {
        ...values,
        id_user: loggedUser.id,
        name: plan?.label || "Plano Ads+ 30",
        type: plan?.value || 0,
        complete: true,
        created_at: new Date(),
        pay: false,
        status: 2,
        consumption: true,
      });
      sendEmail(loggedUser.email, "Boleto", sendAdsTicket(loggedUser.name))
      history.push("/home");
      dispatch(setData({ reloadHome: !reloadHome }));
      setLoadingSave(false)
      alertSuccess("Crédito cadastrado com sucesso!");
    } catch (e) {
      alertError(e.message);
    }
  };

  const closeModal = () => {
    if (isChange) {
      Swal.fire({
        title: "Deseja sair sem salvar?",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: "warning",
        text: "Ao sair, seus dados seram descartados.",
        denyButtonText: `Descartar`,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isDenied) {
          history.push("/home");
        }
      });
    } else {
      history.push("/home");
    }
  };

  return (
    <HomeTicketScreen
      history={history}
      values={values}
      onChange={onChange}
      onSave={onSave}
      isChange={isChange}
      closeModal={closeModal}
      loadingSave={loadingSave}
    />
  );
};

export default HomeTicket;
