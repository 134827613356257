import React, { useEffect, useState } from "react";
import { BsClipboard, BsHouseDoor, BsCreditCard } from "react-icons/bs";
import LoginContainer from "../../containers/Login/LoginContainer";
import RecoveryContainer from "../../containers/Recovery/RecoveryContainer";
import NewPasswordContainer from "../../containers/NewPassword/NewPasswordContainer";
import { RiCoinsLine, RiLockPasswordLine } from "react-icons/ri";
import ChangePasswordContainer from "../../containers/ChangePassword/ChangePasswordContainer";
import { LuUser } from "react-icons/lu";
import { TbChartPie } from "react-icons/tb";
import RegisterContainer from "../../containers/RegisterContainer";
import UserContainer from "../../containers/UserContainer/UserContainer";
import HomeContainer from "../../containers/HomeContainer/HomeContainer";
import DocumentsContainer from "../../containers/DocumentsContainer/DocumentsContainer";
import ConsumptionExtractContainer from "../../containers/ConsumptionExtractContainer/ConsumptionExtractContainer";
import DetailsContainer from "../../containers/DetailsContainer";
import PackagesContainer from "../../containers/PackagesContainer";
import { HomeNavContainer } from "../../containers/HomeContainer/HomeNavContainer";
import VerifyHomeDetailsContainer from "../../containers/VerifyHomeDetailsContainer";
import PaynamentHeaderContainer from "../../containers/PaynamentHeaderContainer";
import PaynamentDetailsContainer from "../../containers/PaynamentsDetailsContainer";
import { HeaderRoutes } from "../../components/HeaderRoutes";
import { BiChart } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import jwt from 'jwt-simple';

const SECRET_KEY = "media-credit-app_1";

export const generateToken = () => {
  const expirationTime = Math.floor(Date.now() / 1000) + 30 * 60;

  // Crie o payload do token
  const payload = {
    exp: expirationTime,
    iat: Math.floor(Date.now() / 1000),
  };

  // Gere o token
  const token = jwt.encode(payload, SECRET_KEY);

  return token;
}

const IframeRoute = () => {
  const [token, setToken] = useState();
  const user = useSelector((state) => state.app.user);

  useEffect(() => {
    setToken(generateToken());
  }, []);

  if(!user.google_codes && !user.facebook_codes) {
    return (
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 80px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Finalize sua configuração de Meta e Google Ads para ter acesso ao dashboard
      </div>
    );
  }

  if (!token) {
    return (
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 80px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const googleCodes = (user.google_codes || "").split(",").join("&google_codes=")
  const facebookCodes = (user.facebook_codes || "").split(",").join("&facebook_codes=")

  return (
    <iframe
      src={`https://dashboard-zclo.onrender.com/?token=${token}${data.google_token ? `&google_token=${user.google_token}`: ''}${user.meta_token ? `&meta_token=${data.meta_token}`: ''}${googleCodes ? `&google_codes=${googleCodes}` : ''}${facebookCodes ? `&facebook_codes=${facebookCodes}` : ''}`}
      style={{ width: "100%", height: "calc(100vh - 80px)" }}
    />
  );
};

const RedirectRoute = () => {
  useEffect(() => {
    window.open("https://dashboard-zclo.onrender.com", "_self");
  }, []);
  return <div></div>;
};

export const openRoutes = [
  {
    path: "/login",
    Component: LoginContainer,
  },
  {
    path: "/recovery",
    title: "Acesso",
    icon: <BsClipboard className="small" />,
    Component: RecoveryContainer,
  },
  {
    path: "/reset-password",
    icon: <RiLockPasswordLine className="small" />,
    Component: NewPasswordContainer,
  },
  {
    path: "/register",
    Component: RegisterContainer,
  },
];

export const menuRoutes = (user) => {
  return [
    {
      path: "/home",
      title: "Início",
      icon: <BsHouseDoor className="small" />,
      mobile: true,
      Component: HomeContainer,
      titleComponent: HomeNavContainer,
      details: (props) => <VerifyHomeDetailsContainer {...props} />,
    },
    {
      path: "/installment",
      title: "Parcelamento",
      icon: <BsCreditCard className="small" />,
      mobile: true,
      Component: ConsumptionExtractContainer,
      titleComponent: () => <HeaderRoutes title={"Suas Movimentações"} />,
      details: (props) => <DetailsContainer {...props} />,
    },

    {
      path: user.type ? "/data" : "/data/:id",
      title: "Dados",
      icon: <TbChartPie className="small" />,
      mobile: true,
      Component: DocumentsContainer,
      titleComponent: () => <HeaderRoutes title={"Cadastro"} />,
    },
    !user.type && {
      path: "/users",
      title: "Clientes",
      icon: <LuUser className="small" />,
      mobile: true,
      Component: UserContainer,
    },
    {
      path: "/paynament",
      title: "Créditos",
      icon: <RiCoinsLine className="small" />,
      mobile: true,
      Component: PackagesContainer,
      titleComponent: PaynamentHeaderContainer,
      details: (props) => <PaynamentDetailsContainer {...props} />,
    },
    // {
    //   path: "/redirect",
    //   title: "Dashboard",
    //   icon: <BiChart className="small" />,
    //   mobile: true,
    //   Component: RedirectRoute,
    //   titleComponent: PaynamentHeaderContainer,
    //   details: (props) => <PaynamentDetailsContainer {...props} />,

    // },
    {
      path: "/dashboard",
      title: "Dashboard",
      icon: <BiChart className="small" />,
      mobile: true,
      Component: IframeRoute,
      titleComponent: () => <PaynamentHeaderContainer title="Dashboard" />,
      details: (props) => <PaynamentDetailsContainer {...props} />,
    },
    {
      path: "/reset-password",
      icon: <RiLockPasswordLine className="small" />,
      Component: NewPasswordContainer,
      titleComponent: () => <HeaderRoutes title={"Cadastrar nova senha"} />,
      hidden: true,
    },
  ].filter((item) => item);
};

export const firstRoute = [
  {
    path: "/change-password",
    hidden: true,
    Component: ChangePasswordContainer,
  },
];
