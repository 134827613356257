import React from "react";
import { Item } from "../FormsItem";
import "react-tabs/style/react-tabs.css";
import { LabelSelect, MoneyInput } from "../Input/Input";
import { LoadingComponent } from "../../components/LoadingComponent";
import { convertMoney } from "../HomeTablesProgress";
import { IconButton } from "../Button/Button";
import { IoMdCheckmark } from "react-icons/io";
import { NotPackageCompontent } from "../NotResultComponent";
import moment from "moment";

const DetailsPlan = ({
  values,
  onChange,
  onSave,
  loading,
  user,
  savePackage,
}) => {
  return (
    <>
      <div className="div-details-plan-container">
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="sub-title">Dados de cadastro do seu plano</div>
            <div className="inputs">
              {details.map((item) => {
                const verifyOptions =
                  item.id === 7
                    ? optionsIvestiments
                    : item.id === 6
                    ? optionsTime
                    : item.id === 0
                    ? options
                    : item.id === 2
                    ? deadlinePayment
                    : optionsCahsbacks;

                const deadLine = values["deadLine"]
                  ? deadlinePayment.find(
                      (item) => item.value === values["deadLine"]
                    )
                  : deadlinePayment.find((item) => item.value === values.plan);

                const monthlyPayment = values["monthlyPayment"]
                  ? options.find(
                      (item) => item.value === values["monthlyPayment"]
                    )?.amount
                  : options.find((item) => item.value === values.plan)?.amount;

                const cashback = values["cashback"]
                  ? optionsCahsbacks.find(
                      (item) => item.value === values["cashback"]
                    )
                  : optionsCahsbacks.find((item) =>
                      values.plan === 0 ? item.value === 0 : item.value === 1
                    );

                if (item.type === "select") {
                  return (
                    <Item onClick={onSave} disableMember={user.type !== 0}>
                      {(disabled) => (
                        <LabelSelect
                          menuPlacement={"bottom"}
                          className="documents-select"
                          placeholder={item.label}
                          options={verifyOptions}
                          disabled={disabled}
                          onChange={(e) => onChange(item.field, e.value)}
                          value={
                            item.field === "deadLine"
                              ? deadLine
                              : item.field === "cashback"
                              ? cashback
                              : verifyOptions.find(
                                  (value) => value.value === values[item.field]
                                )
                          }
                        />
                      )}
                    </Item>
                  );
                } else if (item.type === "number") {
                  return (
                    <Item onClick={onSave} disableMember={user.type !== 0}>
                      {(disabled) => (
                        <MoneyInput
                          className={"documents-input"}
                          placeholder={item.label}
                          type={item.type}
                          disabled={disabled}
                          value={
                            item.field === "monthlyPayment"
                              ? monthlyPayment
                              : values[item.field]
                          }
                          onChange={(value) => onChange(item.field, value)}
                        />
                      )}
                    </Item>
                  );
                }
              })}
            </div>
          </>
        )}
      </div>
      {values?.packages?.length === 0 ? (
        <>
          <NotPackageCompontent />
        </>
      ) : (
        <div className="packages">
          {" "}
          <div className="details-title">Pacotes contratados</div>
          <div className="packages-container">
            {values?.packages?.map((item) => {
              return (
                <div className="packages-div">
                  <div className="package-title">
                    <text>{item.name}</text>
                    {moment(item.date).format("DD/MM/YY")}
                  </div>
                  <div className="div-checks">
                    <text>{convertMoney(item.value)}</text>
                    {/* {user.type === 0 && <>
                        <IconButton
                          icon={<IoMdCheckmark className="icon" color="green" style={{ cursor: item?.rescued ? "no-drop" : "pointer" }} />}
                          style={{ marginLeft: 10 }}
                          onClick={() => savePackage(item)}
                          disabled={item?.rescued}
                        />
                      </>} */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default DetailsPlan;

const options = [
  { value: 0, label: "Plano Ads+ 30", amount: "150", cashback: "0.5%" },
  { value: 1, label: "Plano Ads+ 45", amount: "450", cashback: "1%" },
  { value: 2, label: "Plano Ads+ 60", amount: "2000", cashback: "1%" },
  { value: 3, label: "Plano Ads+ 90", amount: "4800", cashback: "1%" },
];

const details = [
  { id: 0, label: "Plano", type: "select", field: "plan" },
  { id: 1, label: "Limite", type: "number", field: "limit" },
  { id: 2, label: "Prazo Para pagamento", type: "select", field: "deadLine" },
  { id: 5, label: "Cashback", type: "select", field: "cashback" },
  { id: 4, label: "Mensalidade", type: "number", field: "monthlyPayment" },
  {
    id: 6,
    label: "Há quanto tempo sua empresa reali anúncios pagos?",
    type: "select",
    field: "time",
  },
  {
    id: 7,
    label: "Qual é o investimento médio mensal em anúncios pagos?",
    type: "select",
    field: "invest",
  },
];

const deadlinePayment = [
  { value: 1, label: "30 Dias" },
  { value: 2, label: "45 Dias" },
  { value: 4, label: "60 Dias" },
  { value: 5, label: "90 Dias" },
];

const optionsCahsbacks = [
  { value: 1, label: "0%" },
  { value: 2, label: "0.5%" },
  { value: 3, label: "1%" },
];

export const optionsTime = [
  { value: 0, label: "Nunca anunciei" },
  { value: 1, label: "Menos de 6 meses" },
  { value: 2, label: "De 6 meses a 1 ano" },
  { value: 3, label: "Mais de 1 ano" },
];

export const optionsIvestiments = [
  { value: 0, label: "Nunca investi" },
  { value: 1, label: "Menos de R$ 1.000,00 por mês" },
  { value: 2, label: "De R$ 1.000,00 a R$ 5.000,00 por mês" },
  { value: 3, label: "De R$ 5.000,00 a R$ 20.000,00 por mês" },
  { value: 4, label: "De R$ 20.000,00 a R$ 50.000,00 por mês" },
  { value: 5, label: "Acima de R$ 50.000,00 por mês" },
];
