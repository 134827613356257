import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { alertError, alertSuccess } from "../../components/Toast";
import { getTranslation } from "../../core/Translator";
import NewPasswordScreen from "../../screens/NewPassword/NewPasswordScreen";
import { supabase } from "../../supabaseClient";

let auxSession = null;
const NewPasswordContainer = ({}) => {
  let history = useHistory();
  const {
    location: { hash, key },
  } = history;
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    newPassword: "",
    repeatPassword: "",
  });

  useEffect(() => {
    supabase.auth.onAuthStateChange((_event, session) => {
      if (session && session.access_token) {
        auxSession = session;
      }
      if (_event == "PASSWORD_RECOVERY") {
        history.push("/reset-password");
        // supabase.auth.signOut();
      }
    });
  }, [auxSession]);

  useEffect(() => {
    if (hash.includes("401")) {
      history.push("/login");
    }
  }, [hash]);

  const onLogout = () => {
    history.push("/login");
  };

  const handleNewPassword = async () => {
    try {
      if (
        values.newPassword !== "" &&
        values.newPassword === values.repeatPassword
      ) {
        const { error } = await supabase.auth.api.updateUser(
          auxSession.access_token,
          { password: values.newPassword }
        );
        if (error) throw error;
        alertSuccess("Senha redefinida com sucesso!");
        history.push("/home");
      } else {
        alertError("Senhas diferentes");
      }
    } catch (error) {
      alertError(getTranslation(error.error_description || error.message));
    } finally {
    }
  };

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  return (
    <NewPasswordScreen
      handleNewPassword={handleNewPassword}
      onChange={onChange}
      values={values}
      loading={loading}
      history={history}
      onLogout={onLogout}
    />
  );
};

export default NewPasswordContainer;
