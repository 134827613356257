import React, { useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FaImages, FaTrashAlt } from "react-icons/fa";
import { supabase } from "../../supabaseClient";
import { alertError, alertSuccess } from "../Toast";
import { DefaultButton } from "../Button/Button";
import { LuCheck, LuFile } from "react-icons/lu";
import { IoMdClose } from "react-icons/io";
import { isMobile } from "react-device-detect";
import { IoDocuments } from "react-icons/io5";

export const SingleDropzone = ({ onChange = () => { }, value, table = "company", placeholder, filename, title, setReload, downloadDocument, aproved, recused }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    validator: typeValidator,
    maxFiles: 1,
  });

  const uploadImage = async (file) => {
    const { data, error } = await supabase.storage
      .from(table)
      .upload(filename, file, {
        upsert: true,
      });
    setReload((reload) => !reload);

    if (error) {
      alertError("Erro ao enviar Documento");
    } else {
      onChange(data.Key);
      alertSuccess("Documento adicionado com sucesso!")
    }
  };

  useEffect(() => {
    if (acceptedFiles && acceptedFiles[0]) {
      uploadImage(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  return (
    <section className="dropzone-container" style={{}}>
      <div className="dropzone">
        {value ? (
          <div className="allValueTrue">
            <div className="valueTrue">
              <p>{title}</p>
              {verificationAproved(aproved, recused)}
            </div>
          </div>
        ) : (
          <p>{placeholder ? placeholder : "Arraste e solte uma imagem aqui ou clique para selecionar."}</p>
        )}
      </div>
      <div className="row-btns-documents">
        {value ? <LuFile onClick={downloadDocument} /> : <></>}
        {!aproved && (
          <div className="div-buttonZone">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <DefaultButton
                className={value ? "buttonYelow" : "buttonRed"}
                label={value ? <p style={{ color: "white" }}>Alterar Documento</p> : isMobile ? <IoDocuments /> : <p style={{ color: "white" }}>Subir documento</p>}
                onClick={() => { }}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

const verificationAproved = (aproved, recused) => {
  if (aproved === null && recused === null) {
    return <p id="info">
      <LuCheck /> Documento Carregado
    </p>
  } else if (aproved && recused === null) {
    return <p id="info">
      <LuCheck /> Documento Aprovado
    </p>
  } else if (recused && aproved === null) {
    return <p id="info" style={{ color: "rgba(255, 0, 77, 1)" }}>
      <IoMdClose /> Documento Reprovado
    </p>
  }
}

export const MultipleDropzone = ({
  onChange = () => { },
  value = [],
  table = "company",
  placeholder = "Arraste e solte imagens aqui ou clique para selecionar.",
  filename,
  aproved,
  recused,
  downloadDocument,
  setReload,
  title
}) => {

  const onDrop = useCallback(
    (acceptedFiles) => {
      onChange({});
      uploadImage(acceptedFiles)
    },
    [value]
  );

  const uploadImage = async (files) => {

    const promises = [];
    for (let i = 0; i < files.length; i++) {
      promises.push(
        supabase.storage
          .from(table)
          .upload(value.length > 0 ? `${filename}_${gerenationId()}` : filename, files[i])
      );
    }

    let { data, error } = await Promise.all(promises).then((images) => {
      if (
        images.filter((item) => item && item.error).length === images.length
      ) {
        return { data: undefined, error: true };
      }
      return {
        data: images.map((item) => item && item.data && item.data.Key),
        error: undefined,
      };
    });

    if (error) {
      alertError("Erro ao enviar imagem");
    } else {
      onChange([...new Set(value)].concat(data));
      setReload((reload) => !reload);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    validator: typeValidator,
    onDrop,
  });

  const sendToFirst = (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    const newArray = value.filter((row) => row !== item);
    onChange([item].concat(newArray));
  };

  const removeImage = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    await supabase.
      storage
      .from("documents")
      .remove([`131/${item.name}`])
    setReload((reload) => !reload);
  };

  return (
    <section className="dropzone-container" style={{}}>
      <div className="dropzone">
        {value?.length > 0 ? (
          <div className="allValueTrue">
            <div className="valueTrue">
              <p>{title}</p>
              {verificationAproved(aproved, recused)}
            </div>
          </div>
        ) : (
          <p>{placeholder ? placeholder : "Arraste e solte uma imagem aqui ou clique para selecionar."}</p>
        )}
      </div>
      <div className="row-btns-documents">
        {value?.length > 0 ? value.map(item => <div className="icon-files">
          <LuFile className="icon-multiple-files" onClick={(e) => downloadDocument(e, item.name)} />
          {!aproved && <FaTrashAlt
            className="icon-multiple-delete"
            onClick={(e) => removeImage(e, item)}
          />}
        </div>
        ) : <></>}
        {!aproved && (
          <div className="div-buttonZone">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <DefaultButton
                className={/* value ? "buttonYelow" :  */"buttonRed"}
                label={/* value ? <p style={{ color: "white" }}>Alterar Documento</p> : */ isMobile ? <IoDocuments /> : <p style={{ color: "white" }}>Subir documento</p>}
                onClick={() => { }}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export const IconDropzone = ({
  onChange = () => { },
  value,
  table = "company",
  placeholder,
  filename,
  title,
  icon,
  sendEmailFiles
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    validator: typeValidator,
    maxFiles: 1,
  });

  const uploadImage = async (file) => {
    const { data, error } = await supabase.storage
      .from(table)
      .upload(filename, file, {
        upsert: true,
      });

    if (error) {
      alertError("Erro ao enviar Documento");
    } else {
      const splitLabel = table.split("/")[0]
      onChange(data.Key);
      alertSuccess("Documento adicionado com sucesso!")
      if (splitLabel === "signature" || splitLabel === "ticketPlan") {
        sendEmailFiles(splitLabel)

      }
    }
  };

  useEffect(() => {
    if (acceptedFiles && acceptedFiles[0]) {
      uploadImage(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  return (
    <div className="div-buttonZone">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {icon}
      </div>
    </div>
  );
};

function typeValidator(file) {
  if (!["application/pdf"].includes(file.type)) {
    return {
      code: "type-is-diferent",
      message: `Tipo de arquivo não suportado`,
    };
  }

  if (file.size > 25 * 1024 * 1024) {
    return {
      code: "file-size-too-large",
      message: `Tamanho do arquivo deve ser menor que 25 MB`,
    };
  }

  return null;
}

export const CustomDropzone = ({
  onChange = () => { },
  value,
  table = "company",
  placeholder,
  filename,
  disabled = false,
  withDataPlaceholder
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    validator: typeValidator,
    maxFiles: 1,
    disabled: disabled
  });

  const uploadImage = async (file) => {
    const { data, error } = await supabase.storage
      .from(table)
      .upload(filename, file, {
        upsert: true,
      });

    if (error) {
      alertError("Erro ao enviar Documento");
    } else {
      onChange(data.Key);
      alertSuccess("Documento adicionado com sucesso!")
    }
  };

  useEffect(() => {
    if (acceptedFiles && acceptedFiles[0]) {
      uploadImage(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  return (
    <section
      className="dropzone-container"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div {...getRootProps()} className="div-custom-drop">
        <input {...getInputProps()} />
        {value ? (
          <div style={{ flex: 1 }}>
            <span className="span-palceholder">{withDataPlaceholder ? withDataPlaceholder : "Boleto carregado"}</span>
          </div>
        ) : (
          <div style={{ flex: 1 }}>
            <span className="span-palceholder">
              {placeholder
                ? placeholder
                : "Arraste e solte uma imagem aqui ou clique para selecionar."}
            </span>
          </div>
        )}
      </div>
    </section>
  );
};


function gerenationId() {
  // Gera um número aleatório de 10 dígitos
  const id = Math.floor(Math.random() * 10000000000);
  return id;
}