import React from "react";
import { DefaultButton } from "../../components/Button/Button";
import { IoIosAddCircleOutline } from "react-icons/io";

const PackagesScreen = ({ history }) => {
    return (
        <div className="packages-container">
            <div className="div-card">
                <div className="div-package">
                    {plans.map(({ planName, amount, value3x, value6x, value12x, img, description1, description2, description3, value, id, }) =>
                        <div className="package">
                            {id === 0 && <div className="div-img"><img className="range" src={require("../../assets/images/mais-vendido.png").default} /></div>}
                            <div className="img">{img}</div>
                            <div className="amount">{amount}</div>
                            <div className="credits">CRÉDITOS DE MÍDIA</div>
                            <div className="plan-name">{planName}</div>
                            {id === 3 && <div className="div-icon">
                                <IoIosAddCircleOutline className="icon-custom" />
                            </div>}
                            {id !== 3 && <div className="description-container">
                                {id !== 1 && <div className="div-description">

                                    {id !== 3 && <div style={{ color: "#7cda24" }}>+</div>}
                                    <div className="value-description">{description1}</div>
                                </div>}
                                {id !== 1 && <div className="div-description">
                                    {id !== 3 && <div style={{ color: "#7cda24" }}>+</div>}
                                    <div className="value-description">{description2}</div>
                                </div>}
                                {id !== 0 && <div className="div-description">
                                    {id !== 3 && <div style={{ color: "#7cda24" }}>+</div>}
                                    <div className="value-description">{description3}</div>
                                </div>}
                            </div>}
                            <div className="pay">ESCOLHA COMO PAGAR</div>
                            <div className="value-one">{value}</div>
                            {id !== 3 && <div className="div-parcelamentos">
                                <div className="parcelamento">3x</div>
                                <div className="value">{value3x}</div>
                            </div>}
                            {id !== 3 && <div className="div-parcelamentos">
                                <div className="parcelamento">6x</div>
                                <div className="value">{value6x}</div>
                            </div>}
                            {id !== 3 && <div className="div-parcelamentos">
                                <div className="parcelamento">12x</div>
                                <div className="value">{value12x}</div>
                            </div>}
                            {id !== 3 && <div className="div-button">
                                <DefaultButton
                                    className={"default-button"}
                                    label={"Comprar"}
                                    onClick={() => history.push(`/paynament/details/${id}`, { pay: true })}
                                />
                            </div>}
                            {id === 3 && <div className="div-button-green">
                                <DefaultButton
                                    className={"default-button-green"}
                                    label={"Fale com um Especialista"}
                                    onClick={() => history.push(`/paynament/details/${id}`)}
                                />
                            </div>}
                        </div>
                    )}
                </div>
            </div>
        </div>

    );
};

export default PackagesScreen;

export const plans = [
    { id: 0, img: <img className="coin" src={require("../../assets/images/pack4e.png").default} />, amount: "+2.000", planName: "MÍDIA DE CONVERSÃO", value3x: "R$ 707,00", value6x: "R$ 373,00", value12x: "R$ 207,00", description1: "1.000 META ADS", description2: "5.000 GOOGLE ADS" },
    { id: 1, img: <img className="coin" src={require("../../assets/images/pack4d.png").default} />, amount: "+5.000", planName: "MÍDIA DE INFLUÊNCIA", value3x: "R$ 1.676,00", value6x: "R$ 933,00", value12x: "R$ 517,00", description3: "5.000 Influencer Ads" },
    { id: 2, img: <img className="coin" src={require("../../assets/images/pack4c.png").default} />, amount: "+15.000", planName: "MÍDIA DIGITAL", value3x: "R$ 5.300,00", value6x: "R$2.800,00", value12x: "R$ 1.550,00", description1: "5.000 META ADS", description2: "5.000 GOOGLE ADS", description3: "5.000 INFLUENCER ADS" },
    { id: 3, img: <img className="coin" src={require("../../assets/images/pack4x.png").default} />, amount: "+50.000", planName: "DO SEU JEITO", value: "EM ATÉ 24X" },
];