import React from "react";
import GridTable from "@nadavshaar/react-grid-table";
import { BsPencil, BsPieChart } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { Date2Input, DateInput, IconInput } from "../../components/Input/Input";
import { IoIosSearch } from "react-icons/io";
import { NotResultCompontent } from "../../components/NotResultComponent";
import moment from "moment";
import { isMobile } from "react-device-detect";
import ColumnMobileUser from "../../components/ColumnsMobileUser/ColumnsMobileUser";
import { MdOutlineCalendarMonth } from "react-icons/md";
import jwt from 'jwt-simple';

const SECRET_KEY = "media-credit-app_1";

const UserScreen = ({ data, setFilter, filter }) => {
  const history = useHistory();

  return (
    <div className="user-screen-container">
      <div className="div-filter-user">
        <Date2Input
          placeholder={"Data"}
          inputClassname="input-size"
          icon={<MdOutlineCalendarMonth className="search-Icon" />}
          style={{ color: "black" }}
          value={filter?.date}
          onChange={(value) =>
            setFilter((filters) => ({ ...filters, date: value }))
          }
        />
        <IconInput
          placeholder={"Pesquisar"}
          inputClassname="input-size"
          icon={<IoIosSearch className="search-Icon" />}
          style={{ color: "black" }}
          onChange={(value) =>
            setFilter((filters) => ({ ...filters, search: value }))
          }
        />
      </div>
      <div className="user-screen">
        <GridTable
          columns={isMobile ? columnsMobile(history) : columns(history)}
          rows={data}
          texts={{
            noResults: <NotResultCompontent />,
            search: "Buscar:",
            totalRows: "Número de itens:",
            rows: "Itens:",
            selected: "Selecionado",
            rowsPerPage: "Itens por página:",
            page: "Página:",
            of: "de",
            prev: "Anterior",
            next: "Próximo",
            columnVisibility: "Colunas visíveis",
          }}
          showSearch={false}
          showRowsInformation={false}
          showColumnVisibilityManager={false}
          isPaginated={false}
          enableColumnsReorder={false}
        />
      </div>
    </div>
  );
};

export default UserScreen;

const columnsMobile = (history) => [
  {
    id: 1,
    field: "name",
    label: "Clientes",
    width: "10fr",
    resizable: false,
    cellRenderer: ({ data }) => (
      <ColumnMobileUser
        onClick={() => history.push(`/data/${data.id}`)}
        name={data.name}
        email={data.email}
        phone={data.phone}
      />
    ),
  },
];

const columns = (history) => [
  {
    id: 1,
    field: "name",
    label: "Nome",
    width: "1fr",
    paddingLeft: "30px!important",
    resizable: false,
    cellRenderer: ({ data }) => {
      return <div>{data.name}</div>;
    },
  },
  {
    id: 2,
    field: "email",
    label: "Email",
    width: "1fr",
    paddingLeft: "30px!important",
    resizable: false,
    cellRenderer: ({ data }) => {
      return <div>{data.email}</div>;
    },
  },
  {
    id: 3,
    field: "phone",
    label: "Telefone",
    width: "1fr",
    paddingLeft: "30px!important",
    resizable: false,
    cellRenderer: ({ data }) => {
      return <div>{data.phone}</div>;
    },
  },
  {
    id: 4,
    field: "date",
    label: "Data",
    width: "1fr",
    paddingLeft: "30px!important",
    resizable: false,
    cellRenderer: ({ data }) => {
      return <div>{moment(data.created_at).format("DD/MM/YYYY")}</div>;
    },
  },
  {
    id: 5,
    field: "actions",
    label: "Ações",
    resizable: false,
    width: "100px",
    cellRenderer: ({ data }) => <ActionCell data={data} history={history} />,
  },
];

const ActionCell = ({ data, history }) => {
  return (
    <div className="field-actions">
      {(data.google_codes || data.facebook_codes) && <BsPieChart
        className="icons-action"
        style={{ cursor: "pointer", marginRight: 15 }}
        onClick={() => {
          const expirationTime = Math.floor(Date.now() / 1000) + 30 * 60;

          // Crie o payload do token
          const payload = {
            exp: expirationTime,
            iat: Math.floor(Date.now() / 1000),
          };
      
          // Gere o token
          const token = jwt.encode(payload, SECRET_KEY);

          const googleCodes = (data.google_codes || "").split(",").join("&google_codes=")
          const facebookCodes = (data.facebook_codes || "").split(",").join("&facebook_codes=")

          const metaToken = data.meta_token ? `&meta_token=${data.meta_token}`: ''
          const googleToken = data.google_token ? `&google_token=${data.google_token}`: ''

          const url = `https://dashboard-zclo.onrender.com/?token=${token}${metaToken}${googleToken}${googleCodes ? `&google_codes=${googleCodes}` : ''}${facebookCodes ? `&facebook_codes=${facebookCodes}` : ''}`

          window.open( url, "_blank")

        }}
      />}
      <BsPencil
        className="icons-action"
        style={{ cursor: "pointer" }}
        onClick={() => history.push(`/data/${data.id}`)}
      />
    </div>
  );
};
