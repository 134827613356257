import { useState } from "react";

export const useSidebarControls = () => {
    const [collapsed, setCollap] = useState(true)

    const setCollapsed = () => {
        setCollap(collapsed => !collapsed)
    }

    const expand = () => {
        setCollap(false)
    }

    const collapse = () => {
        setCollap(true)
    }

    return {
        collapsed, setCollapsed, expand, collapse
    }
}





