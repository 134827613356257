import React from "react";
import { MdClose } from "react-icons/md";
import {
  DateInput,
  LabelInput,
  LabelSelect,
  MoneyInput,
} from "../../components/Input/Input";
import { IconDropzone } from "../../components/Dropzone/Dropzone";
import { LoadingButton } from "../../components/Button/Button";
import moment from "moment";
import { convertMoney } from "../../components/HomeTablesProgress";
import { LoadingComponent } from "../../components/LoadingComponent";
import { LuFile } from "react-icons/lu";

const TicketDetailsScreen = ({
  values,
  user,
  openDocument,
  type,
  isChange,
  onSave,
  onChange,
  users,
  transactions,
  loading,
  closeModal,
  loadingSave,
  saveing,
}) => (
  <div className="modal-ticket-container">
    <div className="modal-header">
      <div className="header-title">
        <text>Fatura</text>
        <div>
          <div className="file-container-item">
            <div className="div-icon">
              {user.type === 0 ? (
                <IconDropzone
                  table={`tickets/${values?.id}`}
                  filename="ticket"
                  type={user.type}
                  icon={
                    <LuFile
                      style={{ marginLeft: 10, fontSize: 30 }}
                      className="icons-action"
                    />
                  }
                />
              ) : values.ticket ? (
                <LuFile
                  style={{ marginLeft: 10, fontSize: 30 }}
                  className="icons-action"
                  onClick={(e) => (user.type === 1 ? openDocument(e) : {})}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        {isChange ? (
          <LoadingButton
            className={`btn-save-details`}
            label={"Salvar"}
            onClick={onSave}
            loading={loadingSave}
            disabled={saveing}
          />
        ) : (
          <></>
        )}
        <MdClose size={20} onClick={closeModal} style={{ cursor: "pointer" }} />
      </div>
    </div>
    <div className="modal-consumption-body">
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {type === 0 ? (
            <LabelSelect
              className={"documents-select"}
              placeholder={"Usuário"}
              options={users}
              onChange={(item) => onChange("id_user", item.value)}
              value={users?.find((item) => item.value === values.id_user)}
              disabled={loadingSave}
            />
          ) : (
            <></>
          )}
          <LabelInput
            className={"documents-input"}
            placeholder={"Nome"}
            onChange={(value) => onChange("name", value)}
            value={values?.name}
            disabled={type === 1 || loadingSave}
          />
          {(values?.values || type === 0) && (
            <MoneyInput
              className={"documents-input"}
              placeholder={"Valor"}
              onChange={(value) => onChange("value", value)}
              value={values?.value}
              disabled={type === 1 || loadingSave}
            />
          )}
          {type === 0 ? (
            <LabelSelect
              className="documents-select"
              placeholder={"Status"}
              options={options}
              onChange={(item) => onChange("status", item.value)}
              disabled={type === 1 || loadingSave}
              value={options.find((item) => item.value === values?.status)}
            />
          ) : (
            <></>
          )}
          <div className="row-dates">
            <DateInput
              className={"documents-input"}
              placeholder={"Fecha em"}
              onChange={(value) => onChange("closed_in", value)}
              disabled={type === 1 || loadingSave}
              value={
                values && values.closed_in
                  ? moment(values.closed_in).toDate()
                  : null
              }
            />
            <DateInput
              className={"documents-input"}
              placeholder={"Vence em"}
              onChange={(value) => onChange("expired_in", value)}
              disabled={type === 1 || loadingSave}
              value={
                values && values.expired_in
                  ? moment(values.expired_in).toDate()
                  : null
              }
            />
          </div>

          {transactions && transactions.length > 0 ? (
            <div className="div-table">
              <div>
                <text className="title-extract">Extrato de consumo ads</text>
                {transactions
                  .filter((item) => item.status === 3 && item.consumption)
                  .map((item) => (
                    <div className="list-transactions-div">
                      <text>{moment(item.date).format("DD/MM/YYYY")}</text>
                      <text>{item.name}</text>
                      <text>{convertMoney(item.value || 0)}</text>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  </div>
);

export default TicketDetailsScreen;

const options = [
  { value: 1, label: "Pago" },
  { value: 2, label: "Vencido" },
  { value: 3, label: "Aguardando pagamento" },
  { value: 4, label: "Fechado" },
];
