import { MdClose } from "react-icons/md"
import { DateInput, TextInput } from "../../components/Input/Input"
import moment from "moment";
import { LoadingComponent } from "../../components/LoadingComponent";

export const DisapprovedCreditScreen = ({ values, history, loading }) => (
  <div className="modal-consumption-container">
    <div className="modal-header">
      <div className="header-title">Credito Reprovado</div>
      <div>
        <MdClose
          size={20}
          onClick={() => history.push("/installment")}
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
    <div className="modal-consumption-body">
      {loading ? <LoadingComponent /> : <>
        <DateInput
          className={"documents-input"}
          placeholder={"Data de reprovação"}
          value={values && values.reproved_at ? moment(values.reproved_at).toDate() : null}
          disabled={true}
        />
        {values?.details && <TextInput
          className={"documents-input"}
          placeholder={"Detalhes"}
          onChange={(event) => onChange("details", event.target.value)}
          value={values?.details}
          area={"area"}
          disabled={true}
        />}
      </>}
    </div>
  </div>
);