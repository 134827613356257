import React, { useEffect, useState } from "react";
import DocumentsScreen from "../../screens/DocumentsScreen/DocumentsScreen";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { supabase } from "../../supabaseClient";
import { alertError, alertSuccess } from "../../components/Toast";
import moment from "moment";
import { saveUser } from "../../core/utils/user";
import { saveTransaction } from "../../core/utils/transaction";
import { editInvoice, saveInvoice } from "../../core/utils/invoice";
import { optionsPlans } from "../../screens/RegisterScreen/RegisterScreen";
import { sendEmail } from "../../core/email";
import { sendInvoices } from "../../core/email/sendInvoice";
import {
  aprovedRegister,
  refusedApproved,
  releaseLimitInProgress,
  toApproveDocuments,
} from "../../core/email/aprovedRegister";

const DocumentsContainer = (props) => {
  const [tab, setTab] = useState(0);
  const user = useSelector((state) => state.app.user);
  const [values, setValues] = useState({});
  const [documents, setDocuments] = useState([]);
  const [docs, setDocs] = useState([]);
  const { id } = useParams();
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state } = props.location;

  useEffect(() => {
    if (state && state.tab) {
      setTab(state.tab);
    }
  }, [state && state.tab]);

  useEffect(() => {
    if (tab === 1) {
      loadDocuments();
    }
  }, [tab, reload]);

  useEffect(() => {
    if (tab === 0) {
      loadSingature();
    }
  }, [tab, reload]);

  useEffect(() => {
    loadData();
  }, [tab, reload]);

  const loadDocuments = async () => {
    const newId = id === ":id" ? user.id : id ? id : user.id;
    const { data, error } = await supabase.storage
      .from(`documents`)
      .list(`${newId}`);
    setDocuments(data);
  };

  const loadSingature = async () => {
    const newId = id === ":id" ? user.id : id ? id : user.id;
    const { data, error } = await supabase.storage
      .from(`signature`)
      .list(`${newId}`);

    const { data: ticket } = await supabase.storage
      .from(`ticketPlan`)
      .list(`${newId}`);

    setDocs([...data, ...ticket]);
  };

  const loadData = async () => {
    setLoading(true);
    const newId = id === ":id" ? user.id : id ? id : user.id;
    const { data, error } = await supabase
      .from("user")
      .select()
      .eq("id", newId)
      .is("deleted_at", null);

    const { data: transaction } = await supabase
      .from("Transaction")
      .select()
      .eq("id_user", newId)
      .is("deleted_at", null);
    // .eq("package", true);

    const { data: invoice } = await supabase
      .from("Invoice")
      .select()
      .eq("id_user", newId)
      .is("deleted_at", null);

    let limit = data[0].limit;
    let package_limit = data[0].package_limit;

    if (invoice && invoice.length > 0) {
      const invoicesValue = invoice.filter(
        (item) =>
          moment(item.closed_in).format("MMM") === moment().format("MMM") &&
          item.status === 1
      );
      const verificationMonth =
        invoicesValue.length > 0
          ? moment().add(1, "months").format("MMM")
          : moment().format("MMM");

      const actualValue =
        transaction
          .filter(
            (item) =>
              moment(item.date).format("MMM") === verificationMonth &&
              item.status === 6 &&
              !item.consumption
          )
          .reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            0
          ) + package_limit;

      const spentValue = transaction
        .filter(
          (item) =>
            moment(item.date).format("MMM") === verificationMonth &&
            (item.status === 3 || item.status === 1) &&
            item.consumption
        )
        .reduce(
          (accumulator, currentValue) => accumulator + currentValue.value,
          0
        );

      console.log({
        package_limit,
        actualValue: transaction.filter(
          (item) =>
            moment(item.date).format("MMM") === verificationMonth &&
            item.status === 6 &&
            !item.consumption
        ),
        spentValue: transaction.filter(
          (item) =>
            moment(item.date).format("MMM") === verificationMonth &&
            (item.status === 3 || item.status === 1) &&
            item.consumption
        ),
      });

      limit = actualValue - spentValue;
    }
    setValues({
      ...data[0],
      packages: transaction.filter((item) => item.package),
      limit,
    });
    setLoading(false);
  };

  const approveDocument = async (approved, fieldAproved, fieldReproved) => {
    const itemSendEmail = aprovations(approved).find(
      (item) => item.aproved === fieldAproved && item.reproved === fieldReproved
    );
    await supabase
      .from("user")
      .update({
        [fieldAproved]: approved ? new Date() : null,
        [fieldReproved]: !approved ? new Date() : null,
      })
      .eq("id", values.id);

    if (approved && fieldAproved === "ticket_approved_at") {
      const atualDay = moment().format("D");
      const plan = optionsPlans?.find((item) => item?.value === values?.plan);
      const openIn = moment().startOf("month").subtract(3, "h");
      const closedIn = moment().endOf("month").subtract(3, "h");
      const expiredIn = moment(closedIn)
        .add(getQuantity(plan), getType(plan))
        .subtract(3, "h");

      await editInvoice(values.id);
      const saveInvoices = await saveInvoice(undefined, {
        created_at: new Date(),
        name: `Consumo - ${plan.label}`,
        id_user: values.id,
        invoice_day: parseInt(atualDay),
        open_in: new Date(openIn),
        closed_in: new Date(closedIn),
        expired_in: new Date(expiredIn),
        value: "0",
        type: 1,
      });
      // sendEmail(
      //   values.email,
      //   "Nova fatura",
      //   sendInvoices(
      //     values.name,
      //     `Consumo - ${plan.label}`,
      //     parseInt(atualDay),
      //     "0",
      //     saveInvoices.data[0].id
      //   )
      // );
      await saveTransaction(undefined, {
        type: plan.value,
        complete: true,
        date: new Date(),
        pay: false,
        name: plan.label,
        value: plan.credit,
        id_user: values.id,
        status: 6,
      });
    }

    if (approved) {
      alertSuccess(
        `Documentos aprovados! Uma nova etapa foi liberada para o usuário.`
      );
      sendEmail(
        values.email,
        itemSendEmail?.subject,
        aprovedRegister(
          values.name,
          itemSendEmail?.aproved_message,
          itemSendEmail?.labelButton,
          itemSendEmail?.linkButton || "https://app.mediacredit.com.br/home"
        )
      );
    } else {
      alertError(
        `Documentos reprovados! Este usuário voltará para etapa de análise.`
      );
      sendEmail(
        values.email,
        itemSendEmail?.subject,
        aprovedRegister(
          values.name,
          itemSendEmail?.reproved_message,
          itemSendEmail?.labelButton,
          itemSendEmail?.linkButton ||
            `https://app.mediacredit.com.br/data/${values.id}`
        )
      );
    }
    setReload((reload) => !reload);
    // TODO Salvar e enviar email de etapa liberada pro usuário
  };

  const approveContract = async (approved, fieldAproved, fieldReproved) => {
    const itemSendEmail = aprovations(approved).find(
      (item) => item.aproved === fieldAproved && item.reproved === fieldReproved
    );
    await supabase
      .from("user")
      .update({
        [fieldAproved]: approved ? new Date() : null,
        [fieldReproved]: !approved ? new Date() : null,
      })
      .eq("id", values.id);

    if (approved) {
      // const atualDate = moment().format("DD MMM");
      // const atualDay = moment().format("D");
      // const plan = optionsPlans?.find((item) => item?.value === values?.plan);
      // await saveInvoice(undefined, {
      //   created_at: new Date(),
      //   name: atualDate,
      //   id_user: values.id,
      //   invoice_day: parseInt(atualDay),
      //   value: "0",
      // });
      // await saveTransaction(undefined, {
      //   type: plan.value,
      //   complete: true,
      //   date: new Date(),
      //   pay: false,
      //   name: plan.label,
      //   value: plan.credit,
      //   id_user: values.id,
      // });
    }

    if (approved) {
      alertSuccess(
        "Contrato aprovado! Uma nova etapa foi liberada para o usuário."
      );
      sendEmail(
        values.email,
        itemSendEmail?.subject,
        aprovedRegister(
          values.name,
          itemSendEmail?.aproved_message,
          itemSendEmail?.labelButton,
          itemSendEmail?.linkButton || "https://app.mediacredit.com.br/home"
        )
      );
    } else {
      alertError(
        `Contrato reprovados! Este usuário voltará para etapa de análise.`
      );
      sendEmail(
        values.email,
        itemSendEmail?.subject,
        aprovedRegister(
          values.name,
          itemSendEmail?.reproved_message,
          itemSendEmail?.labelButton,
          itemSendEmail?.linkButton ||
            `https://app.mediacredit.com.br/data/${values.id}`
        )
      );
    }
    setReload((reload) => !reload);
  };

  const sendChangeDocumentEmail = async (field, approved) => {
    const formatField = `${field}_approved_at`;

    await supabase
      .from("user")
      .update({
        [formatField]: approved ? new Date() : null,
      })
      .eq("id", values.id);
    setReload((reload) => !reload);

    if (approved) {
      alertSuccess(
        "Contrato aprovado! Uma nova etapa foi liberada para o usuário."
      );
    } else {
      alertError(
        `Contrato reprovados! Este usuário voltará para etapa de análise.`
      );
    }
  };

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));

    const updatedExternalArray = [...documents, { name: field, id: value }];
    const allFieldsPresent = requiredFields.every((requiredField) =>
      updatedExternalArray.some((doc) => doc.name === requiredField)
    );

    if (allFieldsPresent && !values.document_approved_at) {
      sendEmail(
        "plataforma@mediacredit.com.br",
        "Usuário pronto para análise de documentos",
        toApproveDocuments(user.name)
      );
      sendEmail(
        values.email,
        "Liberação do limite pré-aprovado em andamento",
        releaseLimitInProgress(user.name)
      );
    }
  };

  const newValues = { ...values };
  delete newValues.packages;

  const onSave = async () => {
    await saveUser(newValues.id, {
      ...newValues,
      valueCredit: parseInt(newValues.valueCredit),
      limit: parseInt(newValues.limit),
    });
    setReload((reload) => !reload);
  };

  const onSignature = async () => {
    const { data, error } = await supabase.storage
      .from(`signature`)
      .createSignedUrl(`${values.id}/signature`, 315360000);
    window.open(data.signedURL, "_blank");
  };

  const savePackage = async (item) => {
    await saveUser(values?.id, {
      package_limit:
        values?.package_limit === null
          ? 0 + parseInt(item?.value)
          : values?.package_limit + parseInt(item?.value),
    });
    await saveTransaction(item.id, { ...item, rescued: true });
    alertSuccess("Pacote adicionado com sucesso!");
    setReload((reload) => !reload);
  };

  const refusedPreApproved = async () => {
    await supabase
      .from("user")
      .update({
        document_approved_at: null,
        document_refused_at: new Date(),
      })
      .eq("id", values.id);
    sendEmail(
      values.email,
      "Atualização sobre a liberação de limite",
      refusedApproved(values.name)
    );
    setReload((reload) => !reload);
    alertSuccess("Limite pré-aprovado reprovado com sucesso!");
  };

  const changeTicket = async (item) => {
    const plan = optionsPlans?.find((item) => item?.value === values?.plan);
    const openIn = moment().startOf("month").subtract(3, "h");
    const closedIn = moment();
    const expiredIn = moment().add(2, "d");
    // .add(getQuantity(plan), getType(plan))
    // .subtract(3, "h");

    await supabase
      .from("user")
      .update({
        ticket_send_at: new Date(),
      })
      .eq("id", values.id);
    const save = await saveInvoice(undefined, {
      created_at: new Date(),
      name: `Plataforma - ${plan.label}`,
      id_user: values.id,
      open_in: new Date(openIn),
      closed_in: new Date(closedIn),
      expired_in: new Date(expiredIn),
      value: plan.amount,
      type: 0,
    });
    sendEmail(
      values.email,
      "Nova fatura",
      sendInvoices(
        values.name,
        `Plataforma - ${plan.label}`,
        new Date(expiredIn),
        plan.amount,
        save.data[0].id
      )
    );
  };

  const sendEmailFiles = (table) => {
    if (table === "signature") {
      sendEmail(
        values.email,
        "Assinatura",
        aprovedRegister(
          values.name,
          "O documento para assinatura já se encontra na plataforma. Por favor, assine para dar continuidade.",
          "Acessar a plataforma",
          "https://app.mediacredit.com.br/home"
        )
      );
    }
    //  else {
    //   sendEmail(
    //     values.email,
    //     "Boleto",
    //     aprovedRegister(
    //       values.name,
    //       "O boleto foi enviado para que você possa efetuar o pagamento e prosseguir.",
    //       "Acessar a plataforma",
    //       "https://app.mediacredit.com.br/home"
    //     )
    //   );
    // }
  };

  return (
    <DocumentsScreen
      findDocuments={documents}
      values={values}
      onSave={onSave}
      onChange={onChange}
      user={{ id: id === ":id" ? user.id : id ? id : user.id, type: user.type }}
      tabSelect={setTab}
      approveDocument={approveDocument}
      changeTicket={changeTicket}
      setReload={setReload}
      sendChangeDocumentEmail={sendChangeDocumentEmail}
      approveContract={approveContract}
      tab={tab}
      onSignature={onSignature}
      loading={loading}
      docs={docs}
      savePackage={savePackage}
      sendEmailFiles={sendEmailFiles}
      refusedPreApproved={refusedPreApproved}
    />
  );
};

export default DocumentsContainer;

const aprovations = (approved) => [
  {
    id: 1,
    label: "Cadastro",
    aproved: "register_approved_at",
    subject: "Cadastro na plataforma",
    reproved: "register_refused_at",
    aproved_message: "Seu cadastro foi aprovado com sucesso!",
    reproved_message:
      "Seu cadastro foi reprovado! Verifique se seus dados estão corretos!",
    labelButton: "Resolver cadastro",
  },
  {
    id: 2,
    label: "Mídia Social",
    aproved: "social_media_approved_at",
    subject: approved
      ? "Limite Pré-Aprovado na Media Credit"
      : "Atualização sobre sua solicitação de limite",
    reproved: "social_media_refused_at",
    aproved_message:
      "Parabéns! Informamos que sua solicitação de limite na Media Credit foi pré-aprovada. <br />Isso significa que você está um passo mais próximo de desbloquear todos os benefícios da nossa plataforma. Agora você pode prosseguir para a próxima etapa do processo. <br />Envie a documentação da sua empresa clicando no botão abaixo:",
    reproved_message:
      "Gostaríamos de informar que, após revisão detalhada, sua solicitação de limite para Ads não foi aprovada neste momento. <br />Entendemos que isso pode ser decepcionante, mas queremos assegurar que continuaremos trabalhando para oferecer soluções que atendam às suas necessidades. <br />Enquanto sua empresa não possui um limite aprovado, poderá consultar os relatórios automáticos do Google e Meta para potencializar as estratégias.",
    labelButton: approved ? "Enviar documentação" : "Acompanhar relatórios",
    linkButton: approved
      ? "https://app.mediacredit.com.br/home"
      : "https://app.mediacredit.com.br/dashboard",
  },
  {
    id: 3,
    label: "Documentos",
    aproved: "document_approved_at",
    subject: approved
      ? "Limite Aprovado na Media Credit"
      : "Documentação incorreta na Media Credit",
    reproved: "document_refused_at",
    aproved_message:
      "Temos o prazer de informar que seu limite de crédito para Ads foi aprovado! <br />Seu novo limite estará disponível para uso após a assinatura do contrato e pagamento da primeira mensalidade. <br />O contrato foi enviado para o e-mail do representante legal da empresa para assinatura.",
    reproved_message:
      "Agradecemos o envio da documentação da sua empresa, mas alguns documentos estão incorretos. <br />Entre na plataforma e corrija a documentação para avançarmos.",
    labelButton: approved ? "Acompanhe" : "Corrigir documentação",
  },
  {
    id: 4,
    label: "Contrato",
    aproved: "contract_approved_at",
    subject: approved
      ? "Libere o uso do seu limite"
      : "Atualização sobre seu contrato",
    reproved: "contract_refused_at",
    aproved_message:
      "O contrato foi assinado com sucesso! <br />Seu novo limite estará disponível assim que realizar o pagamento da primeira mensalidade. <br />Pague a primeira mensalidade clicando no botão abaixo.",
    reproved_message:
      "Seu contrato foi reprovado! <br />Entre na plataforma e refaça sua assinatura!",
    labelButton: approved ? "Pagar mensalidade" : "Acessar a plataforma",
  },
  {
    id: 5,
    label: "Boleto",
    aproved: "ticket_approved_at",
    subject: approved
      ? "Limite de Ads liberado"
      : "Atualização sobre seu boleto",
    reproved: "ticket_refused_at",
    aproved_message:
      "Seu limite aprovado já está disponível para uso. <br />Agora você pode aproveitar ao máximo os recursos das nossa plataforma e impulsionar suas estratégias de anúncio com ainda mais confiança. <br />Obrigado por escolher nossa plataforma. <br />Comece a usar o seu limite clicando no botão abaixo:",
    reproved_message:
      "Seu boleto foi reprovado! Realize o pagamento o quanto antes para começar a utilizar o seu limite e usufruir da nossa plataforma.",
    labelButton: approved ? "Usar limite" : "Acessar a plataforma",
  },
];

const getString = (plan) => {
  const label = plan.label;
  if (label) {
    const divider = label.split(" ");
    if (divider) {
      const last = divider[divider.length - 1];
      return last;
    }
  }
  return null;
};

const getQuantity = (plan) => {
  const label = getString(plan);
  if (label == "90") {
    return 2;
  } else if (label == "60") {
    return 1;
  } else if (label == "45") {
    return 15;
  } else if (label == "30") {
    return 5;
  }

  return 0;
};

const getType = (plan) => {
  const label = getString(plan);
  if (label == "90") {
    return "month";
  } else if (label == "60") {
    return "month";
  }

  return "days";
};

const requiredFields = [
  "social_contract",
  "last_social_contract",
  "balance_patri",
  "dre",
  "balancete",
  "invoicing",
  "patner_identification",
  "member_receipt",
];
