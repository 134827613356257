import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { alertError, alertSuccess } from "../../components/Toast";
import { getTranslation } from "../../core/Translator";
import { supabase } from "../../supabaseClient";
import LoginScreen from "../../screens/Login/LoginScreen";
import { useDispatch } from "react-redux";
import { setData } from "../../core/redux/app/Actions";
import { openRoutes } from "../../core/router/routes";

const LoginContainer = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({ email: "", password: "" });
  const [hidden, setHidden] = useState(false);
  const lastPath = sessionStorage.getItem("lastPath");

  const handleLogin = async () => {
    try {
      setLoading(true);
      const extraProps = {};

      if (values.password) {
        extraProps.password = values.password;
      }

      const { error, session } = await supabase.auth.signIn(
        { email: values.email, ...extraProps },
        { shouldCreateUser: false }
      );

      if (error) throw error;

      if (!session) {
        alertSuccess("Enviamos uma solicitação para o seu email!");
      }

      history.push("/home");
    } catch (error) {
      alertError(getTranslation(error.error_description || error.message));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (openRoutes?.filter((item) => item.path === lastPath)?.length === 0) {
      sessionStorage.setItem("lastPath", "/home");
      dispatch(setData({ navigate: "/home" }));
    }
  }, []);

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  return (
    <LoginScreen
      handleLogin={handleLogin}
      onChange={onChange}
      values={values}
      loading={loading}
      history={history}
      hidden={hidden}
      setHidden={setHidden}
    />
  );
};

export default LoginContainer;
