import React from "react";
import { MdClose } from "react-icons/md";
import { LoadingButton } from "../../components/Button/Button";
import { CustomDropzone } from "../../components/Dropzone/Dropzone";
import { DateInput, MoneyInput } from "../../components/Input/Input";

const HomeTicketScreen = ({
  values,
  onChange,
  onSave,
  isChange,
  closeModal,
  loadingSave
}) => (
  <div className="modal-consumption-container">
    <div className="modal-header">
      <div className="header-title">Subir créditos</div>
      <div>
        {isChange ? (
          <LoadingButton
            className={`btn-save-details`}
            label={"Salvar"}
            onClick={onSave}
            loading={loadingSave}
          />
        ) : (
          <></>
        )}
        <MdClose size={20} onClick={closeModal} style={{ cursor: "pointer" }} />
      </div>
    </div>
    <div className="modal-consumption-body">
      <MoneyInput
        className={"documents-input"}
        placeholder={"Valor"}
        onChange={(item) => onChange("value", item)}
        value={values?.value}
        disabled={loadingSave}
      />
      <DateInput
        className={"documents-input"}
        placeholder={"Data"}
        onChange={(item) => onChange("date", item)}
        value={values && values.date}
        disabled={loadingSave}
      />
      <CustomDropzone
        placeholder={"Arraste e solte seu boleto."}
        table={"ticketsTransaction"}
        filename={`ticket/${new Date().getTime()}`}
        onChange={(value) => onChange("ticket", value)}
        value={values && values.ticket}
        disabled={loadingSave}
      />
    </div>
  </div>
);

export default HomeTicketScreen;
