import React from 'react';

export const NotResultCompontent = ({ height = 300, width = 300, notText = false, size = 16 }) => (
    <div className='container-not-data'>
        <lottie-player key='loading-data' src="https://lottie.host/9d66c9d0-8ee0-4f93-b189-34c74ac717bc/NGrU1xw4VK.json" background="transparent" speed="1" style={{ width, height }} loop autoplay></lottie-player>
        {!notText && <p className='text-not-result' style={{ fontSize: size }}>Nenhum resultado encontrado.</p>}
    </div>
);


export const NotPackageCompontent = () => (
    <div className='container-not-data'>
        <lottie-player key='loading-data' src="https://lottie.host/9d66c9d0-8ee0-4f93-b189-34c74ac717bc/NGrU1xw4VK.json" background="transparent" speed="1" style={{ width: 300, height: 300 }} autoplay></lottie-player>
        <p className='text-not-result'>Você não possui nenhum pacote</p>
    </div>
);