import { Button, InputGroupText } from "reactstrap";
import { LabelInput } from "../../components/Input/Input";
import { BsArrowLeft, BsEyeSlash } from "react-icons/bs";
import { DefaultButton, LinkButton } from "../../components/Button/Button";

const NewPasswordScreen = ({
  handleNewPassword,
  onLogout,
  onChange,
  values,
  loading,
  isInside = true,
}) => {
  return (
    <>
      <div className="newPassword-container">
        <div className="div-background" />
        <div className={`form ${isInside ? "" : "outside"}`}>
          <div>
            <img
              className="logo"
              src={require("../../assets/images/logo.svg").default}
            />
          </div>
          <div className="loginInfo">
            <h2 className="title">
              {isInside ? "Redefinir senha" : "Cadastrar senha"}
            </h2>
            <h5 className="description">
              {isInside
                ? "Insira sua nova senha abaixo para alterar"
                : "Insira sua senha abaixo para finalizar seu cadastro"}
            </h5>
            <div className="inputs">
              <LabelInput
                label={"Nova senha"}
                value={values.newPassword}
                placeholder={"Insira sua nova senha"}
                classLabel={"loginLabel"}
                className={"login-input"}
                type={"password"}
                onChange={(value) => onChange("newPassword", value)}
              />
              <LabelInput
                label={"Repita sua nova senha"}
                value={values.repeatPassword}
                placeholder={"Insira sua nova senha"}
                classLabel={"loginLabel"}
                className={"login-input"}
                type={"password"}
                onChange={(value) => onChange("repeatPassword", value)}
              />
            </div>
          </div>
          <div style={{ width: "100%" }}>
            {isInside ? (
              <div onClick={onLogout} className="backBtn">
                <BsArrowLeft className="backIcon" />
                <h6 className="forgotPassword">Voltar para o login</h6>
              </div>
            ) : (
              <></>
            )}
            <div style={{ marginTop: 10 }}>
              <DefaultButton
                disabled={loading}
                label={loading ? "Enviando.." : "Redefinir"}
                onClick={handleNewPassword}
                className={"shadow-5"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPasswordScreen;
